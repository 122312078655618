import { useSelector } from 'react-redux';
import { RootState } from '../../Redux/store'; // Adjust the path based on your project structure

const useCategoryNames = (categoryIds: string[]) => {
  // Get all categories from the Redux store
  const allCategories = useSelector((state: RootState) => state.values.allCategories);

  // Create a mapping of category ID to name
  const categoryMap = allCategories.reduce((acc: { [key: string]: string }, category) => {
    acc[category.id] = category.name;
    return acc;
  }, {});


// Return an array of objects containing id and name based on the provided IDs
const result = categoryIds.map(id => ({
    id,
    name: categoryMap[id] || "" // Default to an empty string if ID is not found
  }))

  return result;
};

export default useCategoryNames;
