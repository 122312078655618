import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToTop = () => {
  const { pathname } = useLocation(); // Correct property name is 'pathname'

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]); // Dependency on 'pathname' for scrolling to top on route change
};

export default useScrollToTop;
