import React, { useEffect } from "react";
import { GoBellFill, GoHomeFill } from "react-icons/go";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import ItemRewardComponent from "./components/itemRewardComponent";
import defaultImage from "../../../assets/item.png"
import { useDispatch } from "react-redux";
import { setCategoryItemData } from "../../../Redux/reducer/adminReducer";

type Props = {};

const InventaoryItemPage = (props: Props) => {
  const location = useLocation();
  const { id } = location.state || {};
  // const dispatch = useDispatch()

  // useEffect(() => {
  //   if(id){
  //     dispatch(setCategoryItemData({id}))
  //   }
  // },[id,dispatch])

  return (
 
    <> 
      {/* <div className="flex flex-row gap-2 items-center">
        <span>
          <GoHomeFill size={20} className="text-blue-600" />
        </span>
        <p className="font-inter text-sm text-[#8B8D97]">/</p>
        <Link to="/inventory">
          <p className="font-inter text-sm text-[#6366F1]">Inventory</p>
        </Link>
        <p className="font-inter text-sm text-[#8B8D97]">/</p>
        <p className="font-inter text-sm text-[#8B8D97]">View item</p>
      </div> */}

      
        <ItemRewardComponent id={id} />
        </>

  );
};

export default InventaoryItemPage;
