import { useState } from 'react'
import { CiSearch } from "react-icons/ci";
import { CiFilter } from "react-icons/ci";
import Dropdown from '../Inventory/components/Dropdown';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { RiFileExcel2Fill } from "react-icons/ri";

export const RequestReward = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [limit, setLimit] = useState("6");  
      // const {data} = usePublishedContractorQuery()
    const handleSelect = (option: any) => {
        setSelectedOption(option);
        setLimit(option);
      };
    
      // const toggleComponent = (component: string) => {
      //   setComp(component);
      //   setVisibleComponent(component);
      //   dispatch(closeProfile())
      // };
   
      const options = ["9", "8", "7"];

  return (  
    <div className='h-full bg-white'>
        {/* <AllContractors /> */}
        <div className='border rounded-[12px]  flex flex-col gap-[24px] py-[20px] px-[20px]'>
        
        {/* First Line */}
         <div className='flex justify-between'>
            <div className='flex gap-[7px]'>
            <div className='flex items-center w-[170px] h-[30px] gap-[10px] p-[8px] border rounded-[4px]'>
                <CiSearch className='w-[16px] h-[16px]' />
                <input 
                type='text'
                className='w-[90%] border-none outline-none font-[400] text-[12px] leading-[14px]'
                  placeholder='Search'
                 />
            </div>

            <div className='flex  w-[65px] h-[30px] border gap-[10px] p-[8px] items-center rounded-[4px] border-[#53545C]'>
             <CiFilter />
              <span className='font-[400] text-[11px] leading-[13px]'>Filter</span>
            </div>
             </div>

            <div className='flex items-center px-[24px] py-[3px] border gap-[4px] rounded-[4px] text-[#16A34A] border-[#16A34A] bg-[#F5F5F5]'>
             <span className='font-[600] text-[16px] leading-[20px]'> Export  </span> <RiFileExcel2Fill  className='w-[20px] h-[20px]'/>
           </div>

         </div>

              {/* table */}
         <div className='flex'>
         <table className="table-auto min-w-full divide-y divide-gray-200">
                  <thead className="bg-white">
                    <tr className='border-t'>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]"></th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                        Name
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                      Contact
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                       Contractor Type
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                       City
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                       GST 
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                       Grade 
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                      Action
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                     Status
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                     Coupon type 
                      </th>

                   
                    </tr>
                  </thead>
                  {/* <tbody className="bg-white divide-y divide-gray-200">
                   
                  
                
                  </tbody> */}
                </table>
         </div>
   
         {/* Pagination */}
         <div className='flex flex-row justify-between p-2 m-1 border-t-2 '>
               <div>
                  <div className='flex flex-row gap-3'>
                    <Dropdown options={options} onSelect={handleSelect} />
                    <p className='text-[#A6A8B1]'>Items Per Page</p>
                    <p>Categories</p>
                  </div>
                </div> 

                 <div>
                  <div className='flex flex-row gap-3'>
                    <Dropdown options={options} onSelect={handleSelect} />
                    <p className='text-[#A6A8B1]'>of 44 pages</p>
                    <div className='flex flex-row items-center gap-2 text-[#666666]'>
                        <span >
                        <FaChevronLeft size={15} />
                        </span>

                        <span >
                        <FaChevronRight size={15} />
                        </span>
                        </div>
                  </div>
                </div>   
         </div>


        </div>
    </div>
  
  )
}
