import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface SelectionState {
  selectedCategories: any[];
  selectedCompanies: any[];
  selectedSearch: string | null;
  isFromSearch: boolean;
  subSearch: string | null;
  selectedSubCategories: any[];
  selectedSubCompanies: any[];
  currentPage: number;
}

const initialState: SelectionState = {
  selectedCategories: [],
  selectedCompanies: [],
  selectedSearch: null,
  isFromSearch: false,
  subSearch: null,
  selectedSubCategories: [],
  selectedSubCompanies: [],
  currentPage: 1
};

const selectionSlice = createSlice({
  name: "selection",
  initialState,
  reducers: {
    setSelectedCategories(state, action: PayloadAction<string[]>) {
      state.selectedSearch = null
      state.selectedCategories = action.payload;
      state.currentPage = 1;
    },
    setSelectedCompanies(state, action: PayloadAction<string[]>) {
      state.selectedSearch = null
      state.selectedCompanies = action.payload;
      state.currentPage = 1;
    },
    setSubSelectedCategories(state, action: PayloadAction<string[]>) {
      state.selectedSearch = null
      state.selectedSubCategories = action.payload;
    },
    setSubSelectedCompanies(state, action: PayloadAction<string[]>) {
      state.selectedSearch = null
      state.selectedSubCompanies = action.payload;
    },
    setSearch(state, action: PayloadAction<string>) {
      state.selectedSearch = action.payload;
      state.selectedCompanies = [];
      state.selectedCategories = [];
      state.isFromSearch = true;
      state.currentPage = 1;
    },
    clearSearchOnPages(state, action: PayloadAction<any>) {
      if (!['/items', '/subItems', '/searchpage'].includes(action.payload)) {
        state.selectedSearch = null
        state.isFromSearch = false;
      }
    },
    setSubSearch(state, action: PayloadAction<string | null>) {
      state.subSearch = action.payload;
      //  state.selectedSubCompanies = [];
      //  state.selectedSubCategories = [];

    },
    addCategory(state, action: PayloadAction<string>) {
      state.selectedSearch = null
      state.selectedCategories.push(action.payload);
    },
    addCompany(state, action: PayloadAction<string>) {
      state.selectedSearch = null
      state.selectedCompanies.push(action.payload);
    },
    addSubCategory(state, action: PayloadAction<string>) {
      state.selectedSearch = null
      state.selectedSubCategories.push(action.payload);
    },
    addSubCompany(state, action: PayloadAction<string>) {
      state.subSearch = null
      state.selectedSubCompanies.push(action.payload);
    },
    clearCategory(state, action: PayloadAction<string>) {
      const categoryIdToRemove = action.payload;
      state.selectedCategories = state.selectedCategories.filter(category => category !== categoryIdToRemove);
    },
    clearCompany(state, action: PayloadAction<string>) {
      const companyToRemove = action.payload
      state.selectedCompanies = state.selectedCompanies.filter((company) => company !== companyToRemove);

    },
    clearSubCategory(state, action: PayloadAction<string>) {
      const categoryIdToRemove = action.payload;
      state.selectedSubCategories = state.selectedSubCategories.filter(category => category !== categoryIdToRemove);
    },
    clearSubCompany(state, action: PayloadAction<string>) {
      const companyToRemove = action.payload
      state.selectedSubCompanies = state.selectedSubCompanies.filter((company) => company !== companyToRemove);

    },
    resetCompany(state) {
      state.selectedCompanies = []
    },
    resetSubCompany(state) {
      state.selectedSubCompanies = []
    },
    clearResults(state) {
      state.selectedCompanies = [];
      state.selectedCategories = [];
    },
    clearSubResults(state) {
      state.selectedSubCompanies = [];
      state.selectedSubCategories = [];
    },
    clearSearchResults(state) {
      state.selectedSearch = null
    },
    setInitialSelection(state, action: PayloadAction<{ category?: string; company?: string }>) {
      const { category, company } = action.payload;
      state.selectedSearch = null
      if (category) {
        state.selectedCategories = [category];
      }
      if (company) {
        state.selectedCompanies = [company];
      }
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },

    incrementPage(state) {
      state.currentPage += 1;
    },

    decrementPage(state) {
      if (state.currentPage > 1) {
        state.currentPage -= 1;
      }
    },

    resetPage(state) {
      state.currentPage = 1;
    },
  }
});

export const { setSelectedCategories, addCategory, clearCategory,
  clearCompany, addCompany, setSelectedCompanies, clearResults,
  setInitialSelection, setSearch, resetCompany, clearSearchResults,
  clearSearchOnPages, setSubSearch, setSubSelectedCategories, setSubSelectedCompanies,
  clearSubResults, clearSubCompany, clearSubCategory, addSubCategory, addSubCompany
  , resetSubCompany, setCurrentPage, incrementPage, decrementPage, resetPage
} = selectionSlice.actions;
export default selectionSlice.reducer;
