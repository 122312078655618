import { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { CiFilter } from "react-icons/ci";
import Dropdown from "../Inventory/components/Dropdown";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { usePublishedContractorMutation } from "../../../Redux/Api/Contractor";
import { RiFileExcel2Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { closeProfile } from "../../../Redux/reducer/RequestReducer";
import { useNavigate } from "react-router-dom";
import { Contractor } from "./types";
import { RootState } from "../../../Redux/store";
import * as XLSX from 'xlsx';
import useDebounce from "../../../common/hooks/useDebounce";
import { ClipLoader, RingLoader } from "react-spinners";

export const Contractors = () => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [limit, setLimit] = useState<string | number>("20");
  const [page, setPage] = useState<any>(1);
  const [name, setName] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [grade, setGrade] = useState<string>("");
  const [totalPages, setTotalPages] = useState(1);
  const [fetchContractors, { data, isLoading }] = usePublishedContractorMutation();
  const [visibleComponent, setVisibleComponent] = useState<string>("contractors");
  const [comp, setComp] = useState<string | null>(null);

  const options = ["9", "8", "7", "All"];

  const handleSelect = (option: string) => {
    setSelectedOption(option);
    setLimit(option === "All" ? "All" : parseInt(option));
  };
  const navigate = useNavigate();
  const Contractor = useSelector((state: RootState) => state.admin.Contractor)

  const toggleComponent = (component: string) => {
    setComp(component);
    setVisibleComponent(component);
    dispatch(closeProfile());
  };
  // console.log(Contractor,"contractors")

  const contractor = Contractor?.data?.approveContractor

  const debouncedName = useDebounce(name, 500);
  const debouncedCity = useDebounce(city, 500);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const params: { limit?: string | number, page: number, name?: string, city?: string, grade?: string } = { page, name: debouncedName, city: debouncedCity, grade };
        if (limit !== 'All' && limit !== 'all') {
          params.limit = limit;
        }
        const response = await fetchContractors(params).unwrap();
        setTotalPages(response.totalPages);
        // Handle response
      } catch (error) {
        console.error("Error fetching contractors:", error);
      }
    };

    fetchData();
  }, [page, limit, debouncedName, debouncedCity, grade, fetchContractors]);

  useEffect(() => {
    setTotalPages(data?.totalPages)
  }, [data])

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const nextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const exportToExcel = () => {
    const filteredData = (data?.contractors || []).map((contractor: any, index: any) => {
      const dateObject = new Date(contractor.dateOfCreation); // Convert ISO string to Date object

      return {
        SerialNo: index + 1,  // Adding SerialNo as an incremental number
        Name: contractor.name,
        Contact: contractor.phoneNumber,
        EmailID: contractor.email,
        City: contractor.city,
        GST: contractor.gst,
        Grade: contractor.contractorGrade,
        Date: dateObject.toLocaleDateString(),
        Time: dateObject.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      };
    });

    // Create a worksheet from the filtered data
    const worksheet = XLSX.utils.json_to_sheet(filteredData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Contractors');

    // Generate buffer and save the workbook
    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'contractors.xlsx';
    link.click();
    window.URL.revokeObjectURL(url);
  };

  const startItem = (page - 1) * (typeof limit === 'number' ? limit : parseInt(limit)) + 1;
  const endItem = Math.min(page * (typeof limit === 'number' ? limit : parseInt(limit)), contractor || 0);

  const resetAll = () => {
    setPage(1);
    setLimit("20");
    setName("");
    setCity("");
    setGrade("")
  }
  return (
    <div className="h-full bg-white rounded-[12px]  ">
      {/* <AllContractors /> */}
      <div className="border rounded-[12px]  flex flex-col gap-[24px] py-[20px] px-[20px]">
        {/* First Line */}
        <div className="flex justify-between gap-x-2 ">
          <div className="flex gap-[7px]">
            <div className="flex items-center w-[170px] h-[30px] gap-[10px] p-[8px] border rounded-[4px]">
              <CiSearch className="w-[16px] h-[16px]" />
              <input
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={name}
                className="w-[90%] border-none outline-none font-[400] text-[12px] leading-[14px]"
                placeholder="Search Name"
              />
            </div>
            <div className="flex items-center w-[170px] h-[30px] gap-[10px] p-[8px] border rounded-[4px]">
              <CiSearch className="w-[16px] h-[16px]" />
              <input
                type="text"
                onChange={(e) => setCity(e.target.value)}
                value={city}
                className="w-[90%] border-none outline-none font-[400] text-[12px] leading-[14px]"
                placeholder="Search City"
              />
            </div>
            <div className="flex items-center w-[140px] h-[30px] gap-[10px] p-[8px] border rounded-[4px]">

              <select
                onChange={(e) => setGrade(e.target.value)}
                value={grade}
                className="w-[100%] border-none outline-none font-[400] text-[12px] leading-[14px]"
              >
                <option value="">Select Grade</option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>

              </select>
            </div>
            <button onClick={resetAll} className=" gap-[10px]  border rounded-[4px]  h-[30px] px-2 bg-red-600 text-sm text-white">
              Reset
            </button>


            {/* <div className="flex  w-[65px] h-[30px] border gap-[10px] p-[8px] items-center rounded-[4px] border-[#53545C]">
              <CiFilter />
              <span className="font-[400] text-[11px] leading-[13px]">
                Filter
              </span> */}
            {/* </div> */}
          </div>

          <div
            className="flex items-center px-[24px] py-[3px] border gap-[4px] rounded-[4px] text-[#16A34A] border-[#16A34A] bg-[#F5F5F5] cursor-pointer"
            onClick={exportToExcel}
          >
            <span className="font-[600] text-[16px] leading-[20px]">
              {" "}
              Export{" "}
            </span>{" "}
            <RiFileExcel2Fill className="w-[20px] h-[20px]" />
          </div>
        </div>

        {/* table */}
        <div className="flex">
          <table className="table-auto min-w-full divide-y divide-gray-200">
            <thead className="bg-white">
              <tr className="border-t">
                <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]"></th>
                <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                  Contact
                </th>
                <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                  Contractor Type
                </th>
                <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                  City
                </th>
                <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                  GST
                </th>
                <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                  Grade
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {/* Show loading spinner */}
              {isLoading ? (
                <tr>
                  <td colSpan={7}>
                    <div className="w-full flex justify-center h-[50px] items-center">
                      <ClipLoader />
                    </div>
                  </td>
                </tr>
              ) : data?.contractors?.length > 0 ? (
                // Display contractor data
                data.contractors.map((i: Contractor) => (
                  <tr key={i._id}>
                    <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                      {/* Placeholder for image */}
                    </td>
                    <td
                      className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#0078FB] underline cursor-pointer"
                      onClick={() => navigate(`/admin/contractors/profile/${i._id}`)}
                    >
                      {i.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                      {i.phoneNumber}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                      {i.contractorType}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                      {i.city}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                      {i.gst}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                      {i.contractorGrade}
                    </td>
                  </tr>
                ))
              ) : (
                // Show no data message
                <tr>
                  <td colSpan={7}>
                    <div className="w-full flex justify-center h-[50px] items-center">
                      Data Not Available
                    </div>
                  </td>
                </tr>
              )}
            </tbody>

          </table>
        </div>

        {/* Pagination */}
        <div className="flex flex-row justify-between p-2 m-1 border-t-2 ">
          <div>
            <div className="flex flex-row gap-3">
              <Dropdown options={options} onSelect={handleSelect} />
              <p>
                Showing {limit === "All" ? "All" : `${startItem || 0} - ${endItem}`} Contractors Request

              </p>
              <p className="text-[#A6A8B1]">Contractors Request</p>
            </div>
          </div>

          <div>
            <div className="flex flex-row gap-3">
              <Dropdown options={options} onSelect={handleSelect} />
              <p className="text-[#A6A8B1]">{page}of {totalPages} pages</p>
              <div className="flex flex-row items-center gap-2 text-[#666666]">
                <span
                  onClick={prevPage}
                  className={`cursor-pointer ${page === 1 ? "opacity-50" : ""
                    }`}
                >
                  <FaChevronLeft size={15} />
                </span>
                <span
                  onClick={nextPage}
                  className={`cursor-pointer ${page === totalPages ? "opacity-50" : ""
                    }`}
                >
                  <FaChevronRight size={15} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};
