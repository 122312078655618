
import { RxCross2 } from "react-icons/rx";
import { RiUploadCloud2Line } from "react-icons/ri";
import { useState } from "react";
import { useChangeImageMutation } from "../../../../Redux/Api/Admin/Reward";

type FormValues = {
  image: File | null;
};

export const UploadImage = ({ onClose, id }: any) => {
  const [couponData, setCouponData] = useState<FormValues>({   
    image: null,
  });
  const [uploadImage] = useChangeImageMutation()
 

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setCouponData((prevState) => ({
      ...prevState,
      image: file,
    }));
  };

  const triggerFileInput = () => {
    const fileInput = document.getElementById("image") as HTMLInputElement;
    fileInput?.click();
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();     
      if (couponData.image) {
        formData.append("image", couponData.image);
      }

      uploadImage({ couponData: formData, id })
      onClose();
    } catch (error) {
      console.error("Error creating reward:", error);
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div className="w-[547px] flex flex-col gap-[20px] border rounded-[8px] bg-white shadow-sm">
        <div className="flex justify-between px-[24px] py-[16px] border-b-2">
          <span>Uplpad Photo</span>
          <RxCross2
            className="w-[30px] h-[30px] cursor-pointer"
            onClick={onClose}
          />
        </div>
        <div className="gap-[12px] px-[10px] flex flex-col">
         

          <div className="flex flex-col gap-[26px] ">        
            <div className="w-full bg-white py-[6px] border-2 border-[#797979] border-dashed rounded-[12px] flex flex-col justify-center items-center">
              <div className="flex flex-col gap-[6px] items-center justify-center text-center">
                <div>
                  <RiUploadCloud2Line className="w-[40px] h-[40px] text-[#004EBA]" />
                </div>

                <div className="font-[300] text-[14px] leading-[7px] text-[#9CA3AF]">
                  Upload Your Files
                </div>
                <div
                  className="text-[#004EBA] font-[400] text-[16px] leading-[24px] underline cursor-pointer"
                  onClick={triggerFileInput}
                >
                  Browse
                </div>
                <input
                  type="file"
                  id="image"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="hidden"
                />
              </div>
              {couponData.image && (
                <div className="mt-4 text-center">
                  <p className="text-gray-600 font-[500] text-[14px] leading-[24px]">
                    Selected File: {couponData.image.name}
                  </p>
                  <p className="text-gray-500 text-[12px]">
                    Size: {(couponData.image.size / 1024).toFixed(2)} KB
                  </p>
                </div>
              )}
            </div>
          </div>        
        </div>
        <div className="py-[12px] px-[24px]">
          <div
            className="bg-blue-500 text-white py-[8px] border rounded-md flex justify-center items-center cursor-pointer"
            onClick={handleSubmit}
          >
            Save
          </div>
        </div>
      </div>
    </div>
  );
};
