import React, { Children } from 'react'
import { ProfileSidebar } from './Components/ProfileSidebar';
import { ProfileHeader } from './Components/ProfileHeader';

type Props = {
    children: React.ReactNode;
  };
   

export const Profile = ({children}: Props) => {
  return (
    <div className="  w-full flex flex-col lg:flex-row h-full">
        <ProfileSidebar />
        <ProfileHeader />
        <div className='w-full  h-[80vh] overflow-y-scroll  '>
        {children}
        </div>
       
    </div>
  )
}
