import React, { useEffect, useState } from "react";
import {
  useGetOrderDetailsMutation,
  useGetReferralOrdersMutation,
  usePostOrderHistoryMutation,
} from "../../../Redux/Api/Order";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RiFileExcel2Fill } from "react-icons/ri";
import { CiFilter, CiSearch } from "react-icons/ci";
import { MdSettingsBackupRestore } from "react-icons/md";

interface OrderDetails {
  _id: string;
  orderNumber: string;
  createdAt: string;
  status: string;
  contractorDetail: string;
  contractorId: string;
  itemData: ItemData[];
  createdAs: string;
  createdBy: string;
}

interface ItemData {
  _id: string;
  godown: string;
  itemCode: string;
  itemName: string;
  categoryCoins: number;
  companyProductCoins: number;
  coins?: number;
  quantity: number;
  unit: string;
  dispatchQuantity: number;
  status: { value: string; date: string; _id: string }[];
  taxableValue: number;
  margin: number;
  remark: string;
}

const ReferralOrderDetails: React.FC = () => {
  const navigate = useNavigate();
  const [editStates, setEditStates] = useState<boolean[]>([]);
  const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null);
  const [totalFinalCoins, setTotalFinalCoins] = useState<number>(0);

  const [coins, setCoins] = useState([]);
  const [mutation, { data: orderData, error, isLoading }] =
  useGetOrderDetailsMutation();

  const [historyMutation] =usePostOrderHistoryMutation()
  const { id } = useParams();

  useEffect(() => {
    mutation(id);
  }, [mutation, id]);

  useEffect(() => {
    if (orderData && orderData.order) {
      const initialEditStates = new Array(
        orderData.order[0].itemData.length
      ).fill(false);
      setEditStates(initialEditStates);
      setOrderDetails(orderData.order[0]);
      calculateTotalFinalCoins(orderData.order[0].itemData);
    }
  }, [orderData]);

  const calculateTotalFinalCoins = (items: ItemData[]) => {
    let totalCoins = 0;

    let coinsArrys: any = [];

    coinsArrys = items.map((item: any) => {
      if (item.coins === undefined) {
        return (
          item.categoryCoins * item.companyProductCoins * item.dispatchQuantity
        );
      } else {
        return item.coins;
      }
    });

    setCoins(coinsArrys);

    console.log(items, coinsArrys);

    items.forEach((item) => {
      totalCoins +=
        item.coins ||
        item.categoryCoins * item.companyProductCoins * item.dispatchQuantity;
    });
    setTotalFinalCoins(totalCoins);
  };

  const handleApprove = async() => {

   
    
    const postdata = {
      order: orderData.order[0]._id,
      contractor: orderData.order[0].contractorId,
      coinsData: coins,
    };
    await historyMutation(postdata)

    navigate("/admin/orders")
  };

  const toggleEdit = (index: number) => {
    const newEditStates = [...editStates];
    newEditStates[index] = !newEditStates[index];
    setEditStates(newEditStates);
  };

  const handleEditSave = (index: number) => {
    const updatedItem = { ...orderDetails!.itemData[index] };
    const newEditStates = [...editStates];
    newEditStates[index] = false;
    setEditStates(newEditStates);
    // You can handle the save logic here, like sending updatedItem to backend API
    calculateTotalFinalCoins(orderDetails!.itemData);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedItem = { ...orderDetails!.itemData[index] };
    updatedItem.coins = +e.target.value; // Assuming input field updates `coins` directly
    const updatedItems = [...orderDetails!.itemData];
    updatedItems[index] = updatedItem;
    setOrderDetails({ ...orderDetails!, itemData: updatedItems });
    calculateTotalFinalCoins(updatedItems);
  };

  const handleRestoreOriginal = (index: number) => {
    if (!orderDetails?.itemData) {
      return; // Handle the case where itemData is undefined
    }

    const updatedItems: ItemData[] = orderDetails.itemData.map((item) => ({
      ...item,
      coins:
        item.categoryCoins * item.companyProductCoins * item.dispatchQuantity,
    }));

    setOrderDetails((prevOrderDetails) => ({
      ...prevOrderDetails!,
      itemData: updatedItems,
    }));

    calculateTotalFinalCoins(updatedItems);
  };

  return (
    <section>
      <div className="flex justify-between">
        <ul>
          <li className="flex">
            <span className="font-semibold text-[16px] leading-[19px] flex text-[#8B8D97]">
              Order Number:
            </span>
            <span className="text-[#c15068] mx-2">
              {orderDetails?.orderNumber}
            </span>
            <span className="font-[600] text-[16px] leading-[19px] flex text-[#8B8D97]">
              Date:
            </span>
            <span className="text-[#c15068] mx-2">
              {orderDetails?.createdAt.split("T")[0]}
            </span>
          </li>
          <li className="flex">
            <span className="font-semibold text-[16px] leading-[19px] flex text-[#8B8D97]">
              Order Status:
            </span>
            <span className="text-[#c15068] mx-2">{orderDetails?.status}</span>
          </li>
          <li className="flex">
            <span className="font-semibold text-[16px] leading-[19px] flex text-[#8B8D97]">
              Sales Person:
            </span>
            <span className="text-[#c15068] mx-2">
              {orderDetails?.createdBy}
            </span>
          </li>
          <li className="flex">
            <span className="font-semibold text-[16px] leading-[19px] flex text-[#8B8D97]">
              Referral Contractor
            </span>
            <span
              className="text-[#4f63cb] mx-2 cursor-pointer"
              onClick={() =>
                navigate(
                  `/admin/contractors/profile/${orderDetails?.contractorId}`
                )
              }
            >
              {orderDetails?.contractorDetail}
            </span>
          </li>
        </ul>
      </div>

      <div>
        <button className="p-3">Get Coins Details</button>
      </div>

      <div className="flex">
        <table className="table-auto min-w-full divide-y divide-gray-200">
          <thead className="bg-white">
            <tr className="border-t">
              <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                Item Name
              </th>
              <th className="px-2 py-3 text-left text-sm font-inter text-[#1E293B]">
                Quantity
              </th>
              <th className="px-5 py-3 text-left text-sm font-inter text-[#1E293B]">
                Coins
              </th>
              <th className="px-3 py-3 text-left text-sm font-inter text-[#1E293B]">
                Disp Qty
              </th>
              <th className=" py-6 text-left text-sm font-inter text-[#1E293B]">
                Final Coins (Coins*Dispatch)
              </th>
              <th className="px-5 py-3 text-left text-sm font-inter text-[#1E293B]">
                taxableValue
              </th>
              <th className="px-5 py-3 text-left text-sm font-inter text-[#1E293B]">
                margin
              </th>
              <th className="px-5 py-3 text-left text-sm font-inter text-[#1E293B]">
                Godown
              </th>
              <th className="px-5 py-3 text-left text-sm font-inter text-[#1E293B]">
                Status
              </th>
              <th className="px-5 py-3 text-left text-sm font-inter text-[#1E293B]">
                Remark
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {orderDetails?.itemData?.map((item, index) => (
              <tr key={item._id}>
                <td className=" py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                  {item.itemName}
                </td>
                <td className="px-5 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                  {item.quantity}
                </td>
                <td className="px-5 py-4 whitespace-nowrap font-inter text-sm font-semibold text-amber-500">
                  {item.categoryCoins * item.companyProductCoins}
                </td>
                <td className="px-3 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                  {item.dispatchQuantity}
                </td>
                <td className="px-6 f py-4 whitespace-nowrap font-inter text-sm text-amber-500 flex items-center  justify-between">
                  {editStates[index] ? (
                    <>
                      <input
                        type="number"
                        className="border-2 w-1/2 p-2"
                        value={item.coins}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    </>
                  ) : (
                    <span>
                      {item.coins ||
                        item.categoryCoins *
                          item.companyProductCoins *
                          item.dispatchQuantity}
                    </span>
                  )}

                  <span>
                    <button
                      className={`border-2 rounded-md p-1 text-black ${
                        editStates[index] ? "bg-blue-300" : "bg-yellow-300"
                      } ml-2`}
                      onClick={() =>
                        editStates[index]
                          ? handleEditSave(index)
                          : toggleEdit(index)
                      }
                    >
                      {editStates[index] ? "Save" : "Edit"}
                    </button>
                    <button>
                      {editStates[index] ? (
                        ""
                      ) : (
                        <button
                          className="border-2 rounded-md p-1 text-black bg-yellow-300 ml-2"
                          onClick={() => handleRestoreOriginal(index)}
                        >
                          <MdSettingsBackupRestore />
                        </button>
                      )}
                    </button>
                  </span>
                </td>
                <td className="px-5 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                  {item.taxableValue.toFixed(2)}
                </td>
                <td className="px-5 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                  {item.margin.toFixed(2)}
                </td>
                <td className="px-5 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                  {item.godown}
                </td>
                <td className="px-5 py-4 whitespace-nowrap font-inter text-sm">
                  {item.status[item.status.length - 1].value}
                </td>
                <td className="px-5 py-4 whitespace-nowrap font-inter text-sm">
                  {item.remark}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="my-7 flex gap-5">
        <div>
          <span className="font-semibold">Total Final Coins: </span>
          <span className="text-[25px] bg-yellow-300 text-black border-2 p-2">
            {totalFinalCoins}
          </span>
        </div>
        <div>
          <button
            className="bg-blue-200 h-full border-2 border-blue-300 p-2 text-black font-bold 
             hover:transition-bg hover:bg-blue-600 hover:text-white  transition duration-300"
            onClick={handleApprove}
          >
            Approve
          </button>
        </div>
      </div>
    </section>
  );
};

export default ReferralOrderDetails;
