import { useGetAdminAllContractorMessageQuery } from "../../../Redux/Api/Order";

interface Data {
  contractorId: string;       
  contractorName: string;    
  createdAt: string;        
  date: string;            
  message: string;          
  name: string;             
  _id: string;               
}

const AllContractorMessage = () => {
  const { data: Reward } = useGetAdminAllContractorMessageQuery();

  return (
    <div className="flex flex-col gap-[10px] ">
      <div className="w-full bg-white p-[10px] font-[600] text-[24px] leading-[28px]">
        Messages
      </div>
      <section className="h-[90vh]">
        {Reward?.data?.length > 0 && (
          <div key={Reward?.data?._id}>
            {Reward?.data?.map((item: Data) => {
              return (
                <div className=" my-5 m-auto w-[95%] shadow-lg rounded-md">
                  <div className=" w-full h-full text-xl divide-gray-200">
                    <ul className=" ">
                      <li className=" items-center flex px-3">
                        <span className="font-semibold text-[16px] leading-[19px] flex text-[#8B8D97]">
                          Customer Name:
                        </span>
                        <span className="text-blue-500  mx-2 font-semibold  cursor-pointer">
                          {item?.name}
                        </span>
                      </li>
                      <li className=" items-center flex px-3">
                        <span className="font-semibold text-[16px] leading-[19px] flex text-[#8B8D97]">
                          Message :
                        </span>
                        <span className="text-blue-500  mx-2  font-semibold  cursor-pointer">
                          {item?.message}
                        </span>
                      </li>
                      <li className=" items-center flex px-3">
                        <span className="font-semibold text-[16px] leading-[19px] flex text-[#8B8D97]">
                          Customer Arrival Date & Time
                        </span>
                        <span className="text-blue-500  mx-2  font-semibold  cursor-pointer">
                          {`${item?.date?.split("T")[0]} ${
                          item?.date?.split("T")[1]?.split(":")[0]
                        }:${item?.date?.split("T")[1]?.split(":")[1]}`}
                        </span>
                      </li>
                    </ul>

                    <div className="text-[12px] font-bold text-[#8B8D97] p-3">
                      {`${item?.createdAt?.split(" ")[0]} ${
                          item?.createdAt?.split(" ")[1]
                        }`}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </section>
    </div>
  );
};

export default AllContractorMessage;
