import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../Redux/store';

type PrivateRouteProps = {
  roles: string[]; // Define roles as an array of strings
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({ roles }) => {
  const userRole = useSelector((state: RootState) => state.Login.ContractorData?.role);
 
  if (!userRole) {
    // If no user role, allow access (public route)
    return <Navigate to="/" replace />;
  }

  // if (userRole) {
  //   // If user is logged in, redirect to home
  //   return <Navigate to="/" replace />;
  // }

  // Check if user role matches authorized roles
  const isAuthorized = roles.includes(userRole);

  if (!isAuthorized) {
    // Redirect to unauthorized page or home if not authorized
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
