import animation from "../../../assets/animation.gif";
import vector1 from "../../../assets/vector-24-39.svg";
import mask from "../../../assets/mask-group.png";
import group from "../../../assets/callCordinate.png";
import Header from "../../Components/Header";
import Navbar from "../../Components/Navbar";
import { useEffect, useState } from "react";
import { setInterval } from "timers/promises";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { LoginResponse } from "../../../Redux/reducer/LoginReducer";

export const HoldOnPage = () => {
  const [state, setState] = useState<string>("");
  const [intervalId, setIntervalId] = useState<number | null>(null);

  const dispatch = useDispatch()
  const userDetails = useSelector(
    (state: RootState) => state.Login.ContractorData
  );

  const handleSlideText = () => {
    const data = "YOU  ARE NOT VERIFIED YET      ";
    let index = 0;
    let reversed = false;
    const id = window.setInterval(() => {
      if (index <= data.length && !reversed) {
        setState((prev) => prev + data[index]);
        index++;

        if (index === data.length - 1) {
          reversed = true;
          index = 0;
          setState("YOU ARE NOT VERIFIED YET      ");
        }
      }
      if (index <= data.length && reversed) {
        setState(data.substring(0, data.length - 1 - index));

        index++;
        if (index === data.length - 1) {
          reversed = false;
          index = 0;
        }
      }
    }, 200);

    setIntervalId(id);
  };

  useEffect(() => {
    handleSlideText();
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);
  useEffect(() => {
    // handleSlideText();
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  // handleSlideText()
  return (
    <div>
      <div>
        <div className="w-11/12  m-auto lg:mt-[4rem] ">
          <div className="">

            <div className="flex items-center">
            <div className="  text-[#CBD5E1] [font-family:'Poppins',Helvetica] font-bold text-theme-colors-neutral-300 sm:text-[4rem] md:text-[4rem]  text-[3rem] tracking-[0] leading-[7.25px] whitespace-nowrap">
              Hold ON
            </div>
            <img
              className="sm:w-[146px] w-[120px] sm:h-[131px] h-[100px] "
              alt="Https lottiefiles"
              src={animation}
            />
</div>
            <div className=" sm:top-[20px] right-[350px] [text-shadow:0px_2px_6px_blue] text-blue-600 [font-family:'Poppins',Helvetica] font-bold text-[2rem] text-wrap sm:text-nowrap sm:text-[3rem] md:text-[4rem] lg:text-[4rem] tracking-[0]  whitespace-nowrap ">
              {userDetails?.name}              
            </div>
          </div>

          <div className="lg:flex  justify-between ">
            <div className="h-[40px] my-3 sm:h-[108px] w-full lg:w-[70%] ">
              <div className="flex">
                <span className="font-bold text-[1.4rem] sm:text-[2.7rem] md:text-[3rem] lg:text-[3rem]  block">{state}</span>
                <img className=" h-[28px] sm:h-[80px] px-1 mt-1" alt="Vector" src={vector1} />
              </div>
            </div>
            <div className=" sm:h-[7rem] lg:mt-4">
              {/* <Link to={"/"} onClick={()=>{  dispatch(LoginResponse(null));}}> */}
                <div
                  className={`w-[200px] sm:w-[255px] h-[40px] sm:h-[78px] relative group cursor-pointer `}
                >
                  <div
                    className={`sm:w-[200px] w-[150px] px-3 sm:px-6 py-1 sm:py-4 bg-black items-center justify-end gap-2.5 absolute top-2 rounded-[50px] sm:rounded-[100px] border-2 border-solid border-black group-hover:block hidden transition-opacity duration-[10s] opacity-0 group-hover:opacity-100`}
                  >
                    <div className="relative w-fit mt-[-2.00px] [font-family:'Poppins',Helvetica] font-medium text-white text-2xl sm:text-4xl tracking-[0] leading-[normal]">
                      Go Back
                    </div>
                  </div>
                  <div className="flex justify-end pl-9 pr-6 sm:py-3 py-1 bg-white items-center gap-2.5 absolute top-0 left-0 rounded-[100px] border-2 border-solid border-black">
                  
                    <div className="relative w-fit mt-[-2.00px] font-medium text-black text-2xl sm:text-4xl tracking-[0] leading-[normal]">
                      Go Back
                    </div>
                  </div>
                </div>
              {/* </Link> */}
            </div>
          </div>
          <img
            className=" sm:w-[540px] sm:h-[432px]  w-[440px] h-[332px] "
            alt="Groupc:\Users\Vikas\Downloads\AnimaPackage-React-t8Rbr (1)\static\img\star-1.svg"
            src={group}
          />
          <div className="flex w-[344px] items-start justify-center gap-2.5 "></div>
        </div>
      </div>
    </div>
  );
};
