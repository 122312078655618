import React, { useEffect } from 'react'
import { useGetReferralOrdersMutation } from '../../../Redux/Api/Order';
import { Link, useNavigate } from 'react-router-dom';

const CompleteOrders = () => {

    const navigate = useNavigate()
    const [mutation, { data: referralOrders, error, isLoading }] = useGetReferralOrdersMutation();

    useEffect(() => {
      const body = { referralOrder: true };
      // console.log("Calling mutation with body:", body);
  
      mutation(body);
    }, [mutation]);
  

  // console.log(referralOrders)



  return (
    <div className="flex">
    <table className="table-auto min-w-full divide-y divide-gray-200">
      <thead className="bg-white">
        <tr className="border-t">
          <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
            Date
          </th>
          <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
            Order Number
          </th>
          <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
            Sales Person Name
          </th>
          <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
            Loadin point(Godown)
          </th>
          <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
            Bill No
          </th>
          <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
            Order Status
          </th>
          <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
            Referral By (Contractor)
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
          {referralOrders?.orders?.map((i:any,index: number) =>(
               <tr key={index} >
         
               <td
                 className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079] "
            //    onClick={() => navigate(`/admin/contractors/profile/${i?._id}`)}
               >
                 {i?.createdAt.split("T")[0]}
               </td>
               <td className="px-6 py-4 whitespace-nowrap font-inter text-sm  text-[#0078FB] cursor-pointer">
                 {/* {i?.orderNumber}  */}
                 <Link to={i._id} >{i?.orderNumber} </Link>
               </td>
               <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
               {/* {i?.createdBy} */}
               <Link to="pending"> {i?.createdBy} </Link>
               </td>
               <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
               {i?.godowns.map((el:any)=>{return <span>{el}</span>}

               )}
               </td>
               <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                {i?.billNumber}
               </td>
               <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                {i?.status}
               </td>
               <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#0078FB] cursor-pointer"
                onClick={() => navigate(`/admin/contractors/profile/${i?.contractorId}`)}
               >
                {i?.contractorName}
               </td>
             </tr>
          ))}
       
     
       </tbody>


    </table>
  </div>
  )
}

export default CompleteOrders