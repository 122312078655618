import { useSelector } from "react-redux";
import { useAllCategoriesQuery } from "../../Redux/Api/Category";
import CategoryCard from "./categorycard";
import { RootState } from "../../Redux/store";
import { useEffect, useState } from "react";
import { Category } from "../Components/types";
import { Helmet } from "react-helmet-async";
import { chawlaispat } from "../../Redux/apiRoutes";
import usePageMeta from "../../common/hooks/usePageMeta";

const CategoryPage = () => {
  const [search, setSearch] = useState("");
  const allCategories = useSelector(
    (state: RootState) => state.values.allCategories
  );

  const filterCategories = search
    ? allCategories.filter((category: Category) =>
      category.name.toLowerCase().includes(search.toLowerCase())
    )
    : allCategories;

  const title = "Categories | Chawla Ispat | Buy Steel Products Building Materials";
  const description = "Browse various categories on Chawla Ispat to find what you need.";
  const canonicalUrl = chawlaispat;

  usePageMeta(title, description, canonicalUrl);

  return (
    <div className="font-inter bg-white">

      <div className="py-12 w-11/12 m-auto ">
        <div className="flex justify-between">
          <div className="font-[700]  text-[32px] leading-[38px]">
            All Category
          </div>

          <div className="font-[700] hidden md:flex  text-[18px] border rounded-[60px] leading-[38px] p-2">
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search Company..."
              className="rounded-[60px] text-[18px] text-black outline-none"
            />
          </div>
        </div>
        <div className="flex flex-wrap justify-center gap-4 sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-6 py-8">
          {filterCategories?.map((category: Category) => (
            <CategoryCard key={category?.id} category={category} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryPage;
