import { useEffect, useState, useRef } from "react";
import {
  useItemRewardQuery,
  useUpdatedItemRewardMutation,
} from "../../../../Redux/Api/Category";
import Description from "../../../SingleProduct/Components/Description";
import icon from "../../../../assets/Icon.png";

interface Reward {
  rewardA: number;
  rewardB: number;
  rewardC: number;
}

interface RewardComponentProps {
  id: string;
}

// have to manage the category re rendring on this page for category updatation for the new updated value

const ItemRewardComponent = ({ id }: RewardComponentProps) => {
  const [reward, setReward] = useState<Reward>({
    rewardA: 0,
    rewardB: 0,
    rewardC: 0,
  });

  const [catRewards, setCatReward] = useState<Reward>({
    rewardA: 0,
    rewardB: 0,
    rewardC: 0,
  });

  const { data: itemResponse, refetch } = useItemRewardQuery(id);
  const [changeReward] = useUpdatedItemRewardMutation();
  const debounceTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    refetch();
    if (itemResponse && itemResponse.itemRewards) {
      const {
        rewardA = 0,
        rewardB = 0,
        rewardC = 0,
      } = itemResponse.itemRewards;
      const {
        rewardA: catRewardA = 0,
        rewardB: catRewardB = 0,
        rewardC: catRewardC = 0,
      } = itemResponse.categoryRewards;

      setReward({
        rewardA,
        rewardB,
        rewardC,
      });

      setCatReward({
        rewardA: catRewardA,
        rewardB: catRewardB,
        rewardC: catRewardC,
      });
    }
  }, [itemResponse]);

  const handleRewardChange = (type: keyof Reward, value: string) => {
    const updatedReward = { ...reward, [type]: parseFloat(value) };
    setReward(updatedReward);

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      if (id) {
        changeReward({ id, body: updatedReward }).then(() => refetch());
      }
    }, 1000);
  };

  return (
    <section className="bg-white">
      <div className="p-8 w-[70%] mx-auto rounded-xl shadow-xl space-y-8 transition duration-500">
        <h2 className="font-bold text-2xl text-center mb-6">
          Rewards for {itemResponse?.productName}
        </h2>
        <div className="font-semibold space-y-8">
          <div className="flex items-center gap-4 justify-end">
            <p className="w-16 text-center text-black text-[1.2rem] font-semibold border-black p-1 rounded-md">
              Item
            </p>
            <p className="text-center text-black text-[1.2rem] font-semibold border-black p-1 rounded-mdd">
              Category
            </p>
            <p className="w-16 text-center text-black text-[1.2rem] font-semibold border-black p-1 rounded-md">
              Total
            </p>
          </div>
          <div className="flex items-center gap-5">
            <label className="w-1/4">Reward A</label>
            <input
              className="w-3/4 h-2 rounded-full appearance-none cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 slider"
              type="range"
              min="0"
              max="1000"
              step="10"
              value={reward.rewardA}
              onChange={(e) => handleRewardChange("rewardA", e.target.value)}
            />
            <p className="w-16 text-center text-black border-2 border-black p-1 rounded-md shadow-md">
              {reward.rewardA}
            </p>{" "}
            X
            <p className="w-16 text-center text-black border-2 border-black p-1 rounded-md shadow-md">
              {catRewards.rewardA}
            </p>{" "}
            =
            <p className="w-16 text-center text-black border-2 border-black p-1 rounded-md shadow-md">
              {(reward.rewardA * catRewards.rewardA).toFixed(1)}
            </p>
          </div>
          <div className="flex items-center gap-5">
            <label className="w-1/4">Reward B</label>
            <input
              className="w-3/4 h-2 rounded-full appearance-none cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 slider"
              type="range"
              min="0"
              max="1000"
              step="10"
              value={reward.rewardB}
              onChange={(e) => handleRewardChange("rewardB", e.target.value)}
            />
            <p className="w-16 text-center text-black border-2 border-black p-1 rounded-md shadow-md">
              {reward.rewardB}
            </p>{" "}
            X
            <p className="w-16 text-center text-black border-2 border-black p-1 rounded-md shadow-md">
              {catRewards.rewardB}
            </p>{" "}
            =
            <p className="w-16 text-center text-black border-2 border-black p-1 rounded-md shadow-md">
              {(reward.rewardB * catRewards.rewardB).toFixed(1)}
            </p>
          </div>
          <div className="flex items-center gap-5">
            <label className="w-1/4">Reward C</label>
            <input
              className="w-3/4 h-2 rounded-full appearance-none cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 slider"
              type="range"
              min="0"
              max="1000"
              step="10"
              value={reward.rewardC}
              onChange={(e) => handleRewardChange("rewardC", e.target.value)}
            />
            <p className="w-16 text-center text-black border-2 border-black p-1 rounded-md shadow-md">
              {reward.rewardC}
            </p>{" "}
            X
            <p className="w-16 text-center text-black border-2 border-black p-1 rounded-md shadow-md">
              {catRewards.rewardC}
            </p>{" "}
            =
            <p className="w-16 text-center text-black border-2 border-black p-1 rounded-md shadow-md">
              {(reward.rewardC * catRewards.rewardC).toFixed(1)}
            </p>
          </div>
        </div>
      </div>

      <div>
        <div className="w-[80%] m-auto  ">
          <div className=" flex md:gap-5 flex-col md:flex-row lg:gap-10 mt-[1rem] ">
            <div className="text-2xl text-gray-500 my-3  font-medium sm:hidden">
              {itemResponse?.productName}
            </div>

            <div className="px-2 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079] flex gap-x-5">
              <img
                src={
                  itemResponse?.productImage
                    ? itemResponse?.productImage[0]
                    : "https://m.media-amazon.com/images/I/51MLqxTajeL._SX679_.jpg"
                }
                alt="product image"
                className="w-[236px] h-[236px]"
              />
              <div className="flex flex-col space-y-5 p-2">
                <span className="font-semibold text-black  text-[1.5rem]">
                  {itemResponse?.productName}
                </span>
                <span className="text-[1.2rem] font-semibold">
                  Rs {itemResponse?.finalPrice.toFixed(2)}
                </span>

                <div className="space-y-5">
                  <span className="flex items-center">
                 <span className="text-[1.4rem] font-semibold">A .</span>   You will earn {<img className="mx-2" src={icon} alt="icon" />}
                    <span className="text-[20px] mr-2"> {(reward.rewardA * catRewards.rewardA).toFixed(0)} super coins</span>
                  </span>
                  <span className="flex items-center">
                  <span className="text-[1.4rem] font-semibold">B .</span>   You will earn {<img className="mx-2" src={icon} alt="icon" />}
                    <span className="text-[20px] mr-2"> {(reward.rewardB * catRewards.rewardB).toFixed(0)} super coins</span>
                  </span>
                  <span className="flex items-center">
                  <span className="text-[1.4rem] font-semibold">C .</span>   You will earn {<img className="mx-2" src={icon} alt="icon" />}
                    <span className="text-[20px] mr-2"> {(reward.rewardC * catRewards.rewardC).toFixed(0)} super coins</span>
                  </span>            
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Description singleProduct={itemResponse} />
        </div>
      </div>
    </section>
  );
};

export default ItemRewardComponent;
