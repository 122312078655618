import { useLocation, useNavigate } from "react-router-dom";
import { useGetAllContractorQuery } from "../../../../../Redux/Api/Contractor";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setContractorData } from "../../../../../Redux/reducer/adminReducer";
import { IoMdTrophy } from "react-icons/io";

export const RewardNavbar = () => {
  const navigate = useNavigate();
  const { data: ContractorResponse } = useGetAllContractorQuery();
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedNavLink, setSelectedNavLink] = useState<string>(location.pathname);

  const customerProfile = location.pathname.startsWith(
    "/admin/contractors/profile"
  );

  const contractor = ContractorResponse?.data?.totalContractor;

  useEffect(() => {
    if (ContractorResponse) {
      dispatch(setContractorData(ContractorResponse?.data));
    }
  }, [contractor, ContractorResponse]);

  useEffect(() => {
    setSelectedNavLink(location.pathname);
  }, [location.pathname]);

  const handleNavClick = (path: string) => {
    setSelectedNavLink(path);
    navigate(path);
  };

  return (
    <div className="flex flex-col gap-[10px] mb-[10px]">
      <div className="w-full bg-white p-[10px] font-[600] text-[24px] leading-[28px]">
        Reward
      </div>

      <div className="inline-flex items-center border-b border-b-slate-400 font-[400] text-[15px] leading-[22px] px-2 whitespace-nowrap w-fit">
        <div
          className={`inline-flex items-center cursor-pointer px-2 ${selectedNavLink === "/admin/reward" ? "border-b-2 border-blue-500" : "border-b border-transparent"} transition-colors duration-300`}
          onClick={() => handleNavClick("/admin/reward")}
        >
          <span>Reward</span>
        </div>
        <div
          className={`inline-flex items-center cursor-pointer px-2 ${selectedNavLink === "/admin/reward/request" ? "border-b-2 border-blue-500" : "border-b border-transparent"} transition-colors duration-300`}
          onClick={() => handleNavClick("/admin/reward/request")}
        >
          <span>Request For Reward</span>
        </div>
        <div
          className={`inline-flex items-center cursor-pointer px-2 ${selectedNavLink === "/admin/reward/rewardSystem" ? "border-b-2 border-blue-500" : "border-b border-transparent"} transition-colors duration-300`}
          onClick={() => handleNavClick("/admin/reward/rewardSystem")}
        >
          <span>Reward System</span>
        </div>
      </div>
    </div>
  );
};
