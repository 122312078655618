import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

type productData = {
  totalProduct: number;
  publishProduct: number;
  unpublishProduct: number;
};

type ProductsChartProps = {
  data: productData;
};

const COLORS = ['#0088FE', '#FF8042'];

const transformData = (data: productData) => [
  { name: 'Published Products', value: data.publishProduct },
  { name: 'Unpublished Products', value: data.unpublishProduct },
  { name: 'Total Products', value: data.totalProduct },
];

const ProductsChart: React.FC<ProductsChartProps> = ({ data }) => {
  if (!data || data.publishProduct === undefined || data.unpublishProduct === undefined) {
    return <div>No data available</div>;
  }

  const chartData = transformData(data);

  return (
    <div className="bg-gray-100 p-4 rounded-lg shadow-sm">
      <h2 className="text-xl font-semibold mb-4">Products Chart</h2>
      <div className="mb-4 flex gap-2">
        <h4 className="text-lg font-semibold text-gray-800">
          Total Products: {data.totalProduct}
        </h4>
        <h4 className="text-lg font-semibold text-green-500">
          Published Products: {data.publishProduct}
        </h4>
        <h4 className="text-lg font-semibold text-red-500">
          Unpublished Products: {data.unpublishProduct}
        </h4>
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Pie
            data={chartData}
            cx="50%"
            cy="50%"
            outerRadius={100}
            dataKey="value"
            label={({ name, value }) => `${name}: ${value}`}

          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ProductsChart;
