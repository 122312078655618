import { useEffect } from "react";
import SearchCard from "./SearchCard";
import { useSubSearchMutation } from "../../../Redux/Api/ProductAPI";

const RelatedSearch = ({ search }: any) => {
  const [searchMutation, { data: subSearches, isLoading: isSearchLoading }] =
    useSubSearchMutation();

  useEffect(() => {
    if (search) {
      searchMutation(search);
    }
  }, [search, searchMutation]);


  return (
    <div className="  flex flex-col gap-[12px] w-full ">
      <div className="font-[400] text-[20px] leading-[23px]">
        Related Search
      </div>

      <div className="flex flex-wrap gap-[24px]">
        {subSearches?.product?.slice(0, 6).map((item: any) => {
          return (
            <div key={item._id} className="flex-shrink-0">
              <SearchCard name={item?.name}  />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RelatedSearch;
