import { lhwpath } from "../../apiRoutes";
import { api } from "../rtkAPI";

interface DailyData {
    date: string,
    todayContractor: number,
    totalContractorMonth: number
}

type Users = {
    totalContractorTillDate: number,
    dailyData: DailyData[]
}


interface productData {
    totalProduct: number,
    publishProduct: number,
    unpublishProduct: number
}

type Products = {
    message: string,
    productData: productData
}


export const DashboardQuery: any = api.injectEndpoints({
    endpoints: (builder) => ({  
        getProducts: builder.query<Products, string>({
            query: () => {
                return {
                  url: `${lhwpath}/global/dashboard/product `,
                  method: "GET",
                };
              },
             
        }),
        getPagesView: builder.query<any, any>({
            query: () => {
                return {
                  url: `${lhwpath}/global/dashboard/pageviews`,
                  method: "GET",
                };
              },
             
        }),
        getContractors: builder.mutation<Users, string>({
            query: (body) => {
                console.log(body)
                return {
                  url: `${lhwpath}/global/dashboard/user `,
                  method: "POST",
                  body
                };
              },
             
        }),
 

    }),
})

export const { useGetContractorsMutation ,  useGetPagesViewQuery  , useGetProductsQuery } = DashboardQuery;


