import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useDashboardWebsiteVistMutation } from "../../../../Redux/Api/Contractor";
import { HiMiniArrowTrendingUp } from "react-icons/hi2";

interface VisitData {
  [key: string]: number;
}

interface SelectDate {
  year: string;
  month: string;
}

export function VisitChartComponent() {
  const currentYear = new Date().getFullYear();
  const years = [
    "All",
    ...Array.from({ length: 6 }, (_, i) => currentYear - i),
  ];
  const currentMonth = new Date().toLocaleString("default", { month: "long" }); 

  const [currentWebsiteUser, setWebsiteUser] = useState();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [selectDate, setSelectDate] = useState<SelectDate>({
    year: currentYear.toString(),
    month: currentMonth,
  });

  const [chartData, setChartData] = useState<(string | number)[][]>([
    ["Time", "Visit Count"],
  ]);

  const [mutationData, { error: errorData, data: apiData }] =
    useDashboardWebsiteVistMutation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("selectDate", selectDate);
        const data = await mutationData(selectDate);

        if (data && data.data) {
          let formattedData: (string | number)[][] = [["Time", "Visit Count"]];

          if (selectDate.year === "All") {
            formattedData = [["Year", ""]];
            data.data.data.forEach((item: VisitData) => {
              const [year, visitCount] = Object.entries(item)[0];
              formattedData.push([year, visitCount]);
            });
            
            setWebsiteUser(data.data.totalCount);
          } else if (selectDate.month === "") {
            
            formattedData = [["Month", ""]];
            data.data.data.forEach((item: VisitData) => {
              const [month, visitCount] = Object.entries(item)[0];
              formattedData.push([month, visitCount]);
            });
            setWebsiteUser(data.data.totalCount);
          } else {
            formattedData = [["Date", ""]];
            data.data.data.forEach((item: VisitData) => {
              const [date, visitCount] = Object.entries(item)[0];
              formattedData.push([date, visitCount]);
            });
            setWebsiteUser(data.data.totalCount);
          }

          console.log("formattedData", formattedData);
          setChartData(formattedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectDate, mutationData]);

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedYear = event.target.value;
    setSelectDate({
      year: selectedYear,
      month: selectedYear === "All" ? "" : selectDate.month,
    });
  };

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectDate({ ...selectDate, month: event.target.value });
  };

  return (
    <>
      <div className="flex gap-3 items-center">
        <span className="font-bold text-xl">WEBSITE VIEWERS</span>
        <span className="text-red-600 font-bold">Total Viewers :</span>
        <span className="text-green-500 font-bold flex">
          <span>{currentWebsiteUser}</span>
          <span>
            <HiMiniArrowTrendingUp className="text-2xl text-red-700" />
          </span>
        </span>
      </div>
      <div className="flex my-3 space-x-4">
        <div>
          <label htmlFor="year" className="font-bold">
            Select Year:
          </label>
          <select id="year" value={selectDate.year} onChange={handleYearChange}>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>

        {selectDate.year !== "All" && (
          <div>
            <label htmlFor="month" className="font-bold">
              Select Month:
            </label>
            <select
              id="month"
              value={selectDate.month}
              onChange={handleMonthChange}
            >
              <option value="">All Months</option>
          
              {months.map((month, index) => (
                <option key={index} value={month}>
                  {month}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      <Chart
        chartType="Bar"
        width="95%"
        height="400px"
        data={chartData}
      />
    </>
  );
}
