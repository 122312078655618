import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAllCategoriesQuery } from "../../Redux/Api/Category";

import { Bars } from "react-loader-spinner";
import { ScaleLoader } from "react-spinners";

import { useGetCompanyProductMutation } from "../../Redux/Api/Admin/Company";
import { useDispatch, useSelector } from "react-redux";
import {
  addCompany,
  clearResults,
  resetCompany,
} from "../../Redux/reducer/Filter";
import { act } from "react-dom/test-utils";
import { RootState } from "../../Redux/store";
import { setAllCategories, setAllCompanies } from "../../Redux/reducer/toStoreValues";
import { useTranslation } from "react-i18next";
import  defaultImage from "../../assets/item.png"
interface CompanyName {
  basicRate: number;
  description: string;
  id: string;
  image: string;
  name: string;
  productCount: number;
  publish: boolean;
  srNo: number;
}

const Navbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { data: categoriesData, isLoading: categoriesLoading } =
    useAllCategoriesQuery("");
  const [mutation, { data: companyName }] = useGetCompanyProductMutation();
  console.log(companyName);
  useEffect(() => {
    mutation({
      category: [],
    });
  }, [mutation]);

  const [activeItem, setActiveItem] = useState(location.pathname);
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [showCompanyDropdown, setShowCompanyDropdown] = useState(false);

  useEffect(() => {
    if (categoriesData) {
      dispatch(setAllCategories(categoriesData))
    }
  }, [categoriesData])

  useEffect(() => {
    if (companyName) {
      dispatch(setAllCompanies(companyName))
    }
  }, [companyName])

  useEffect(() => {
    setActiveItem(location.pathname);
  }, [location]);

  const resetActiveItem = () => {
    setActiveItem(location.pathname);
  };
  const handleItemPage = () => {
    dispatch(clearResults());
  };

  const handleItemClick = (itemName: any) => {
    setActiveItem(`/items`);
    resetActiveItem();
    navigate(`/items`, { state: { id: itemName } });
    setShowCategoryDropdown(false);
    setShowCompanyDropdown(false);
  };

  const handleClick = (itemName: any) => {
    setActiveItem(`/items`);
    resetActiveItem();
    dispatch(resetCompany());
    dispatch(clearResults());
    dispatch(addCompany(itemName));
    navigate(`/items`, { state: { company: itemName } });
    setShowCategoryDropdown(false);
    setShowCompanyDropdown(false);
  };

  const handleCategoryHover = (value: boolean) => {
    setShowCategoryDropdown(value);
  };

  const handleCompanyyHover = (value: boolean) => {
    setShowCompanyDropdown(value);
  };

  const handleClose = () => {
    setShowCategoryDropdown(false);
    setShowCompanyDropdown(false);
  }


  return (

    <nav
      className="bg-white w-full  p-1 items-center gap-6 font-inter text-[#252F3D] font-semibold text-sm  hidden sm:block sticky   z-40 border shadow-sm"
    >
      <div className="relative flex items-center justify-evenly w-full  font-inter text-[#252F3D] font-semibold text-sm cursor-pointer  group">
        <Link
          to="/"
          onClick={resetActiveItem}
          className={`${activeItem === "/" ? "text-red-500" : "hover:text-blue-400"
            } hover:underline`}
        >
          Home
        </Link>
        <div
          className="group"
          onMouseEnter={() => handleCategoryHover(true)}
          onMouseLeave={() => handleCategoryHover(false)}
        >
          <div className="text-gray-800 hover:text-gray-900 px-4 py-2 cursor-pointer">
            <Link
              to={"/company"}
              className={`${activeItem === "/company"
                  ? "text-red-500"
                  : "hover:text-blue-400"
                } hover:underline`}
            >
              Company
            </Link>
          </div>
          {showCategoryDropdown && (
            <div
              className={`transition-all duration-1000 ease-out ${showCategoryDropdown
                  ? "max-h-[100px] opacity-100"
                  : "max-h-[350px] "
                } `}
            >
              <div className="absolute  bg-white top-8 w-9/12 left-20 z-50  p-2 shadow-lg">
                <div className="grid grid-cols-7 gap-2 ">
                  {companyName &&
                    companyName
                      ?.slice(0, 70)
                      .map((company: CompanyName, index: number) => (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <div className="">
                      
                          <a
                            key={index}
                            href="#"
                            className=" px-2 text-[11px] py-1 text-gray-500 hover:text-black flex gap-x-1 "
                            onClick={() => handleClick(company.id)}
                          >
                               <img src={company.image || defaultImage} alt="" className="h-[11px] w-[11px] rounded-full border-[1px] border-gray-400 mt-1 object-cover" />  {company.name}
                          </a>
                        </div>
                      ))}

                  <Link to={"/company"} onClick={() => handleCategoryHover(false)}>
                    <span className="text-blue-600 text-[12px]">See more</span>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>

        <div
          className="group"
          onMouseEnter={() => handleCompanyyHover(true)}
          onMouseLeave={() => handleCompanyyHover(false)}
        >
          <div className="text-gray-800 hover:text-gray-900 px-4 py-2 cursor-pointer">
            <Link
              to={"/categories"}
              className={`${activeItem === "/categories"
                  ? "text-red-500"
                  : "hover:text-blue-400"
                } hover:underline`}
            >
              Category
            </Link>
          </div>
          {showCompanyDropdown && (
            <div className="absolute  bg-white top-8 w-9/12 left-20 z-50  p-2 shadow-lg">
              {categoriesLoading ? (
                <div className="items-center  m-auto w-[70%]">
                  <ScaleLoader
                    color="#565958"
                    margin={3}
                    width={4}
                    height={23}
                  />
                </div>
              ) : (
                <div className="grid grid-cols-7 gap-2 ">
                  {categoriesData &&
                    categoriesData.map((category: any, index: any) => (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a
                        key={index}
                        href="#"
                        className=" px-2 text-[11px] py-1 text-gray-500 hover:text-black"
                        onClick={() => handleItemClick(category.id)}
                      >
                        {category.name}
                      </a>
                    ))}

                  <Link to={"/categories"} onClick={() => handleCompanyyHover(false)}>
                    <span className="text-blue-600 text-[12px]">See more</span>
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>

        <div onClick={handleItemPage}>
          <Link
            to="/items"
            className={`${activeItem === "/items" ? "text-red-500" : "hover:text-blue-400"
              } hover:underline`}
          >
            Product
          </Link>
        </div>
        <div onClick={handleItemPage}>
          <Link
            to="/assignrewards"
            className={`${activeItem === "/assignrewards" ? "text-red-500" : "hover:text-blue-400"
              } hover:underline`}
          >
            Assigned Rewards

          </Link>
        </div>
        <div>
          <Link
            to="/aboutus"
            className={`${activeItem === "/aboutus" ? "text-red-500" : "hover:text-blue-400"
              } hover:underline`}
          >
            About Us
          </Link>

        </div>
        <div>
          <Link
            to="/order "
            className={`${activeItem === "/order" ? "text-red-500" : "hover:text-blue-400"
              } hover:underline`}
          >
            OrderTracking
          </Link>
        </div>
        <div>
          <Link
            to="/contactus"
            className={`${activeItem === "/contactus"
                ? "text-red-500"
                : "hover:text-blue-400"
              } hover:underline`}
          >
            Contact Us
          </Link>
        </div>
      </div>

    </nav>
  );
};

export default Navbar;
