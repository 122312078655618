import { useEffect, useState, useRef } from "react";
import { useCategoryRewardQuery, useGetCategoryRewardMutation } from "../../../../Redux/Api/Category";

interface Reward {
  rewardA: number;
  rewardB: number;
  rewardC: number;
}

interface RewardComponentProps {
  id: string | null;
  name: string | null;
}

const RewardComponent = ({ id ,name}: RewardComponentProps) => {

  
  const [reward, setReward] = useState<Reward>({
    rewardA: 0,
    rewardB: 0,
    rewardC: 0,
  });

  const { data: categoryResponse, refetch } = useCategoryRewardQuery(id);

  const [changeReward, { data: gradeResponse }] = useGetCategoryRewardMutation();

  const debounceTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (categoryResponse) {
      setReward({
        rewardA: categoryResponse.data.rewardA,
        rewardB: categoryResponse.data.rewardB,
        rewardC: categoryResponse.data.rewardC,
      });
    }
  }, [categoryResponse]);

  useEffect(() => {
    if (gradeResponse) {
      setReward({
        rewardA: gradeResponse.data.rewardA,
        rewardB: gradeResponse.data.rewardB,
        rewardC: gradeResponse.data.rewardC,
      });
      refetch();
    }
  }, [gradeResponse, refetch]);

  const handleRewardChange = (type: keyof Reward, value: string) => {
    const updatedReward = { ...reward, [type]: parseFloat(value) };
    setReward(updatedReward);

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      if (id) {
        changeReward({ id, body: updatedReward }).then(() => refetch());
      }
    }, 1000);
  };

  return (
    <section>
      <div className="p-8 w-[70%] mx-auto bg-gradient-to-r from-blue-900 to-cyan-500 rounded-xl shadow-xl space-y-8 transition duration-500">
        <h2 className="text-white font-bold text-2xl text-center mb-6">Rewards for {name}</h2>
        <div className="text-white font-semibold space-y-8">
          <div className="flex items-center gap-8">
            <label className="w-1/4">Reward A</label>
            <input
              className="w-3/4 h-2 rounded-full appearance-none cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 slider"
              type="range"
              min="0"
              max="10.0"
              step="0.1"
              value={reward.rewardA}
              onChange={(e) => handleRewardChange("rewardA", e.target.value)}
            />
            <p className="w-16 text-center bg-gray-800 p-1 rounded-md shadow-md">{reward.rewardA}</p>
          </div>
          <div className="flex items-center gap-8">
            <label className="w-1/4">Reward B</label>
            <input
              className="w-3/4 h-2 rounded-full appearance-none cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 slider"
              type="range"
              min="0"
              max="10.0"
              step="0.1"
              value={reward.rewardB}
              onChange={(e) => handleRewardChange("rewardB", e.target.value)}
            />
            <p className="w-16 text-center bg-gray-800 p-1 rounded-md shadow-md">{reward.rewardB}</p>
          </div>
          <div className="flex items-center gap-8">
            <label className="w-1/4">Reward C</label>
            <input
              className="w-3/4 h-2 rounded-full appearance-none cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 slider"
              type="range"
              min="0"
              max="10.0"
              step="0.1"
              value={reward.rewardC}
              onChange={(e) => handleRewardChange("rewardC", e.target.value)}
            />
            <p className="w-16 text-center bg-gray-800 p-1 rounded-md shadow-md">{reward.rewardC}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RewardComponent;
