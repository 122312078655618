// ItemStatus.jsx

import React from "react";
import { RxCross2 } from "react-icons/rx";
import { BsThreeDotsVertical } from "react-icons/bs";

const ItemStatus = ({ i, index, openPopupIndex,handleStatusChange, handleTogglePopup }: any) => {
    
  
  return (
    <td className="w-[114px] relative h-[23px] whitespace-nowrap font-inter text-sm  rounded-lg text-[#FFFFFF]">
      <div
        className={`text-black  relative flex justify-between p-2 items-center border rounded-[4px] ${
          i.publish ? "bg-green-400" : "bg-red-400"
        }`}
      >
        <span>{i.publish ? "Published" : "Unpublished"}</span>
        <span className="" onClick={() => handleTogglePopup(index)}><BsThreeDotsVertical/></span>
      </div> 

      {openPopupIndex === index && (
        <div className=" absolute top-4 -left-3 z-50 items-center justify-end text-black rounded-md opacity-1 transition-opacity duration-500">
          <div
            onClick={() => {
              handleTogglePopup(index);
            }}
            className="border-[1px] cursor-pointer absolute -right-[150%] top-[20%] rounded-full border-black"
          >
            <RxCross2 className=" cursor-pointer"/>
          </div>
          <div className=" flex flex-col gap-[30px] w-[50px] h-[150px] p-6 rounded-lg">
            <div className="flex h-[4rem] w-[7rem] flex-col items-center justify-center rounded-lg border-[1px] bg-white p-2 ">
              <div className="cursor-pointer flex gap-[5px]  items-center">
                <p className="text-sm font-medium tracking-[0.25px] " onClick={() => handleStatusChange("publish",i.itemCode,index)}>
                  Publish
                </p>
              </div>
              <div className="cursor-pointer flex gap-[5px] items-center px-[15px] h-9 w-[80px] ">
                <p className="text-sm font-medium  tracking-[0.25px] " onClick={() => handleStatusChange("unPublish",i.itemCode,index)}>
                  Unpublish
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </td>
  );
};

export default ItemStatus;
