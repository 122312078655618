import  {  useState } from "react";
import {  useNavigate } from "react-router-dom";
import chaw from "../../assets/welder.png";
import { FaHandshake } from "react-icons/fa";
import tata from "../../assets/tata.png";
import haier from "../../assets/haeir.png";
import voltas from "../../assets/voltas.png";
import Sliders from "./Slidder";
import { Certificates } from "./Certificates";
import { chawlaispat } from "../../Redux/apiRoutes";
import usePageMeta from "../../common/hooks/usePageMeta";

const AboutUs = () => {
  const [isFlipped, setIsFlipped] = useState<boolean>(false);
  const navigate = useNavigate();
  
  const handleMouseEnter = () => {
    setIsFlipped(true);
  }

  const handleMouseLeave = () => {
    setIsFlipped(false);
  }


  const title = "About Us | Chawla Ispat | Buy Steel Products Building Materials";
  const description = "Learn more about Chawla Ispat and our mission, values, and services.";
  const baseUrl = chawlaispat;

  usePageMeta(title, description, baseUrl);

  return (
    <div className="font-inter bg-white mb-4">
      <div className="flex flex-col gap-[36px] w-[90%] mx-auto  ">
        <div className="px-auto  py-[40px] flex flex-col items-center gap-[48px] ">
          <div className="flex flex-col gap-[24px] items-center">
            <h1 className="font-[700]  text-[32px] leading-[38px] ">
              About Us
            </h1>
           

            <h2 className="font-[500]  text-[16px] leading-[19px] text-slate-600 ">
              We offer instant online quotes, payment and ordering for
              high-quality, high-, custom metal parts – straight from our
              factory.
            </h2>
          </div>

          <div className="w-full bg-[#475569]   rounded-[14px] p-[24px]   flex flex-col justify-center  gap-[12px] ">
            <div className="w-full  h-full    rounded-[14px]    flex flex-col justify-center md:flex-row gap-[48px]  ">
              <div
                className=" hidden
            sm:flex rounded-[14px] border border-[#475569]   md:w-[300px] h-[400px] overflow-hidden"
              >
                <img
                  src={chaw}
                  className="w-full h-full object-cover "
                  alt="chaw"
                />
              </div>

              <p className="font-[400] md:w-2/3 flex flex-wrap text-[16px] md:text-[20px] leading-[36px] text-white  ">
                Chawla Ispat Pvt Ltd, a distinguished name in the iron and steel
                industry, has a rich heritage dating back to its inception as
                Chawla Enterprises in 2002. Rebranded in 2007, we have
                consistently set benchmarks for quality and reliability. Guided
                by our visionary chairman, Chaudhary Lal Chawla, and supported
                by our five dedicated managing directors—Gurbaksh Singh Chawla,
                Santokh Singh Chawla, Mahendra Singh Chawla, Gurbaj Singh
                Chawla, and Simprabh Singh Chawla—we have cemented our
                reputation as a trusted provider of premium building
                materials.Our unwavering commitment to excellence and innovation
                ensures that every client receives exceptional service and
                products, making Chawla Ispat Pvt Ltd a leader in the industry.
              </p>
            </div>

            <div className="flex  justify-center">
              <div
                className="group relative w-[151px] h-[48px] cursor-pointer"
                onClick={() => navigate("/contactus")}
              >
                {/* Default state */}
                <div className="bg-[#EAEDED] flex h-[48px] w-[151px] justify-center items-center border rounded-tr-[50px] rounded-bl-[50px] gap-[4px] shadow-lg shadow-[#475569] transition-opacity duration-300 absolute inset-0 group-hover:opacity-0">
                  <FaHandshake className="text-[#FC5220] w-[19px] h-[18px]" />
                  <div className="text-slate-500 text-sm font-semibold font-['Inter'] leading-tight">
                    Get in touch
                  </div>
                </div>
                {/* Hover state */}
                <div className="bg-[#FC5220] flex h-[48px] w-[151px] justify-center items-center border rounded-tr-[50px] rounded-bl-[50px] gap-[4px] shadow-lg transition-all duration-300 opacity-0 group-hover:opacity-100 group-hover:shadow-2xl absolute inset-0 overflow-hidden">
                  <FaHandshake className="text-white w-[19px] h-[18px] opacity-0 transition-opacity duration-100 delay-300 group-hover:opacity-100" />
                  <div className="text-white text-sm font-semibold font-['Inter'] leading-tight transition-transform duration-300 transform translate-x-full group-hover:translate-x-0">
                    Get in touch
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*cards */}
        <div className="hidden w-full md:flex flex-col gap-[48px]">
          <h1 className="font-[700] text-[38px] md:text-[48px]  leading-[58px]">
            Our Clients
          </h1>

          <div className="flex flex-col sm:flex-row sm:flex-wrap justify-between gap-[23px]">
            <div className="group w-[338px] md:w-[397px] h-[360px]  rounded-[8px] justify-center">
              <div className="relative h-full w-full rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                <div className="absolute inset-0">
                  <img
                    src={tata}
                    alt="tata"
                    className="opacity-100 group-hover:opacity-0 border rounded-[8px]"
                  />
                  <div className="px-[25px] py-[30px] flex flex-col gap-[8px] opacity-100 group-hover:opacity-0">
                    <span className="font-[600] text-[28px] leading-[33px]">
                      Tata
                    </span>
                    <p className="font-[400] text-[12px] leading-[16px]">
                      "We have been working with the Chawla Auto Components
                      since its launch and have been very impressed with the
                      quality of the fabrication, the lead times and ease of use
                      of the platform.""
                    </p>
                  </div>
                </div>
                <div className="absolute inset-0 h-full w-full rounded-xl bg-[#5097A4] px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                  <p className="font-[500] text-[22px] leading-[27px] flex  items-center text-white px-[15px] py-[15px] h-full ">
                    "We have been working with the Chawla Auto Components since
                    its launch and have been very impressed with the quality of
                    the fabrication, the lead times and ease of use of the
                    platform."{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="group w-[338px] md:w-[397px] h-[360px]  rounded-[8px] justify-center">
              <div className="relative h-full w-full rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                <div className="absolute inset-0">
                  <img
                    src={haier}
                    alt="haier"
                    className="opacity-100 group-hover:opacity-0 border rounded-[8px]"
                  />
                  <div className="px-[25px] py-[30px] flex flex-col gap-[8px] opacity-100 group-hover:opacity-0">
                    <span className="font-[600] text-[28px] leading-[33px]">
                      Haeir
                    </span>
                    <p className="font-[400] text-[12px] leading-[16px]">
                      "It's amazing you can engineer any special items for us so
                      quickly it is even faster than writing a few words on a
                      piece of paper! Thank you for your support!"{" "}
                    </p>
                  </div>
                </div>
                <div className="absolute inset-0 h-full w-full rounded-xl bg-[#5097A4] px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                  <p className="font-[500] text-[22px] leading-[27px] flex  items-center text-white px-[15px] py-[15px] h-full  ">
                    "It's amazing you can engineer any special items for us so
                    quickly it is even faster than writing a few words on a
                    piece of paper! Thank you for your support!"{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="group w-[338px] md:w-[397px] h-[360px]  rounded-[8px] justify-center">
              <div className="relative h-full w-full rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                <div className="absolute inset-0">
                  <img
                    src={voltas}
                    alt="voltas"
                    className="opacity-100 group-hover:opacity-0 border rounded-[8px]"
                  />
                  <div className="px-[25px] py-[30px] flex flex-col gap-[8px] opacity-100 group-hover:opacity-0">
                    <span className="font-[600] text-[28px] leading-[33px]">
                      Voltas
                    </span>
                    <p className="font-[400] text-[12px] leading-[16px]">
                      "Best contract manufacturing in India, professional and
                      diligent management. Feel safe and taken care bt it's
                      CEO's experience in business and integrity. Chawla Auto
                      Components is a secured bet."{" "}
                    </p>
                  </div>
                </div>
                <div className="absolute inset-0 h-full w-full rounded-xl bg-[#5097A4] px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                  <p className="font-[500] text-[22px] leading-[27px] flex justify-center  items-center text-white h-full   ">
                    "Best contract manufacturing in India, professional and
                    diligent management. Feel safe and taken care bt it's CEO's
                    experience in business and integrity. Chawla Auto Components
                    is a secured bet."{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-[120px] mx-auto md:px-[104px] w-4/5 md:w-full flex justify-center items-center ">
        <Sliders />
      </div>

      <div className="mx-auto w-4/6 md:w-full flex justify-center md:px-[104px]">
        <Certificates />
      </div>
    </div>
  );
};

export default AboutUs;
