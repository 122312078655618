import { useEffect, useState } from "react";
import { CgProfile } from "react-icons/cg";
import { CiMedicalClipboard } from "react-icons/ci";
import { FaHourglassHalf } from "react-icons/fa6";
import { TbMessageCircleQuestion } from "react-icons/tb";
import { MdOutlineLogout } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../../Redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useLogoutApiMutation } from "../../../Redux/Api/Contractor";
import { LoginResponse } from "../../../Redux/reducer/LoginReducer";
import Profile from "../../../assets/profile.jpg"

export const ProfileSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [sidebar, setSidebar] = useState<string>(location.pathname);
  const userDetails = useSelector(
    (state: RootState) => state.Login.ContractorData
  );

  useEffect(() => {
    setSidebar(location.pathname);
  }, [location.pathname]);

  const handleRouting = (pathname: string) => {
    navigate(pathname);
    setSidebar(pathname);
  };

  const [LogoutCall, { data: logoutData, isLoading, isError }] =
    useLogoutApiMutation();

  const handleLogout = async () => {
    const result = await LogoutCall().unwrap();
    if (result?.success) {
      dispatch(LoginResponse(null));
      navigate("/login"); // Redirect to login page after logout
    }
  };

  useEffect(() => {
    if (logoutData && logoutData.success) {
      dispatch(LoginResponse(null));
      navigate("/login"); // Redirect to login page after logout
    }
  }, [logoutData, dispatch, navigate]);

  return (
    <div className="px-[12px] lg:flex flex-col justify-between border-r w-[266px] h-[80vh] pb-[10px] hidden">
      <div className="flex flex-col gap-[10px] py-[12px]">
        <div className="py-[10px] px-[8px]  gap-[10px] flex items-center border-b">
          {userDetails?.isVerified && (
            <div className="ml-[4px] hidden sm:block ">
              <Link to={"/profile"}>
                <div className="w-10 h-10 border rounded-full  bg-white text-turkishBlue-dark font-bold text-xl text-center flex items-center justify-center ">
               <img src= {Profile} alt="profile" className="rounded-full" />
                </div>
              </Link>
            </div>
          )}
          <span className="font-[700] text-[16px] leading-[20px]">
            {userDetails?.name}
          </span>
        </div>

        <div className="text-[#6B7280] space-y-2">
          <div
            className={`px-[13px] py-[9px] flex gap-[10px] items-center rounded-[8px] hover:bg-gray-100     ${
              sidebar === "/profile" ? "bg-[#E2E8F0] rounded-[8px]" : ""
            } `}
            onClick={() => handleRouting("/profile")}
          >
            <CgProfile className="cursor-pointer" />
            <span className="font-[400] text-[16px] leading-[19px] cursor-pointer">
              MyProfile
            </span>
          </div>
          <div
            className={`px-[13px] py-[9px] flex gap-[10px] items-center rounded-[8px] hover:bg-gray-100  
           ${
             sidebar === "/profile/rewards" ? "bg-[#E2E8F0] " : ""
           } `}
            onClick={() => handleRouting("/profile/rewards")}
          >
            <TbMessageCircleQuestion className="cursor-pointer" />
            <span className="font-[400] text-[16px] leading-[19px] cursor-pointer">
              Reward Cards
            </span>
            {/* <span className="font-[400] text-[16px] leading-[19px] cursor-pointer">
              Assigned Rewards
            </span> */}
          </div>
          <div
            className={`px-[13px] py-[9px] flex gap-[10px] items-center rounded-[8px] hover:bg-gray-100   ${
              sidebar === "/profile/entry" ? "bg-[#E2E8F0] " : ""
            } `}
            onClick={() => handleRouting("/profile/entry")}
          >
            <CiMedicalClipboard className="cursor-pointer" />
            <span className="font-[400] text-[16px] leading-[19px] cursor-pointer">
              Customer Entry
            </span>
          </div>
          <div
            className={`px-[13px] py-[9px] flex gap-[10px] items-center rounded-[8px] hover:bg-gray-100   ${
              sidebar === "/profile/history" ? "bg-[#E2E8F0]" : ""
            } `}
            onClick={() => handleRouting("/profile/history")}
          >
            <FaHourglassHalf className="cursor-pointer" />
            <span className="font-[400] text-[16px] leading-[19px] cursor-pointer">
              History
            </span>
          </div>
          <div
            className={`px-[13px] py-[9px] flex gap-[10px] items-center rounded-[8px] hover:bg-gray-100  
           ${
             sidebar === "/profile/query" ? "bg-[#E2E8F0] " : ""
           } `}
            onClick={() => handleRouting("/profile/query")}
          >
            <TbMessageCircleQuestion className="cursor-pointer" />
            <span className="font-[400] text-[16px] leading-[19px] cursor-pointer">
              Query
            </span>
          </div>
        </div>
      </div>

      {/* Logout */}
      <div
        className="rounded-[8px] border px-[13px] py-[9px] cursor-pointer flex gap-[10px] items-center text-[#6B7280] bg-slate-50"
        onClick={handleLogout}
      >
        <MdOutlineLogout className="text-black" />
        <span>Logout</span>
      </div>
    </div>
  );
};
