import { useEffect } from "react";
import { useGetDeleteAdminContractorQuery, useGetUnblockAdminContractorMutation } from "../../../Redux/Api/Order";

const ContractorHistory = () => {
  const { data: history, refetch } = useGetDeleteAdminContractorQuery();

  const [unblockContractor] = useGetUnblockAdminContractorMutation();

  useEffect(() => {
    refetch(); // Fetch fresh data whenever the component mounts
  }, [refetch]);

  const handleUnblockContractor = async (id: string) => {
    try {
      await unblockContractor(id).unwrap();
      refetch(); // Fetch fresh data after unblocking a contractor
    } catch (error) {
      console.error("Failed to unblock contractor: ", error);
    }
  };

  return (
    <div>
      <section className="h-[90vh]">
        {history?.data?.length > 0 && (
          <div>
            {history?.data?.map((item: any) => {
              return (
                <div className=" my-5 m-auto w-[95%]">
                  <div className="bg-white w-full h-full text-[15px] flex px-4 py-2 items-center justify-between  divide-gray-200">
                    <div>
                      <ul className=" ">
                        <li className=" items-center flex px-3">
                          <span className="font-semibold text-[16px] leading-[19px] flex text-[#8B8D97]">
                            Contractor Name:
                          </span>
                          <span className="text-gray-600  mx-2  font-semibold  cursor-pointer">
                            {item?.name}
                          </span>
                        </li>
                        <li className=" items-center flex px-3">
                          <span className="font-semibold text-[16px] leading-[19px] flex text-[#8B8D97]">
                            Phone Number:
                          </span>
                          <span className="text-gray-600  mx-2  font-semibold  cursor-pointer">
                            {item?.phoneNumber}
                          </span>
                        </li>
                        <li className=" items-center flex px-3">
                          <span className="font-semibold text-[16px] leading-[19px] flex text-[#8B8D97]">
                            Remark:
                          </span>
                          <span className="text-gray-600  mx-2  font-semibold  cursor-pointer">
                            {item?.remark}
                          </span>
                        </li>
                      </ul>

                      <div className="text-[12px] font-bold text-[#8B8D97] p-2">
                        <span className="text-gray-600 font-bold  mx-2 cursor-pointer">
                          {`${item?.deleteAt?.split("T")[0]} ${
                            item?.deleteAt?.split("T")[1]?.split(":")[0]
                          }:${item?.deleteAt?.split("T")[1]?.split(":")[1]}`}
                        </span>
                      </div>
                    </div>

                    <div className="border-2 p-2 cursor-pointer text-white font-semibold  bg-sky-500 hover:bg-sky-800 text-text"
                    onClick={()=>handleUnblockContractor(item?.contractorId)}
                    >UNBLOCK</div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </section>
    </div>
  );
};

export default ContractorHistory;
