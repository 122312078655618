import React, { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import { useGetCompanyProductMutation } from "../../../Redux/Api/Admin/Company";
import { useDispatch, useSelector } from "react-redux";
import {
  addSubCompany,
  clearSubCompany,
  resetSubCompany,
  setSubSearch,
} from "../../../Redux/reducer/Filter";
import { RootState } from "../../../Redux/store";

const Companies = () => {
  const [dispalycount, setDisplayCount] = useState<number>(7);
  const selectedCategories = useSelector(
    (state: RootState) => state.selection.selectedSubCategories
  );
  const [mutation, { data: CompaniesResponse , isLoading}] =
    useGetCompanyProductMutation();
  // console.log(sidebarCategory,"sidebar")
  const [companyLength, setCompanyLength] = useState(0);
  const [showSeeMore, setShowSeeMore] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    mutation({ category: [...selectedCategories] });
    if (selectedCategories?.length > 0) {
      dispatch(resetSubCompany());
    }
  }, [mutation, selectedCategories,dispatch]);

  // console.log("sidebar catgeory", selectedCategories);

  useEffect(() => {
    if (CompaniesResponse) {
      setCompanyLength(CompaniesResponse?.length);
      setShowSeeMore(
        CompaniesResponse?.length > dispalycount && CompaniesResponse?.length > 7
      );
    }
  }, [CompaniesResponse, dispalycount]);

  const [form, setForm] = useState({
    search: "",
    checkboxes: {} as { [key: string]: boolean },
  });

  const selectedCompanies = useSelector(
    (state: RootState) => state.selection.selectedSubCompanies
  );

  useEffect(() => {
    const updatedCheckboxes = selectedCompanies.reduce(
      (acc: any, category: any) => {
        acc[category] = true;
        return acc;
      },
      {}
    );
    setForm((prevFormData) => ({
      ...prevFormData,
      checkboxes: updatedCheckboxes,
    }));
  }, [selectedCompanies]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    companyId: string
  ) => {
    const { checked } = e.target;

    if (checked) {
      dispatch(addSubCompany(companyId));
      dispatch(setSubSearch (null));  // Dispatch action with the ID of the checked category
    } else {
      dispatch(clearSubCompany(companyId));
    }

    const updatedCheckboxes = { ...form.checkboxes };
    updatedCheckboxes[companyId] = checked;

    // Update the categoryLocation state when the categoryName checkbox is checked or unchecked
    if (checked) {
      updatedCheckboxes[companyId] = true;
    } else {
      delete updatedCheckboxes[companyId];
    }
    const updatedFormData = { ...form, checkboxes: updatedCheckboxes };
    setForm(updatedFormData);
  };

  const filteredCompanies = CompaniesResponse
    ? CompaniesResponse.filter((company: any) => {
        const regex = new RegExp(form.search, "i"); // 'i' flag for case-insensitive matching
        return regex.test(company.name);
      })
    : [];

  const handleMoreClick = () => {
    setDisplayCount((prevCount) => prevCount + 5);
  };

  const companiesToDisplay = filteredCompanies.slice(0, dispalycount);

  useEffect(() => {
    if (CompaniesResponse) {
      setShowSeeMore(
        companiesToDisplay?.length < companyLength &&
          CompaniesResponse.length > 7
      );
    }
  }, [companiesToDisplay?.length, companyLength,CompaniesResponse]);

  return (
    <div className="font-inter">
      <div className="flex flex-col gap-[0.6rem]">
        <div className="font-[700] text-[14px] leading-[16px]">Companies</div>

        <div className="flex border items-center bg-[#F8FAFC] mr-3 py-1 rounded-[7px]">
          <IoSearch size={18} className="text-[#9C3AF] pl-[4px]" />
          <input
            type="text"
            name="search"
            placeholder="Search"
            value={form.search}
            onChange={handleInputChange}
            className="font-inter leading-7 text-[#9CA3AF] ml-1 font-Inter text-md bg-[#F8FAFC] border-[#D1D5DB] focus:outline-none focus:ring-2 focus:ring-[#D1D5DB] rounded-md"
            style={{ outline: "none", boxShadow: "none" }}
          />
        </div>

        <div className="pl-[8px]">
          <div className="font-[500] text-[14px] leading-[16px] flex flex-col gap-[5px]">
            {
              isLoading ? "loading": 
                (companiesToDisplay.length > 0 ? (
                  companiesToDisplay.map((company: any, index: number) => (
                    <div
                      key={index}
                      className="font-[500] text-[14px] leading-[16px]"
                    >
                      <input
                        type="checkbox"
                        id={`myCheckbox${index}`}
                        // name={`myCheckbox${index}`}
                        checked={form.checkboxes[company.id] || false}
                        onChange={(e) => handleCheckboxChange(e, company.id)}
                        className="mr-2"
                      />
                      {company.name}
                    </div>
                  ))
                ) : (
                  <div>No Companies Available</div>
                ))
            
            }
          </div>
        </div>

        {showSeeMore && (
          <div
            className="flex items-center gap-[3px] cursor-pointer"
            onClick={handleMoreClick}
          >
            <IoIosArrowDown />
            <div className="text-[#007185] font-[500] text-[14px] leading-[16px] cursor-pointer">
              See More
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Companies;
