import { useGetPagesViewQuery, useGetProductsQuery } from "../../../Redux/Api/Admin/Dashboard";
import BarChartComponent from "./charts/LineChartComponent";
import { DonutChart } from "./charts/piechart/DonutChart";
import ProductsChart from "./charts/ProductsChart";
import { VisitChartComponent } from "./charts/visitChartComponent";
import { data3 } from "../analytics/charts/piechart/DonutChartBasicDemo";


export const Analytics = () => {
  const {data} = useGetProductsQuery()

  const {pageData} = useGetPagesViewQuery()

  console.log(pageData)

  return (
    <div className="flex flex-col bg-white p-6">
      {/* Main container */}

      <div className="flex w-full">
        <div className="mb-8 w-2/3">
          <VisitChartComponent />
        </div>
        {/* <div className="  w-1/2  pl-16">
          <DonutChart data={data3} width={400} height={400} />
        </div> */}
      </div>

  
      <div className="mb-8">
          <ProductsChart data={data?.productData} />
        </div>

      {/* Contractors Chart Section */}
      <div className="mb-8">
        <BarChartComponent />
      </div>
    </div>
  );
};
