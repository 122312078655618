import React, {  useState } from "react";
import { FaTelegramPlane } from "react-icons/fa";
import { chawlaispat } from "../../Redux/apiRoutes";
import usePageMeta from "../../common/hooks/usePageMeta";

interface FormState {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber: string;
  subject?: string;
  message?: string;
}

interface FormErrors {
  firstName?: string;
  phoneNumber?: string;
  message?: string;
}

const ContactUs = () => {
  const [formData, setFormData] = useState<FormState>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handle form field changes
  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Validate form data
  const validateForm = (): boolean => {
    const errors: FormErrors = {};
    if (!formData.firstName) errors.firstName = "First name is required.";
    if (!formData.phoneNumber) errors.phoneNumber = "Phone number is required.";
    else if (!/^\d{10}$/.test(formData.phoneNumber)) errors.phoneNumber = "Phone number must be 10 digits.";
    if (!formData.message) errors.message = "Message is required.";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Handle form submission
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (validateForm()) {
      const { firstName, phoneNumber, message } = formData;
      const whatsappNumber = "9639921932";
      const encodedMessage = encodeURIComponent(
        `Name: ${firstName}\nPhone Number: ${phoneNumber}\nMessage: ${message}`
      );
      const whatsappURL = `https://wa.me/${whatsappNumber}?text=${encodedMessage}`;

      window.open(whatsappURL, "_blank");

      // Reset form and errors after successful submission
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        subject: "",
        message: "",
      });
      setFormErrors({});
    }

    setIsSubmitting(false);
  };


  const title = "Contact Us | Chawla Ispat | Buy Steel Products Building Materials";
  const description = "Get in touch with Chawla Ispat for any inquiries or support. We're here to help!";
  const canonicalUrl = chawlaispat;

  usePageMeta(title, description, canonicalUrl);
  return (
    <div className="font-inter bg-white">

      <div className="py-[40px] flex flex-col items-center gap-[48px]">
        <div className="flex flex-col gap-[24px] items-center">
          <h1 className="font-[700] text-[32px] leading-[38px]">Contact Us</h1>
          <div className="font-[500] w-11/12 m-auto text-[16px] leading-[19px] text-slate-600">
            Any question or remarks? Just write us a message!
          </div>
        </div>

        <div className="w-11/12 bg-[#5097a4] h-full items-center rounded-[2.5rem] py-[24px] bg-gradient-to-b from-[#5097A4] to-[rgb(192,235,231)]">
          <div className="flex flex-col gap-[3rem] md:flex-row mx-[15px] sm:px-[36px] md:gap-[4.5rem]">
            <div className="relative order-2 mx-4 md:mx-0 overflow-hidden md:order-1 md:w-full md:h-full md:max-w-[30.7rem] border rounded-[14px]">
              {/* Your image */}
              {/* Map container */}
              <div className="h-[450px] w-full bg-transparent">
                <div className="h-full">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d55842.052412769466!2d79.36254392857471!3d28.983569628882858!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a07fd9dbe0d7c7%3A0x3e982a8cc989e74a!2sChawla%20auto%20componant%20sidcul%20pantnagar%20rudrapur!5e0!3m2!1sen!2sin!4v1713431639918!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    className="border:0;"
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            
            </div>

            <div className="font-[400] w-full order-1 md:order-2 text-[20px] leading-[36px] text-gray-900">
              <div className="w-full">
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div className="flex flex-col gap-[24px]">
                    <div className="flex w-full md:justify-between flex-col md:flex-row gap-[1.7rem]">
                      <div className="w-full px-3 md:px-0 bg-transparent">
                        <label
                          htmlFor="firstName"
                          className="block mb-1 font-[500] text-[0.75rem] leading-[20px]"
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          id="firstName"
                          name="firstName"
                          value={formData.firstName || ""}
                          onChange={handleInputChange}
                          className={`w-full border-b border-black outline-none font-[500] text-[14px] leading-[20px] bg-transparent px-3 py-2 ${formErrors.firstName ? 'border-red-500' : ''}`}
                        />
                        {formErrors.firstName && <p className="text-red-500 text-xs mt-1">{formErrors.firstName}</p>}
                      </div>
                    </div>

                    <div className="flex flex-col md:flex-row gap-[2rem]">
                      <div className="w-full px-3 md:px-0">
                        <label
                          htmlFor="phoneNumber"
                          className="block mb-1 font-[500] text-[12px] leading-[20px]"
                        >
                          Phone Number
                        </label>
                        <input
                          type="tel"
                          id="phoneNumber"
                          name="phoneNumber"
                          value={formData.phoneNumber || ""}
                          onChange={handleInputChange}
                          className={`w-full border-b border-black outline-none font-[500] text-[14px] leading-[20px] bg-transparent px-3 py-2 ${formErrors.phoneNumber ? 'border-red-500' : ''}`}
                        />
                        {formErrors.phoneNumber && <p className="text-red-500 text-xs mt-1">{formErrors.phoneNumber}</p>}
                      </div>
                    </div>

                    <div className="flex flex-col gap-[14px] justify-between w-full px-3 md:px-0">
                      <p className="block mb-1 font-[600] text-[14px] leading-[20px]">
                        Select Subject
                      </p>
                      <div className="flex sm:flex-row flex-col gap-3 sm:items-center sm:justify-between ">
                        <div className="flex items-center ">
                          <input
                            type="radio"
                            id="general"
                            name="subject"
                            value="General Enquiry"
                            checked={formData.subject === "General Enquiry"}
                            onChange={handleInputChange}
                            className="mr-1 "
                          />
                          <label
                            htmlFor="general"
                            className="text-[12px] leading-[20px] "
                          >
                            General Enquiry
                          </label>
                        </div>
                        <div className=" flex items-center gap-4">
                          {" "}
                          <input
                            type="radio"
                            id="personal"
                            name="subject"
                            value="Personal Enquiry"
                            checked={formData.subject === "Personal Enquiry"}
                            onChange={handleInputChange}
                            className="mr-1"
                          />
                          <label
                            htmlFor="personal"
                            className="text-[12px] leading-[20px] "
                          >
                            Personal Enquiry
                          </label>
                        </div>

                        <div className=" flex items-center gap-4">
                          <input
                            type="radio"
                            id="company"
                            name="subject"
                            value="Company Enquiry"
                            checked={formData.subject === "Company Enquiry"}
                            onChange={handleInputChange}
                            className="mr-1"
                          />
                          <label
                            htmlFor="company"
                            className="text-[12px] leading-[20px] "
                          >
                            Company Enquiry
                          </label>
                        </div>
                      </div>
                    </div>


                    <div className="w-full px-3 md:px-0">
                      <label
                        htmlFor="message"
                        className="block mb-1 font-[500] text-[12px] leading-[20px]"
                      >
                        Message
                      </label>
                      <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        className={`w-full border px-3 rounded-md bg-white border-black outline-none font-[500] text-[14px] leading-[20px] bg-transparent py-2 ${formErrors.message ? 'border-red-500' : ''}`}
                        placeholder="Write Your Message...."
                     />
                      {formErrors.message && <p className="text-red-500 text-xs mt-1">{formErrors.message}</p>}
                    </div>

                    <div className="md:mt-[1rem] flex justify-center md:justify-end px-3 md:px-0">
                      <button
                        type="submit"
                        className="bg-white w-full md:w-[9.8rem] shadow-lg mt-1 pr-4 px-4 py-3 rounded-md font-[500] text-[14px] leading-[20px] flex justify-center items-center gap-[7px]"
                      >
                        <FaTelegramPlane className="text-[#5097A4]" />
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
