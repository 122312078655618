import React, { useState } from "react";
import {  useParams } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import ItemStatus from "./InventoryStatus2";
import defaultImage from "../../../assets/item.png";
import { useChangeProductStatusMutation } from "../../../Redux/Api/Admin/Inventory";
import { useGetCompanySearchesQuery } from "../../../Redux/Api/Admin/Company";

export const InventoryCompanyProduct = () => {
  const [inputValue, setInputValue] = useState("");
  // const [selectedOption, setSelectedOption] = useState<number>(10);
  // const [isChecked, setIsChecked] = useState(false);
  // const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState("");
  const itemperPage = 10;
  const { name } = useParams();
const [search,setSearch] = useState("")
  const {
    data: ProductResponse,
    refetch,
    error,
    isLoading,
  } = useGetCompanySearchesQuery({ company: name,search });
  const [changeStatusMutation] = useChangeProductStatusMutation();
  
  // const totalPages = Math.ceil((ProductResponse?.length || 0) / selectedOption);
  // const [updateProductMutation] = useChangeCompanyStatusMutation();
  const [openPopupIndex, setOpenPopupIndex] = useState<number | null>(null);

  const handleTogglePopup = (index: number) => {
    setOpenPopupIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  // Function to handle changes in the input field
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setInputValue(event.target.value);
    setSearch(event.target.value);
  };

  // const toggleRow = (rowId: number) => {
  //   if (selectedRows.includes(rowId)) {
  //     setSelectedRows(selectedRows.filter((id) => id !== rowId));
  //   } else {
  //     setSelectedRows([...selectedRows, rowId]);
  //   }
  // };

  // const options = ["9", "8", "7"];

  // const handleSelect = (option: any) => {
  //   setSelectedOption(option);
  //   setLimit(option);
  // };

  // const handleCheckboxChange = () => {
  //   setIsChecked(!isChecked);
  // };

  const handleStatusChange = async (
    status: string,
    itemCode: string,
    index: number
  ) => {

    if (status === "publish") {
      await changeStatusMutation({ itemCode, status: true });
    }
    if (status === "unPublish") {
      await changeStatusMutation({ itemCode, status: false });
    }
    refetch();
    handleTogglePopup(index);
  };

  // const prevPage = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage((prevPage) => prevPage - 1);
  //   }
  // };

  // const nextPage = () => {
  //   if (currentPage < totalPages) {
  //     setCurrentPage((prevPage) => prevPage + 1);
  //   }
  // };

  // Filtered items based on search input
  const filteredItems = ProductResponse
    ? ProductResponse.filter((i: any) =>
        i.name.toLowerCase().includes(inputValue.toLowerCase())
      )
    : [];

  const indexOfLastItem = currentPage * itemperPage;
  const indexOfFirstItem = indexOfLastItem - itemperPage;

  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  // const customerDataString = localStorage.getItem("customerData") || "{}";
  // const customerData: CustomerData = JSON.parse(customerDataString);

  if (error) {
    console.error("Error fetching data:", error);
    return <div>Error fetching data</div>;
  }

  return (

            <div className="bg-[#FFFFFF] rounded-lg mx-4 my-4 px-4 py-3">
              <div className="flex flex-row justify-between">
                <div className="font-inter">
                  <h1 className="text-[#4B5563]">Companies</h1>
                </div>
                <div className="flex flex-row gap-3 ">
                  {/* Search */}
                  <div className="w-full flex flex-row items-center p-1 gap-3 border border-[#CFD3D4] rounded-lg">
                    <span>
                      <FiSearch size={20} className="text-[#130F26]" />
                    </span>
                    <input
                      type="text"
                      value={search}
                      onChange={handleChange}
                      placeholder="Search"
                      className="outline-none focus:outline-none text-xs"
                    />
                  </div>
                </div>
              </div>

              {/* Table heading */}
              <div className="overflow-x-auto">
                <table className="table-auto min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]"></th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                        Product
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                        Price
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                        Description
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {isLoading ? (
                      <tr>
                        <td colSpan={5} className="px-6 py-4 text-center">
                          Loading...
                        </td>
                      </tr>
                    ) : error ? (
                      <tr>
                        <td
                          colSpan={5}
                          className="px-6 py-4 text-center text-red-500"
                        >
                          Error fetching data. Please try again.
                        </td>
                      </tr>
                    ) : !ProductResponse || ProductResponse.length === 0 ? (
                        <tr>
                            <td colSpan={5} className="px-6 py-4 text-center">
                                No products currently available.
                            </td>
                        </tr>
                    ) : (
                      currentItems.map((i: any, index: number) => (
                        <tr key={i?._id}>
                          <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                            <img
                              onError={(
                                e: React.SyntheticEvent<HTMLImageElement, Event>
                              ) => {
                                const target = e.target as HTMLImageElement;
                                target.onerror = null;
                                target.src = defaultImage;
                              }}
                              src={i?.image || defaultImage}
                              className="w-[30px] h-[30px] rounded-[12px]"
                              alt="Product Image"
                            />
                          </td>
                          <td className="pl-4 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                            {i?.name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                            {i?.netRate.toFixed()}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                            {i?.description}
                          </td>
                          <ItemStatus
                            key={index}
                            i={i}
                            index={index}
                            handleStatusChange={handleStatusChange}
                            openPopupIndex={openPopupIndex}
                            handleTogglePopup={handleTogglePopup}
                          />
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>

               <div className="flex flex-row justify-between p-2 m-1 border-t-2 border-[#E1E2E9]">
                <div>
                  <div className="flex flex-row gap-3">
                    {/* <Dropdown options={options} onSelect={handleSelect} />
                    <p className="text-[#A6A8B1]">Items per page</p> */}
                    {/* <p className="text-[#666666]">{`${
                      indexOfFirstItem + 1
                    }-${indexOfLastItem} of ${filteredItems.length} items`}</p> */}
                    <p>{currentItems?.length} Company Products</p>
                  </div>
                </div>
                <div>
                  {/* <div className="flex flex-row gap-3">
                    <p className="text-[#666666]">
                      Page {currentPage} of {totalPages}
                    </p>
                    <div className="flex flex-row items-center gap-2 text-[#666666]">
                      <span>
                        <FaChevronLeft onClick={prevPage} size={15} />
                      </span>
                      <span>
                        <FaChevronRight onClick={nextPage} size={15} />
                      </span>
                    </div>
                  </div> */}


                </div>
              </div> 

              
            </div>
       
  );
};
