import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const ProfileHeader = () => {
   const [selectedHeader,setSelectedHeader] = useState("My Profile")
    const arr = [
   {name:"My Profile",route:"/profile"},
   {name:"Customer Entry",route:"/profile/entry"},
   {name:"Query",route:"/profile/query"},
   {name:"Assigned Rewards",route:"/profile/assign"},
   {name:"History",route:"/profile/history"},
   
    ]
    const navigate = useNavigate()
    const handleClick= (name: string,route:string) => {
        setSelectedHeader(name)
        navigate(`${route}`)
    }

  return (
    <div className='px-[12px] py-[10px] lg:hidden flex gap-[16px]  rounded-[6px]'>

   
    <div className=' flex gap-[16px] overflow-x-auto  rounded-[6px]'>
        {arr.map((name,index)=>(
               <button 
               key={index}
               className={`px-[12px] py-[4px] rounded-[6px] flex whitespace-nowrap
                ${selectedHeader === name?.name ? "bg-[#F2FDFF] text-[#5097A4] border border-[#5097A4]" : "bg-[#F2F2F2]" } `} 
                onClick={() => handleClick(name?.name,name?.route)}>
                {name.name}
         </button>
        ))}
 
    </div>
    </div>
  )
}
