import { useCallback, useEffect, useRef, useState } from "react";
import ItemCard from "./Components/ItemCard";
import { useLocation } from "react-router-dom";
import { Filters } from "./Components/Filters";
import Companies from "./Components/Companies";
import noProductFound from "../../assets/noProduct.png";
import {
  useSearchFiltersMutation,
  useSubSearchMutation,
} from "../../Redux/Api/ProductAPI";
import { CombinedState } from "../../types/api-types";
import Pagination from "./pagination";
import { MobileFilter } from "./Components/MobileFilter";
import { RingLoader} from "react-spinners";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { subSearches } from "../../types/types";
import { DetailsBar } from "./Components/Detailsbar";
import { chawlaispat } from "../../Redux/apiRoutes";
import usePageMeta from "../../common/hooks/usePageMeta";

const SubItem = () => {
  const location = useLocation();
  const [sidebarCategory, setSidebarCategory] = useState<any>([]);
  const [sidebarCompany, setSidebarCompany] = useState<any>([]);
  const [searchFilters, { data: SearchProductsResponse, isLoading }] =
    useSearchFiltersMutation();
    const name = useSelector((state: RootState) => state.selection.subSearch);
    const [searchMutation,{data: subSearches, isLoading: isSearchLoading}] = useSubSearchMutation()
    useEffect(() =>{
      if(name ){
        searchMutation(name)
      }
   },[name,searchMutation])
  const [combinedState, setCombinedState] = useState<CombinedState>({
    search: null,
    company: [],
    category: [],
    limit: 12,
    page: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState<boolean>(false);
  const [smFilter, setSmFilter] = useState<any>();
  const [comFilter, setComFilter] = useState<any>();
  const [filterPosition, setFilterPosition] = useState(0);
  
  const categoryBox = useSelector(
    (state: RootState) => state.selection.selectedSubCategories
  );
  const companyBox = useSelector(
    (state: RootState) => state.selection.selectedSubCompanies
  );
  const prevCategoryBoxRef = useRef(categoryBox);
  const prevCompanyBoxRef = useRef(companyBox);
  const total = Math.ceil(SearchProductsResponse?.total / 12);

  const SmallFilter = (data: any) => {
    setSmFilter(data);
  };

  const SmallCompanyFilter = (data: any) => {
    setComFilter(data);
  };

  useEffect(() => {
    if (smFilter || comFilter) {
      setCombinedState((prevState) => ({
        ...prevState,
        // search: null,
        category: smFilter || prevState.category,
        company: comFilter || prevState.company,
        page: 1,
      }));
      if (smFilter) setSidebarCategory(smFilter);
      if (comFilter) setSidebarCompany(comFilter);
    }
  }, [smFilter, comFilter]);

  const arrayEquals = useCallback((a: any[] | null | undefined, b: any[] | null | undefined): boolean => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  },[]);

  const fetchData = useCallback (async () => {
    if (!name ) {
      try {
        const requestPayload = {
          ...combinedState,
          category: categoryBox,
          company: companyBox,
          page: 1, // Reset to first page on filter change
        };
        const { company, category, limit, page } = requestPayload;
        await searchFilters({ combinedState: { company, category, limit, page } });

      } catch (error) {
        console.error('Error found:', error);
      }
    } else {
      console.log('Conditions not met for API call');
    }
  }, [name, categoryBox, companyBox, combinedState, searchFilters]);


  useEffect(() => {
    const hasCategoryChanges = !arrayEquals(prevCategoryBoxRef.current, categoryBox);
    const hasCompanyChanges = !arrayEquals(prevCompanyBoxRef.current, companyBox);
    const hasChanges = hasCategoryChanges || hasCompanyChanges;

    if (hasChanges) {
      fetchData();
      prevCategoryBoxRef.current = categoryBox;
      prevCompanyBoxRef.current = companyBox;
    }
  }, [categoryBox, companyBox, arrayEquals, fetchData]);

  const onPageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
    setCombinedState({
      ...combinedState,
      page: pageNumber,
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const close = (data: any) => {
    setSidebarCategory([]);
    setSidebarCompany([]);
    setFilters(data);
  };

  const handleFilterButtonClick = () => {
    setFilters(!filters);
    if (!filters) {
      setFilterPosition(0);
    }
  };

  const totalItems =
    subSearches && subSearches?.product && subSearches?.product?.length > 0
      ? subSearches?.product?.length
      : SearchProductsResponse?.total;

      useEffect(() => {
        document.title = "Subitem Page - Chawla Ispat";
      }, []);

      const title = "SubItem | Chawla Ispat | Buy Steel Products Building Materials";
      const description = "View detailed information about the selected subitem on the Subitem Page of Chawla Ispat.";
      const canonicalUrl = chawlaispat;
    
      usePageMeta(title, description, canonicalUrl);
    
  return (
    <div className="font-inter  bg-white">
      <DetailsBar
        total={totalItems}
        isLoading={isLoading}
        onClick={handleFilterButtonClick}
      />

      <div className="flex">
        <div className="w-full flex  h-full gap-[2rem] sm:px-[1.67rem] mt-[1.3rem] pb-[32px] ">
          {/* filters */}
          <div className=" w-[270px] hidden sm:flex flex-col gap-[0.6rem] border-r-[2px] sticky top-[14rem] ">
            <Filters />
            <Companies />
          </div>
          {filters && (
            <div
              id="mobile-filter"
              style={{
                transform: `translateY(${Math.max(filterPosition, 0)}px)`,
                maxHeight: `calc(100vh - ${Math.max(filterPosition, 0)}px)`,
              }}
              className="fixed bg-white w-full border-t-[1px] rounded-t-[6px] z-50 sm:hidden bottom-0 flex flex-col justify-center gap-[0.6rem] border-r-[2px] m-0 p-0 overflow-y-auto"
            >
              <MobileFilter
                smallFilter={SmallFilter}
                smallCompanyFilter={SmallCompanyFilter}
                close={close}
                sidebarCategory={sidebarCategory}
                sidebarCompany={sidebarCompany}
              />
            </div>
          )}

          <div className="flex flex-col gap-[1rem] w-[92%] mx-auto">
            <div className="w-full border-b-2 ">
              {isLoading || isSearchLoading ? (
                <div className="flex justify-center h-[50vh] items-center ">
                  <RingLoader color="#5097A4" size={200} />
                </div>
              ) : SearchProductsResponse && SearchProductsResponse?.products?.length > 0 ? (
                SearchProductsResponse?.products?.map( 
                  (product: subSearches) => {
                      return (
                      <ItemCard
                        key={product?.id}
                        data={product}
                       
                      />
                    )
                }
                )
              ) : subSearches &&
              subSearches?.product?.length > 0 ? (
                subSearches?.product?.map(
                  (subSearches: subSearches) => {
               
                  return (
                    <ItemCard
                      key={subSearches?.id}
                      data={subSearches}
                     
                    />
                  );
                  }
                )
              ) : (
                <img src={noProductFound} alt="" />
              )}
            </div>
          </div>
        </div>
      </div>

      {!subSearches && SearchProductsResponse?.products?.length > 0 ? (
        <div className="h-[96px] flex justify-center items-center">
          <div>
            <Pagination
              total={total}
              current={currentPage}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SubItem;
