import { useEffect, useState } from "react";
import logo from "../../assets/logo.svg";
import chawlaLogo from "../../assets/chawlaLogo.png";
import ChawlaIspat from "../../assets/CHAWLA ISPAT.svg";
import IronJunction  from "../../assets/THE IRON JUNCTION.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RxCross1, RxHamburgerMenu } from "react-icons/rx";
import { MobileFilter } from "./sidebar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { BiSolidCoinStack } from "react-icons/bi";
import { useLogoutApiMutation } from "../../Redux/Api/Contractor";
import { LoginResponse, SignupWait } from "../../Redux/reducer/LoginReducer";
import { CgProfile } from "react-icons/cg";
import { LiaCoinsSolid } from "react-icons/lia";
import { TbLogout } from "react-icons/tb";
import { MdLogout } from "react-icons/md";
import { IoSearchOutline } from "react-icons/io5";
import Dropdown from "./Dropdown";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const location = useLocation();
  const [LogoutCall, { data: logoutData }] = useLogoutApiMutation();
  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state: RootState) => state.Login.ContractorData
  );

  const handleLogout = async () => {
    const logoutData = await LogoutCall();
    dispatch(LoginResponse(null));

    closeMenu();
  };

  useEffect(() => {
    if (logoutData && logoutData?.success) {
      dispatch(LoginResponse(null));
    }
  }, [logoutData, dispatch]);

  const navigate = useNavigate();
  const toggleMenu = () => {
    setIsMenuOpen(true);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const closeSideBar = (data: any) => {
    setIsMenuOpen(data);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const menu = document.getElementById("mobile-menu");
      const hamburgerIcon = document.getElementById("hamburger-icon");

      if (
        isMenuOpen &&
        menu &&
        !menu.contains(event.target as Node) &&
        hamburgerIcon &&
        !hamburgerIcon.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    setDropdown(false);
  }, [location.pathname]);

  const closeMenuForSignUp = () => {
    dispatch(SignupWait(false));
    setIsMenuOpen(false);
  };

  const NavigateToProfile = () => {
    navigate("/profile");
    setIsMenuOpen(false);
  };

  return (
    <div className="flex flex-col bg-[#5097A4] px-[20px] py-[10px] sticky top-0 z-50 w-full">
      <div className="flex lg:flex-row justify-between items-center ">
        <div className="flex items-center gap-3">
          <span id="hamburger-icon" className="sm:hidden" onClick={toggleMenu}>
            <RxHamburgerMenu />
          </span>
          <div className="flex sticky top-0 justify-between items-center bg-[#5097A4]">
            <Link to="/" className="">
              <img src={logo} alt="logo" className="h-12 md:hidden " />
            </Link>
            <Link to="/">
              <img
                src={chawlaLogo}
                alt="logo"
                className="h-11 hidden md:block"
              />
            </Link>
            <div>
              <Link to="/" className="flex flex-col gap-[1px]">
                <img src={ChawlaIspat} alt="logo" className="hidden md:block w-[120px]" />
                <div className="w-full h-[0.03rem] bg-black"></div>
                <img src={IronJunction} alt="logo" className="hidden md:block w-[100px]"  />
              </Link>
            </div>
          </div>
        </div>
        <div className="hidden lg:flex bg-white">
          <Dropdown />
        </div>

        <div className=" flex gap-2 items-center">
          <div className="p-1 bg-[#FEBD69] sm:hidden rounded-full">
            <IoSearchOutline
              className="w-5 h-5 sm:hidden text-black"
              onClick={() => navigate("/searchpage")}
            />
          </div>

          {userDetails && (
            <div className="flex gap-[10px] justify-center items-center relative ">
              {userDetails?.isVerified && (
                <div
                  className="flex border-[3px] bg-gray-200 h-[35px] rounded-[20px] justify-between items-center gap-2 p-1"
                  onClick={(event) => {
                    event.stopPropagation();
                    setDropdown(!dropdown);
                  }}
                >
                  <BiSolidCoinStack className="text-[#FF9900]" />
                  <span className="font-[600] text-[14px] leading-[17px]">
                    {userDetails.totalReward}
                  </span>
                  <span className="font-[600] text-[14px] leading-[17px]">
                    {userDetails.name.split(" ")[0].toUpperCase()}
                  </span>
                </div>
              )}
              {userDetails?.isVerified && (
                <div className="ml-[4px] hidden sm:block">
                  <Link to={"/profile"}>
                    <div className="rounded-full h-[50px] w-[50px] bg-white text-blue-700 font-bold text-[40px] text-center flex items-center justify-center">
                      {userDetails.name[0].toUpperCase()}
                    </div>
                  </Link>
                </div>
              )}
              {dropdown && (
                <div
                  id="dropdown"
                  className=" flex flex-col absolute bg-white border rounded-md top-[2.89rem] right-[1rem] overflow-hidden z-50"
                >
                  <div
                    className="px-[12px] py-[8px] hover:bg-slate-300 flex items-center gap-[10px] w-[200px] border-b"
                    onClick={() => navigate("/profile/rewards")}
                  >
                    <LiaCoinsSolid className="text-[#5097A4]" />
                    <span className="font-[500] text-[16px] leading-[19px]">
                      Get Rewards
                    </span>
                  </div>
                  <div
                    className="px-[12px] py-[8px] hover:bg-slate-300 flex items-center gap-[10px] w-[200px] border-b"
                    onClick={handleLogout}
                  >
                    <TbLogout className="text-[#5097A4]" />
                    <span className="font-[500] text-[16px] leading-[19px]">
                      Logout
                    </span>
                  </div>
                  <div
                    className="px-[12px] py-[8px] hover:bg-slate-300 flex items-center gap-[10px] w-[200px] border-b"
                    onClick={() => navigate("/profile")}
                  >
                    <CgProfile className="text-[#5097A4]" />
                    <span className="font-[500] text-[16px] leading-[19px]">
                      Profile
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}

          {userDetails?.name && userDetails?.isVerified ? null : (
            <Link to="/signup" onClick={() => dispatch(SignupWait(false))}>
              <button className="bg-[#FEBD69] font-[600] font-inter md:font-bold whitespace-nowrap flex justify-center items-center md:mb-3 rounded-lg md:py-4 py-2 md:px-[3rem] px-2 my-2 md:text-sm text-[12px] text-[#0F172A] border-2 border-[#FEBD69] h-[40px] leading-[20px] hover:bg-white ml-2 hover:text-[#0F172A] hover:border-[#1E293B] hover:transition hover:duration-500">
                SignUp/Signin
              </button>
            </Link>
          )}
        </div>
      </div>

      {/* mobileMenu */}
      <div className="lg:hidden bg-white">
        <Dropdown />
      </div>

      <div className="relative ">
        {/* Gradient Background */}
        <div
          id="shadow"
          className={`fixed top-0 right-0 bottom-0 z-40  w-[calc(100%-300px)]  ${
            isMenuOpen ? "bg-black opacity-50" : "opacity-0 pointer-events-none"
          }`}
          onClick={() => setIsMenuOpen(false)}
        ></div>
        <div
          className={`lg:hidden bg-white fixed left-0 top-0 bottom-0 z-50 w-[300px] transition-all duration-500 ${
            isMenuOpen
              ? "opacity-100 translate-x-0"
              : "opacity-0 -translate-x-full"
          }`}
        >
          <div className="flex sticky top-0 justify-between items-center w-full border-b  py-[12px]">
            {userDetails?.isVerified && (
              <div className="rounded-[74px] bg-white text-blue-700 font-bold  items-center border w-full mx-1 p-[4px] text-center flex gap-3">
                <span
                  className="border border-turkishBlue-default rounded-full text-[20px] w-[32px] h-[32px]"
                  onClick={NavigateToProfile}
                >
                  {userDetails.name[0].toUpperCase()}{" "}
                </span>{" "}
                <span
                  className="font-bold text-[14px] "
                  onClick={NavigateToProfile}
                >
                  {userDetails.name}
                </span>
              </div>
            )}
          </div>

          <div className="flex flex-col h-[80%] w-full  ">
            <div className="h-full bg-white overflow-auto flex-wrap space-y-4">
              <div className="border-b py-2">
                <MobileFilter closeSideBar={closeSideBar} />
              </div>

              <div className="flex items-center font-semibold text-[16px] border-b leading-[24px] px-3 py-1">
                <Link to="/" onClick={closeMenu}>
                  Home
                </Link>
              </div>
              <div className="flex items-center font-semibold text-[16px] border-b leading-[24px] px-3 py-1">
                <Link to="/items" onClick={closeMenu}>
                  Items
                </Link>
              </div>
              <div className="flex items-center font-semibold text-[16px]  border-b leading-[24px] px-3 py-1">
                <Link to="/order" onClick={closeMenu}>
                  Order
                </Link>
              </div>
              <div className="flex items-center font-semibold text-[16px] border-b leading-[24px] px-3 py-1">
                <Link to="/aboutus" onClick={closeMenu}>
                  About us
                </Link>
              </div>
              <div className="flex items-center font-semibold text-[16px] border-b leading-[24px] px-3 py-1">
                <Link to="/contactus" onClick={closeMenu}>
                  Contact Us
                </Link>
              </div>
              {
                userDetails && <div className="flex items-center font-semibold text-[16px] leading-[24px] px-3">
                  <Link to="/profile/rewards" onClick={closeMenu}>
                    Rewards
                  </Link>
                </div>
              }
            </div>

            {userDetails && (
              <div className=" w-[90%] m-auto  ">
                <button
                  className="font-semibold text-center items-center gap-2 flex justify-center   m-auto w-full text-[1.1rem] text-white py-[12px] px-[16px] bg-[#5097A4] rounded-[10px] leading-[24px] font-inter "
                  onClick={handleLogout}
                >
                  <MdLogout />
                  <span>Logout</span>
                </button>
              </div>
            )}

            {userDetails?.name && userDetails?.isVerified ? null : (
              <Link to="/signup" onClick={closeMenuForSignUp}>
                <button className="bg-[#FEBD69] font-[600] font-inter md:font-bold whitespace-nowrap flex justify-center items-center md:mb-3 rounded-lg md:py-4 py-4 md:px-[3rem] px-2 my-2 md:text-sm text-[20px] text-[#0F172A] border-2 border-[#FEBD69] h-[40px] leading-[20px] hover:bg-white ml-2 hover:text-[#0F172A] hover:border-[#1E293B] hover:transition hover:duration-500 mr-7">
                  SignUp/Signin
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className="fixed top-0 right-3 p-4 z-50 w-[calc(100%-300px)] cursor-pointer">
          <div className="flex justify-end p-4 ">
            <RxCross1
              className="text-[24px] text-white cursor-pointer"
              onClick={closeMenu}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
