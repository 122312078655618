import { lhwpath } from "../apiRoutes"
import { api } from "./rtkAPI";

export const TopQuery: any = api.injectEndpoints({
    endpoints: (builder) => ({
        topSelling: builder.query<any, string>({
            query: () => {
            //  console.log("items added")
              return {
                url: `${lhwpath}/global/top/product?amount=10`,
                method: "GET",
              };
            },
          }),
       

        }),
    });
    
    export const {useTopSellingQuery } = TopQuery;


    