import { BsFillHandbagFill } from "react-icons/bs";
import bigcircle from "../../assets/CIRCLE.svg";
import { useGetCategoryPriceMutation } from "../../Redux/Api/Category";
import { useEffect, useState } from "react";
import PriceSection from "./PriceSection";

interface imageCarouselDetails {
  title: string,
  image: string,
  _id: string
}

type Category = {
  id: string;
  name: string;
  itemCount: number;
  companyCount: number;
  image: string;
  imageCarouselDetails: imageCarouselDetails[];
  productNames: string[];
};

interface Comp1Props {
  categoryId: Category;
}

interface PriceData {
  priceWithOutMargin: number;
  averageDispatchQuantity3Months: number;
  averageDispatchQuantity6Months: number;
}


const Comp1: React.FC<Comp1Props> = ({ categoryId }) => {
  const category = categoryId;
  const [mutation, { data, isLoading }] = useGetCategoryPriceMutation();
  const [categoryPrice, setCategoryPrice] = useState({
    category: ""
  });


  useEffect(() => {
    if (category?.id) {
      setCategoryPrice((prevState) => ({
        ...prevState,
        category: category?.id
      })
      );
    }
  }, [category])

  useEffect(() => {
    if (categoryPrice.category) {
      // Perform the mutation once categoryPrice is updated
      mutation({ categoryPrice })
        .then((response: PriceData) => {
          console.log('Mutation successful:', response);
        })
        .catch((error: any) => {
          console.error('Mutation error:', error);
        });
    }
  }, [categoryPrice, mutation]);
  console.log('data.....',);
  return (
    <>
      <div
        className="flex  flex-col sm:flex-row overflow-hidden sm:justify-between relative left-0 h-[40rem] sm:h-[24rem] md:h-[28rem] lg:h-[35rem]  md:gap-[7rem] 
      bg-[#E2E8F0]"
      >
        <div className="flex flex-col px-[1rem]  my-[1.365rem]  md:my-[2.365rem] lg:mx-[3.5rem] xl:mx-[5.5rem] lg:my-[5.365rem] gap-[0.26rem]  md:gap-[2rem] pt-[2rem] ">
          <div
            className="font-[400] font-bebas-neue  text-xl sm:text-[1.45rem] md:text-[3rem] xl:text-[5rem] leading-[3.6rem] cursor-pointer relative z-30"
          >
            {/* {products?.productName} */}
            {category?.name}
          </div>

          {/* center */}
          <div className="flex flex-row gap-[0.375rem] md:gap-[1.375rem] ">
            {/* star half */}

            {/* weight half */}
            <PriceSection data={data} isLoading={isLoading}/>
            {/* <div className="flex flex-col justify-center gap-0.5 md:w-[13rem]">
              <div className="font-[500] text-[1.49rem] md:text-[2.21rem]  leading-[1.0rem] md:leading-[1.80rem]  text-gray-700  items-center w-full border-l-2 border-l-black pl-1 py-2 ">
                {isLoading ?
                  (<div>
                    ...
                  </div>) : (
                    <div className="w-full text-[23px] flex flex-col  items-start">
                      <div>
                        Start At
                      </div>
                      <div className="mt-2 md:mt-0">
                        {data && `₹${data?.finalPrice.toFixed(2)}/${data?.unit}`}
                      </div>
                    </div>
                    //  data && data?.priceWithOutMargin ? (`₹${data?.priceWithOutMargin?.toFixed(2)}`) : ""
                  )}

              </div>
              <div className="font-[500] text-[0.68rem] md:text-[1.11rem]  leading-[0.9rem] md:leading-[1.80rem]   text-[#9797AA]  items-center">
                ₹55,976.950/Ton
              </div>
            </div> */}
          </div>
          {/* center */}

          <div
            className=" w-[14.5rem] md:w-[17.5rem] h-[3.375rem] border-[0.12rem]  rounded-[0.25rem] flex flex-row justify-center items-center gap-[0.625rem] mt-[0.875rem] cursor-pointer relative z-30 lg:py-3 border-black"
          >
            <BsFillHandbagFill />
            <span>Continue for Better Pricing</span>
          </div>
        </div>

        {/* 2nd div */}

        <div className=" w-full relative flex ">

          <div className=" relative -left-[30px] md:-left-[150px] max-w-[323px]   z-40 flex  flex-col justify-center h-fit  gap-1 items-center border-[0.125rem] rounded-[0.750rem] px-[2rem]    py-[1rem]  sm:mt-[3rem] ml-[4.34rem] sm:ml-[0.375rem]  border-black ">
            <span className=" flex font-[500] text-[16px] md:text-[1.375rem] leading-[1rem] text-black">
              Available in{" "}
            </span>
            <span className="font-[500] flex flex-wrap gap-1 text-[14px] md:text-[1.215rem] leading-[1.1rem] text-[#5C5C77] max-w-[319px] ">
              {category?.productNames?.map((details, index) => (
                <span key={index} className="whitespace-nowrap">
                  {details}
                  {index !== details?.length - 1 && ","}


                </span>
              ))}
              {"And More"}
            </span>
          </div>

          {/* <div className="flex justify-center absolute  top-[8.49rem] sm:top-[9rem] left-[13.38rem] sm:left-[11.2rem] md:top-[12.11rem] md:left-[7.7rem] z-20  h-[0.17rem] bg-gray-400 w-[5.24rem] sm:w-[6.93rem] md:w-[9.74rem] transform rotate-45"
        style={{
          transform: 'rotate(45deg)',
          opacity: 0.8,
        }}
          ></div> */}

          <div className="absolute  bottom-[-120px] sm:bottom-0 left-[11.23rem]  md:left-[2rem]  justify-between h-full  w-[15rem] md:w-[25rem]  lg:w-[25rem] sm:h-[21rem] lg:h-[30rem]">
            <div className="relative  sm:mt-10 md:mt-2 lg:mt-10 z-0 ">
              <img
                src={category?.imageCarouselDetails?.[0]?.image}
                alt={category?.imageCarouselDetails?.[0]?.title}
                className="absolute top-[2rem] md:top-[5.67rem]  right-5  z-10 w-full object-cover"
              />

              <img
                src={bigcircle}
                className="w-[21rem] lg:w-[25rem] absolute top-0 right-5 md:top-14 md:right-0  "
                alt="bigcircle"
              />
            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 h-8 bg-gradient-to-b  from-[#E2E8F0] to-white pointer-events-none z-30">
          <div className="w-full h-full backdrop-blur-[2px]"></div>
        </div>
      </div>
    </>
  );
};

export default Comp1;