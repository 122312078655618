import { IoIosArrowDown } from "react-icons/io";
import { BsDot } from "react-icons/bs";
import { IoIosArrowUp } from "react-icons/io";
import { useEffect, useState } from "react";

const Description = ( props : any) => {
  const [showDescription, setShowDescription] = useState(false);
  const [description, setDescription] = useState(props.singleProduct);
  //  console.log(singleProduct,"description");

   useEffect(()=>{

setDescription(props?.singleProduct)

   },[props?.singleProduct])

  return (
    <div className=" w-11/12 mx-auto mt-[20px] mb-[40px]">
      
      <div className="font-[500] text-[16px] leading-[24px] w-[131px] h-[40px] flex items-center justify-center gap-[3px]">
        <div className="text-[#D58800] underline">Description</div>
      </div>
       <div className="flex flex-col gap-[10px] px-[5px]">

      <div className="border-b-[2px] w-full pb-[6px] px-[5px]">
          <div className="font-[500] text-[19px] leading-[21px]">
            Product description
          </div>
          <span>
            {description?.companyProductDescription}
          </span>
        </div>
        <div className="border-b-[2px] pb-[6px] px-[5px] w-full ">
          <div className="font-[500] text-[19px] leading-[21px]">
            Company Description
          </div>

          <div className=" ">
            <div className="w-full ">
              {description?.companyDescription}  
            </div>

            <div className="w-full   *: py-3">
              <ul className="w-full">
                { description?.companyDescriptionLabels?.map((el:any,index:number)=>{

                  return <li className="border bg-[#F0F2F2] h-full" key={index}>
                  <div className="w-full flex h-full">
                    <div className="w-[30%] h-full font-semibold text-[0.875rem] leading-[1.25rem] pb-2 pt-[0.375rem] px-[0.875rem]">
                      {el?.key?.charAt(0).toUpperCase() + el?.key?.slice(1)}
                    </div>
                    <div className="w-[70%] text-[0.875rem] leading-[1.25rem] pb-2 pt-[0.375rem] px-[0.875rem]">
                    {el?.value}
                    </div>
                  </div>
                </li>
                }) }
               
              </ul>
            </div>
          </div>
        </div>
        <div className="border-b-[2px] pb-[6px] px-[5px] w-full ">
          <div className="font-[500] text-[19px] leading-[21px]">
            Category Description
          </div>

          <div className=" ">
            <div className="w-full">
              {description?.categoryDescription}  
            </div>

            <div className="w-full py-3">
              <ul className="w-full">
                { description?.categoryDescriptionLabels?.map((el:any)=>{

                  return <li className="border bg-[#F0F2F2] h-full">
                  <div className="w-full flex h-full">
                    <div className="w-[30%] h-full font-semibold text-[0.875rem] leading-[1.25rem] pb-2 pt-[0.375rem] px-[0.875rem]">
                      {el.key.charAt(0).toUpperCase() + el.key.slice(1)}
                    </div>
                    <div className="w-[70%] text-[0.875rem] leading-[1.25rem] pb-2 pt-[0.375rem] px-[0.875rem]">
                    {el.value}
                    </div>
                  </div>
                </li>
                }) }
               
              </ul>
            </div>
          </div>
        </div>

        

        <div className="border-b-[2px] pb-[6px] px-[5px] w-full ">
          <div className="font-[500] text-[19px] leading-[21px]">
            Category Item Description
          </div>

          <div className="w-full ">
            <div className="">
              {description?.categoryDescription}  
            </div>

            <div className="w-full py-3">
              <ul className="w-full">
                { description?.categoryProductDescriptionLabels?.map((el:any)=>{

                  return <li className="border bg-[#F0F2F2] h-full">
                  <div className="w-full flex h-full">
                    <div className="w-[30%] h-full font-semibold text-[0.875rem] leading-[1.25rem] pb-2 pt-[0.375rem] px-[0.875rem]">
                      {el.key.charAt(0).toUpperCase() + el.key.slice(1)}
                    </div>
                    <div className="w-[70%] text-[0.875rem] leading-[1.25rem] pb-2 pt-[0.375rem] px-[0.875rem]">
                    {el.value}
                    </div>
                  </div>
                </li>
                }) }
               
              </ul>
            </div>
          </div>
        </div>

        
      </div>

      
    </div>
  );
};

export default Description;
