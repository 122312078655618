import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ContractorData {
    name: string;
  phoneNumber: string;
  email: string;
  city: string;
  pinCode: number;
  gst: string;
  totalReward: number;
  categoryProduct: any[]; 
  contractorGrade: string;
  isVerified: boolean;
  referralCode: string;
  status: boolean;
  role: string;
  dateOfCreation: string; 
}

interface LoginState {
    ContractorData: ContractorData | null;
    role:string | null
    signupWait: boolean
}

const initialState: LoginState= {
    ContractorData : null,
    role:null,
    signupWait:false

};

const LoginSlice = createSlice({
    name: "Login",
    initialState,
    reducers: {
        LoginResponse(state, action: PayloadAction<ContractorData | null>) {
            state.ContractorData = action.payload   
            // console.log(action.payload)
        },
        userRole(state, action: PayloadAction<string>) {
            state.role = action.payload
            // console.log(action.payload)
        },
        SignupWait(state, action: PayloadAction<boolean>) {
            state.signupWait = action.payload
            // console.log(action.payload)
        },

       
    }
});

export const { LoginResponse,userRole, SignupWait } = LoginSlice.actions;
export default LoginSlice.reducer;
