import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useCreateRewardMutation } from "../../../../Redux/Api/Admin/Reward";
import { RiUploadCloud2Line } from "react-icons/ri";

type FormValues = {
  name: string;
  rewardA: number;
  rewardB: number;
  rewardC: number;
  status: boolean;
  image: File | null;
};

export const Coupons = ({ onClose }: any) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [createReward] = useCreateRewardMutation();
  const [couponData, setCouponData] = useState<FormValues>({
    name: "",
    rewardA: 0,
    rewardB: 0,
    rewardC: 0,
    status: true,
    image: null,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCouponData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setCouponData((prevState) => ({
      ...prevState,
      image: file,
    }));
  };

  const triggerFileInput = () => {
    const fileInput = document.getElementById("image") as HTMLInputElement;
    fileInput?.click();
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("name", couponData.name);
      formData.append("rewardA", couponData.rewardA.toString());
      formData.append("rewardB", couponData.rewardB.toString());
      formData.append("rewardC", couponData.rewardC.toString());
      formData.append("status", couponData.status.toString());
      if (couponData.image) {
        formData.append("image", couponData.image);
      }

      const response = await createReward(formData).unwrap();
      onClose();
    } catch (error) {
      console.error("Error creating reward:", error);
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div className="w-[547px] flex flex-col gap-[20px] border rounded-[8px] bg-white shadow-sm">
        <div className="flex justify-between px-[24px] py-[16px] border-b-2">
          <span>Add Reward Points</span>
          <RxCross2
            className="w-[30px] h-[30px] cursor-pointer"
            onClick={onClose}
          />
        </div>
        <div className="gap-[12px] px-[10px] flex flex-col">
          <div className="border p-[7px]">
            <input
              name="name"
              type="text"
              value={couponData.name}
              onChange={handleChange}
              className="w-full border-none outline-none"
              placeholder="Enter Coupon name.."
            />
          </div>

          <div className="flex flex-col gap-[26px] ">
            <span className="font-[600] text-[16px] leading-[19px]">
              Upload Photo
            </span>
            <div className="w-full bg-white py-[6px] border-2 border-[#797979] border-dashed rounded-[12px] flex flex-col justify-center items-center">
              <div className="flex flex-col gap-[6px] items-center justify-center text-center">
                <div>
                  <RiUploadCloud2Line className="w-[40px] h-[40px] text-[#004EBA]" />
                </div>

                <div className="font-[300] text-[14px] leading-[7px] text-[#9CA3AF]">
                  Upload Your Files
                </div>
                <div
                  className="text-[#004EBA] font-[400] text-[16px] leading-[24px] underline cursor-pointer"
                  onClick={triggerFileInput}
                >
                  Browse
                </div>
                <input
                  type="file"
                  id="image"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="hidden"
                />
              </div>
              {couponData.image && (
                <div className="mt-4 text-center">
                  <p className="text-gray-600 font-[500] text-[14px] leading-[24px]">
                    Selected File: {couponData.image.name}
                  </p>
                  <p className="text-gray-500 text-[12px]">
                    Size: {(couponData.image.size / 1024).toFixed(2)} KB
                  </p>
                </div>
              )}
            </div>
          </div>

          <label className="text-[12px] leading-[14px]">Grade A</label>
          <div className="border p-[5px]">
            <input
              name="rewardA"
              type="number"
              value={couponData.rewardA}
              onChange={handleChange}
              className="w-full border-none outline-none"
              placeholder="Enter Rewards points.."
            />
          </div>

          <label className="text-[12px] leading-[14px]">Grade B</label>
          <div className="border p-[5px]">
            <input
              name="rewardB"
              type="number"
              value={couponData.rewardB}
              onChange={handleChange}
              className="w-full border-none outline-none"
              placeholder="Enter Rewards points.."
            />
          </div>

          <label className="text-[12px] leading-[14px]">Grade C</label>
          <div className=" border p-[5px]">
            <input
              name="rewardC"
              type="number"
              value={couponData.rewardC}
              onChange={handleChange}
              className="w-full border-none outline-none"
              placeholder="Enter Rewards points.."
            />
          </div>
        </div>
        <div className="py-[12px] px-[24px]">
          <div
            className="bg-blue-500 text-white py-[8px] border rounded-md flex justify-center items-center cursor-pointer"
            onClick={handleSubmit}
          >
            Save
          </div>
        </div>
      </div>
    </div>
  );
};
