import { lhwpath } from "../apiRoutes";
import { api } from "./rtkAPI";
import { LoginResponse } from "./types";


export const SignupQuery : any  = api.injectEndpoints({
      endpoints: (builder) => ({

       Signup: builder.mutation<any,any>({
        query: (formData) => {
            //    console.log(formData)
                return {
                 url:`${lhwpath}/global/contractor/create`,
                 method:"POST",
                 body: formData
                }
            }
        }),

        Login: builder.mutation<LoginResponse,string>({
          query: (loginData) => {
                //  console.log(loginData)
                  return {
                   url:`${lhwpath}/global/contractor/login`,
                   method:"POST",
                   body: loginData
                  }
              }
          }),


      }),
})

export const { useSignupMutation , useLoginMutation } = SignupQuery