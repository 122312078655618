const Table = ({ productDetails }: any) => {
  //  console.log( data ,"it will")

  return (
    <div className="space-y-7">
      {productDetails?.length>0 &&
        <table className="w-full table-auto border">
          <thead>
            <tr>
              <th className="border-2 border-[#64748B]  bg-[#CBD5E1]">Data</th>
              <th className="border-2 border-[#64748B] px-4 py-2 bg-[#CBD5E1]">
                Values
              </th>
            </tr>
          </thead>
          <tbody>
            {productDetails &&
              productDetails.map((el: any,index:number) => {
                return (
                  <tr key={index}>
                    <td className="border-2 border-[#64748B]  font-[500] text-[16px] leading-[24px] text-center ">
                      {el?.key}
                    </td>
                    <td className="border-2 border-[#64748B]  font-[500] text-[16px] leading-[24px] text-center ">
                      {el?.value}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      }

      <div className="w-full hidden md:block space-y-10">
        <button
          className="bg-[#FEBD69] text-black font-bold py-3 w-full whitespace-nowrap rounded
             hover:bg-white hover:text-[#1E293B] border-2 border-[#FEBD69] hover:border-[#1E293B] hover:transition hover:duration-500"
        >
          Get Best Price
        </button>

        <div
          className="bg-green-500  text-center text-white font-bold py-3  whitespace-nowrap rounded
    hover:bg-white hover:text-[#1E293B] border-2 border-green-500 hover:border-[#1E293B] hover:transition hover:duration-500"
        >
          <a
            href="https://api.whatsapp.com/send?phone=YOUR_PHONE_NUMBER&text=YOUR_MESSAGE"
            target="_blank"
            rel="noopener noreferrer"
          >
            Connect Through WhatsApp
          </a>
        </div>
      </div>
    </div>
  );
};

export default Table;
