import { SearchProductsResponse } from "../../types/api-types";
import { SingleProductData } from "../../types/types";
import { lhwpath } from "../apiRoutes";
import { api } from "./rtkAPI";


export const PriceQuery: any = api.injectEndpoints({
    endpoints: (builder) => ({
        Price: builder.mutation<any, string>({
            query: (bodyData) => {
            //  console.log("items added",bodyData)
              return {
                url: `${lhwpath}/global/getPrice`,
                method: "POST",
                body: bodyData,
              };
            },
          }),
        
        // searchAllProducts: builder.mutation<SearchProductsResponse, string>({
        //     query: (searchTerm) => {
        //       // console.log( "searchh",searchTerm);
                
        //       return {
        //         url: `${lhwpath}/global/searchAssists/${searchTerm}`,
        //         method: "GET",
                
        //       };
        //     },
        //   }),


          searchData: builder.mutation<any, string>({
            query: (searchTerm) => {
              // console.log( "searchh",searchTerm);
                
              return {
                url: `${lhwpath}/global/searchAssists/${searchTerm}`,
                method: "GET",
                
              };
            },
          }),

          singleProduct: builder.mutation<any, string>({
            query: (searchTerm) => {
              // console.log( "searchh",searchTerm);
                
              return {
                url: `${lhwpath}/global/getPriceCalculationData/${searchTerm}`,
                method: "GET",
                
              };
            },
          }),

        }),
    });
    
export const {usePriceMutation, useSearchAllProductsMutation,useSearchDataMutation, useSingleProductMutation } = PriceQuery;