interface ImagesectionProps {
  image: string[];
}

const Imagesection: React.FC<ImagesectionProps> = ({ image }) => {
  // console.log(image, "datais");

  return (
    <div className="flex m-auto xl:w-[36.75rem] lg:w-[34rem] w-[23rem] flex-col-reverse lg:flex-row md:gap-10 gap-[1rem] sm:gap-[1rem]  ">
      <div className="flex lg:flex-col  justify-center gap-[0.250rem] lg:gap-[0.5rem] ">
        <>
          <img
            src={
              image
                ? image[0]
                : "https://m.media-amazon.com/images/I/51MLqxTajeL._SX679_.jpg"
            }
            alt="image"
            className="lg:w-[5.7rem] lg:h-[5.7rem] sm:w-[4.4rem] sm:h-[4.4rem] w-[3.4rem] h-[3.4rem] object-cover border"
          />
          <img
            src={
              image
                ? image[0]
                : "https://m.media-amazon.com/images/I/51MLqxTajeL._SX679_.jpg"
            }
            alt="image"
            className="lg:w-[5.7rem] lg:h-[5.7rem] sm:w-[4.4rem] sm:h-[4.4rem] w-[3.4rem] h-[3.4rem] object-cover border"
          />
          <img
            src={
              image
                ? image[0]
                : "https://m.media-amazon.com/images/I/51MLqxTajeL._SX679_.jpg"
            }
            alt="image"
            className="lg:w-[5.7rem] lg:h-[5.7rem] sm:w-[4.4rem] sm:h-[4.4rem] w-[3.4rem] h-[3.4rem] object-cover border"
          />
          <img
            src={
              image
                ? image[0]
                : "https://m.media-amazon.com/images/I/51MLqxTajeL._SX679_.jpg"
            }
            alt="image"
            className="lg:w-[5.7rem] lg:h-[5.7rem] sm:w-[4.4rem] sm:h-[4.4rem] w-[3.4rem] h-[3.4rem] object-cover border"
          />
          <img
            src={
              image
                ? image[0]
                : "https://m.media-amazon.com/images/I/51MLqxTajeL._SX679_.jpg"
            }
            alt="image"
            className="lg:w-[5.7rem] lg:h-[5.7rem] sm:w-[4.4rem] xsm:h-[4.4rem] w-[2rem] h-[3.4rem] object-cover border"
          />
        </>
      </div>

      <div className=" flex justify-center  ">
        <div className="flex justify-center items-center ">
          <img
            src={
              image
                ? image[0]
                : "https://m.media-amazon.com/images/I/51MLqxTajeL._SX679_.jpg"
            }
            alt="image"
            className="xl:w-[28.125rem] h-[15.5rem] sm:h-[25rem] md:h-[30.5rem]  lg:w-[27rem]   sm:object-cover flex justify-center items-center py-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default Imagesection;
