import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../Redux/store';
import { SignupWait } from '../../Redux/reducer/LoginReducer';

const SignupCheck: React.FC = () => {
  const signupCheck = useSelector((state: RootState) => state.Login.signupWait);

  if (!signupCheck) {
    return <Navigate to="/" replace />;
  }

  return (
    <Outlet />
  );
};

export default SignupCheck;
