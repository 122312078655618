import ComapnyCard from "./CompanyCard";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { useEffect, useState } from "react";
import { Company } from "../Components/types";
import { chawlaispat } from "../../Redux/apiRoutes";
import usePageMeta from "../../common/hooks/usePageMeta";

const CompanyPage = () => {
  const [search, setSearch] = useState("");
  const allCompanies = useSelector(
    (state: RootState) => state.values.allCompanies
  );

  const filterCompanies = search
    ? allCompanies.filter((company: Company) =>
        company.name.toLowerCase().includes(search.toLowerCase())
      )
    : allCompanies;

  useEffect(() => {
    document.title = "Company Page - Chawla Ispat";
  }, []);

 
  const title = "Company | Chawla Ispat | Buy Steel Products Building Materials";
  const description = "Learn more about our company and what we offer at Chawla Ispat.";
  const canonicalUrl = chawlaispat;

  usePageMeta(title, description, canonicalUrl);
  return (
    <div className="font-inter bg-white">
      <div className="py-12 w-11/12 m-auto ">
        <div className="flex justify-between">
          <div className="font-[700]  text-[32px] leading-[38px]">
            All Company
          </div>

          <div className="font-[700] hidden md:flex  text-[18px] border rounded-[60px] leading-[38px] p-2">
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search Company..."
              className="rounded-[60px] text-[18px] text-black outline-none"
            />
          </div>
        </div>

        <div className="flex flex-wrap justify-center gap-4 sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-6 py-8">
          {filterCompanies?.map((company: Company) => (
            <ComapnyCard key={company.id} company={company} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CompanyPage;
