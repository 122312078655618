import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ImgProps } from './types';
import deafultImage from "../../assets/item.png"

const ImgCard: React.FC<ImgProps> = ({ data, smallScreen, largeScreen, category, id }) => {
  const navigate = useNavigate();
  const { image = '', name = ''} = data || {};

  const handleClick = () => {
    navigate('/items', { state: { id } });
  };

  if (smallScreen) {
    return (
      <div
        className="bg-[#F6FAFB] flex flex-col border-[2px] rounded-md w-[104px] shadow-lg overflow-hidden"
        onClick={handleClick}
      >
        <div className="w-[104px] h-[87px] md:w-[6.5rem] md:h-[5rem] overflow-hidden border-b-1">
          <Link to="/items" className="w-full h-full">
            <img src={image? image : deafultImage} className="w-full h-full object-cover" alt={name} />
          </Link>
        </div>
        <h1 className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-center mt-2 font-inter text-sm font-semibold">{name}</h1>
      </div>
    );
  }

  if (largeScreen) {
    return (
      <div
        className="bg-[#F6FAFB] sm:w-[140px] md:w-[140px] justify-center flex flex-col"
        onClick={handleClick}
      >
        <div className="w-[6.5rem] h-[5rem] overflow-hidden mx-auto">
          <Link to="/items">
            <img src={image? image : deafultImage} alt={name} />
          </Link>
        </div>
        <h1 className="flex text-center mt-2 mx-auto font-inter text-sm font-semibold">{name}</h1>
      </div>
    );
  }

  if (category) {
    return (
      <div
        className="bg-[#F6FAFB] w-[150px] justify-center flex flex-col"
        onClick={handleClick}
      >
        <div className="w-[6.5rem] h-[5rem] overflow-hidden mx-auto">
          <Link to="/items">
            <img src={category?.image ? category?.image : deafultImage} alt={name} className='w-full h-full object-cover'/>
          </Link>
        </div>
        <h1 className="flex text-center mt-2 mx-auto font-inter text-sm font-semibold">{name}</h1>
      </div>
    );
  }

  return null;
};

export default ImgCard;
