import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePageMeta = (title: string, description: string, baseUrl: string) => {
  const location = useLocation();

  useEffect(() => {
    // Update document title
    document.title = title;

    // Update meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', description);
    } else {
      const newMetaDescription = document.createElement('meta');
      newMetaDescription.setAttribute('name', 'description');
      newMetaDescription.setAttribute('content', description);
      document.head.appendChild(newMetaDescription);
    }

    // Set canonical link
    let canonicalLink = document.querySelector("link[rel='canonical']");
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      document.head.appendChild(canonicalLink);
    }

    // Set href for canonical link
    canonicalLink.setAttribute(
      'href',
      location.pathname === '/' ? `${baseUrl}` : `${baseUrl}${location.pathname}`
    );

    // console.log("Canonical URL set to:", canonicalLink.getAttribute('href'));

    // Cleanup: Remove only the meta description created during this useEffect call
    return () => {
      const newMetaDescription = document.querySelector(
        'meta[name="description"][content="' + description + '"]'
      );
      if (newMetaDescription) {
        document.head.removeChild(newMetaDescription);
      }
    };
  }, [title, description, baseUrl, location.pathname]);
};

export default usePageMeta;
