import {AllProductsResponse,CombinedState,SearchProductsResponse} from "../../types/api-types";
import { lhwpath } from "../apiRoutes";
import { api } from "./rtkAPI";


interface subSearch {
  id: string,
  name: string,
  images: string
}

export const productsQuery: any = api.injectEndpoints({
    endpoints: (builder) => ({
      searchAllProducts: builder.mutation<SearchProductsResponse, string>({
        query: (searchTerm) => {
         return {
            url: `${lhwpath}/global/searchAssists/${searchTerm}`,
            method: "GET",
            
          };
        },
      }),

      // searchFilters: builder.mutation<SearchProductsResponse, {page:number,combinedState :CombinedState}>({
      //   query: ({page, combinedState}) => {
      //     console.log("hi i m filter api", combinedState, page);
      //     return {
      //       url: `product/search?page=${page}`,
      //       method: "POST",
      //       body: combinedState,
      //     };
      //   }, 
      // }),

      searchFilters: builder.mutation<SearchProductsResponse, {page:number,combinedState :CombinedState}>({
        query: ({page, combinedState}) => {
          return {
            url: `${lhwpath}/global/getProductSearch`,
            // url: `http://localhost:8080/global/getProductSearch`,
            method: "POST",
            body: combinedState
          };
        }, 
      }),

    
      allProducts: builder.query<AllProductsResponse, string>({
        query: () => {
          // console.log("all product query")
          return {
            url: `${lhwpath}/global/searchData/ACCESSORIES roof`,
            method: "GET",
          };
        }
   
      }),

      subSearch: builder.mutation<subSearch, string>({
        query: (name) => {
          // console.log("all product query")
          return {
            url:`/global/subSearch/${name}`,
            method: "GET",
          };
        }
     }),

     
      
      
    }),
  });
  
  
  export const {useAllProductsQuery,
   useSearchAllProductsMutation,
   useSearchFiltersMutation,
    useSubSearchMutation
     
  } = productsQuery


  