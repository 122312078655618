import { LiaCoinsSolid } from "react-icons/lia";
import { useGetRewardsQuery } from "../../../Redux/Api/Admin/Reward";
import thailand from "../../../assets/thailand trip.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { PointsHeader } from "./pointsHeader";
import { chawlaispat } from "../../../Redux/apiRoutes";
import usePageMeta from "../../../common/hooks/usePageMeta";

export const Rewards = () => {
  const { data } = useGetRewardsQuery();
  const userDetails = useSelector(
    (state: RootState) => state.Login.ContractorData
  );

  const title = "Reward - Chawla Ispat";
  const description = "Explore rewards and benefits on Chawla Ispat Reward Page.";
  const baseUrl = chawlaispat;

  usePageMeta(title, description, baseUrl);
  return (
    <div className=" pb-[24px] ">
      {userDetails?.isVerified && <PointsHeader />}

      <div className=" md:px-[80px] px-[20px] py-[36px] flex flex-col gap-[36px]">
        <h1 className="font-[600] text-[36px] leading-[43px] ">Rewards</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-[24px] md:gap-[36px]">
          {data?.response?.map((i: any, index: number) => (
            <div
              className="w-full max-w-[250px] mx-auto gap-[24px] flex flex-col justify-between"
              key={i?.name}
            >
              <div className="h-full ">
                {<img src={i?.image ? i?.image :thailand} className="w-[300px] rounded-md h-[190px] object-cover " alt={i?.name} />}
              </div>

              <div className="flex flex-col gap-[12px]">
                <h2 className="font-[400] text-[16px] leading-[19px]">
                  {i?.name}
                </h2>

                {/* <div className='w-full border border-[#5097A4] rounded-[4px] py-[10px] flex justify-center items-center font-[600] text-[16px] leading-[19px]'>
                                  use  <LiaCoinsSolid className=' text-[#FF9900]'/> {i?.points?.rewardC}
                                </div> */}
                <div className="w-full border border-[#5097A4] hover:bg-[#5097A4] hover:text-white rounded-[4px] py-[10px] flex justify-center items-center font-[600] text-[16px] leading-[19px]">
                  {userDetails ? (
                    <div className="flex gap-2 justify-center items-center">
                      <LiaCoinsSolid className=" text-[#FF9900]" />
                      <span>{i?.rewardValue}</span>
                    </div>
                  ) : (
                    "Claim Now "
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
