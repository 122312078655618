import React from 'react'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../Redux/store';
import { GoBellFill, GoHomeFill } from 'react-icons/go';
import { Link, useLocation } from 'react-router-dom';

export const AdminHeader = () => {
    const userDetails = useSelector(
        (state: RootState) => state.Login.ContractorData
    );
    const location = useLocation()

    const category = location.pathname.startsWith("/admin/category")
    const categoryItem = location.pathname.startsWith("/admin/category/view") ||  location.pathname.startsWith("/admin/category/item")
    const company = location.pathname.startsWith("/admin/company")
    const companyItem = /^\/admin\/company\/[^\/]+$/.test(location.pathname)

    return (

        <div className='bg-white px-2'>
            <div className="flex flex-row justify-between mx-1 items-center">
                <h1 className="font-poppins font-semibold text-[1.3rem] leading-7 text-[#45464E]">Category Management</h1>
                <div className="flex flex-row justify-between items-center gap-3 mr-4 my-1">

                    <div className="flex flex-row bg-[#E0E7FF] rounded-lg px-3 py-1 gap-2 items-center">
                        <p className="text-[#1C1D22] font-inter text-sm">{userDetails?.name}</p>
                        <span>
                            <MdOutlineKeyboardArrowDown
                                size={25} className="text-[#1C1D22]" />
                        </span>
                    </div>
                    <span>
                        <GoBellFill size={22} className="text-[#EF4444]" />
                    </span>
                </div>
            </div>

            <div className="flex flex-row gap-2 items-center ">

                <span>
                    <GoHomeFill size={20} className="text-blue-600" />
                </span>
                <p className="font-inter text-sm text-[#8B8D97]">/</p>
                {category && (
                    <>
                        <Link to="/admin/category">
                            <p className="font-inter text-sm text-[#6366F1]">Category</p>
                        </Link>
                        {categoryItem && (
                            <>
                                <p className=''>/</p>
                                <p className=''>View Item</p>
                            </>
                        )}
                    </>
                )}

                {company && (
                    <>
                     <Link to="/admin/company">
                     <p className="font-inter text-sm text-[#6366F1]">Company</p>
                     </Link>
                 
                        {companyItem && (
                            <>
                                <p className=''>/</p>
                                <p className=''>Product</p>
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}
