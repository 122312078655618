import { CiFilter, CiSearch } from "react-icons/ci";
import { RiFileExcel2Fill } from "react-icons/ri";
import { useGetOrdersDetailsMutation , useGetReferralOrdersMutation } from "../../../Redux/Api/Order";
import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";

const Orders = () => {
  const [border, setBorder] = useState(true);
  const [mutation] = useGetReferralOrdersMutation();
  const [mutationData, { data: orderData }] = useGetOrdersDetailsMutation();

  useEffect(() => {
    const body = { referralOrder: false };
    mutation(body);
    mutationData();
  }, [mutation]);

  return (
    <div className="flex flex-col gap-[10px] bg-slate-100">
      <div className="w-full bg-white p-[10px] font-[600] text-[24px] leading-[28px]">
        Referral Orders
      </div>

      <div className="flex h-[40px] items-center border-b font-[400] text-[15px] leading-[22px] p-2">
        <div
          className={`px-[20px] cursor-pointer  ${
            border ? "border-b-2 border-black" : ""
          } `}
          onClick={() => setBorder(true)}
        >
          <Link to="request">Order request</Link>
        </div>
        <div
          className={`px-[20px] cursor-pointer  ${
            border ? "" : "border-b-2 border-black"
          } `}
          onClick={() => setBorder(false)}
        >
          <Link to="complete">Complete Orders</Link>
        </div>
      </div>

      <div className="h-[70px] white  flex gap-10 text-center bg-white border rounded-[12px] pt-2 pl-7">
        <div>
          <div className="font-[400] text-[16px] leading-[20px] text-[#8B8D97]">
            Total Requests
          </div>
          <div className="font-[700] text-[24px] leading-[28px] text-amber-500">
            {orderData?.data?.totalReferralOrder}
          </div>
        </div>
        <div>
          <div className="font-[400] text-[16px] leading-[20px] text-[#8B8D97]">
            Complete Order
          </div>
          <div className="font-[700] text-[24px] leading-[28px] text-amber-500">
            {orderData?.data?.completeOrders}
          </div>
        </div>
      </div>

      <div className="h-full bg-white">
        {/* <AllContractors /> */}
        <div className="border rounded-[12px]  flex flex-col gap-[24px] py-[20px] px-[20px]">
          {/* First Line */}
          <div className="flex justify-between">
            <div className="flex gap-[7px]">
              <div className="flex items-center w-[170px] h-[30px] gap-[10px] p-[8px] border rounded-[4px]">
                <CiSearch className="w-[16px] h-[16px]" />
                <input
                  type="text"
                  className="w-[90%] border-none outline-none font-[400] text-[12px] leading-[14px]"
                  placeholder="Search"
                />
              </div>

              <div className="flex  w-[65px] h-[30px] border gap-[10px] p-[8px] items-center rounded-[4px] border-[#53545C]">
                <CiFilter />
                <span className="font-[400] text-[11px] leading-[13px]">
                  Filter
                </span>
              </div>
            </div>

            <div className="flex items-center px-[24px] py-[3px] border gap-[4px] rounded-[4px] text-[#16A34A] border-[#16A34A] bg-[#F5F5F5]">
              <span className="font-[600] text-[16px] leading-[20px]">
                {" "}
                Export{" "}
              </span>{" "}
              <RiFileExcel2Fill className="w-[20px] h-[20px]" />
            </div>
          </div>
        </div>
      </div>

      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default Orders;
