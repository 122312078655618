import React from 'react'
import { BiHomeAlt2 } from "react-icons/bi";
import { RiBarChartBoxLine } from "react-icons/ri";
import { LuTruck } from "react-icons/lu";
import { MdErrorOutline } from "react-icons/md";
import { useLocation, useNavigate } from 'react-router-dom';

export const Navigation = () => {
     const navigate= useNavigate()
     const location = useLocation()
      const current = location.pathname

  return (
    <div className='w-full flex sm:hidden justify-between px-5 py-3 bg-white sticky bottom-0 left-0 right-0 z-30'>

            <div className='flex flex-col justify-center items-center' onClick={() => navigate("/")}>
               <div className={`bg-blue-500 h-1 w-1 rounded-full ${current ==="/" ? "flex" : "hidden"}`}></div> 
            <BiHomeAlt2  className={`w-[24px] h-[24px]  ${current == "/" ? "text-blue-500": ""}`} />
            <span className={`font-[600] text-[12px] leading-[14px] ${current === "/" ? "text-blue-500" : ""}`}>Home</span> 
            </div>
             

            <div className='flex flex-col justify-center items-center' onClick={() => navigate("/items")}>
            <div className={`bg-blue-500 h-1 w-1 rounded-full ${current ==="/items" ? "flex" : "hidden"}`}></div> 
              <RiBarChartBoxLine className={`w-[24px] h-[24px]  ${current == "/items" ? "text-blue-500": ""}`} />
              <span className={`font-[600] text-[12px] leading-[14px]  ${current === "/items" ? "text-blue-500" : ""}`}>Products</span>
            </div>


            <div className='flex flex-col justify-center items-center' onClick={() => navigate("/contactus")}>
            <div className={`bg-blue-500 h-1 w-1 rounded-full ${current ==="/contactus" ? "flex" : "hidden"}`}></div> 
              <LuTruck  className={`w-[24px] h-[24px]  ${current == "/contactus" ? "text-blue-500": ""}`} />

              <span className={`font-[600] text-[12px] leading-[14px]  ${current === "/contactus" ? "text-blue-500" : ""}`}>Contact Us</span>
            </div>  


              <div className='flex flex-col justify-center items-center gap-[5px]'  onClick={() => navigate("/aboutus")}>
              <div className={`bg-blue-500 h-1 w-1 rounded-full ${current ==="/aboutus" ? "flex" : "hidden"}`}></div> 
              <MdErrorOutline className={`w-[24px] h-[24px]  ${current == "/aboutus" ? "text-blue-500": ""}`} />

              <span className={`font-[600] text-[12px] leading-[14px]  ${current === "/aboutus" ? "text-blue-500" : ""}`}>About Us</span>
            </div>
    </div>
  )
}
