import React, { useEffect, useState } from "react";
import { useGetAdminContractorHistoryMutation } from "../../../Redux/Api/Order";
import { Link, useNavigate, useParams } from "react-router-dom";
import icon from "../../../assets/Icon.png";

const ContractorOrderHistory = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [mutation, { data: orderData }] =
    useGetAdminContractorHistoryMutation();

  useEffect(() => {
    mutation(id);
  }, [mutation, id]);

  // State to track expanded item by index
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  // Function to toggle expanded state based on index
  const toggleExpanded = (index: number) => {
    setExpandedIndex(prevIndex => (prevIndex === index ? null : index));
  };

  const handleNavigateToCompleteOrderDetails = (orderId: string) => {
    navigate(`/admin/orders/complete/${orderId}`);
  };

  return (
    <section className="min-h-screen bg-gray-100 p-6">
      {orderData?.data?.length > 0 && (
        <div className="space-y-6">
          {orderData?.data?.map((item: any, index: number) => {
            const isExpanded = expandedIndex === index;
            return (
              <div
                key={index}
                className={`relative p-6 rounded-lg shadow-md transition-colors duration-300 ${
                  isExpanded
                    ? "border-l-4 border-blue-500 bg-blue-50"
                    : "bg-white"
                } hover:shadow-lg cursor-pointer`}
                onClick={() => toggleExpanded(index)}
              >
                {item.customerName ? (
                  // Main Order Card
                  <div>
                    <div className="flex justify-between items-center">
                      <div>
                        <h3 className="text-lg font-semibold text-gray-800">
                          Order Number:{" "}
                          <span
                            className="text-blue-600 hover:underline cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleNavigateToCompleteOrderDetails(
                                item?.orderId
                              );
                            }}
                          >
                            {item?.orderNumber}
                          </span>
                        </h3>
                        <p className="text-gray-600">
                          Customer Name: {item?.customerName}
                        </p>
                      </div>
                      <div className="flex items-center space-x-3">
                        <div className="text-lg font-semibold">
                          {item?.type === "add" ? (
                            <span className="text-green-600">
                              +{item?.totalCoins}
                            </span>
                          ) : (
                            <span className="text-red-500">
                              {item?.totalCoins}
                            </span>
                          )}
                        </div>
                        <img src={icon} alt="Coins" className="h-6 w-6" />
                      </div>
                    </div>

                    <div className="mt-4 flex justify-between text-sm text-gray-500">
                      <div>
                        <span className="font-semibold">Edited By: </span>
                        {item?.approveBy}
                      </div>
                      <div>
                        <span className="font-semibold">Date: </span>
                        {item?.createdAt.split(" ")[0]}{" "}
                        {item?.createdAt.split(" ")[1]}
                      </div>
                      <div>
                        <span className="font-semibold text-orange-500">
                          Current Coins: {item?.currentCoins}
                        </span>
                      </div>
                    </div>

                    {isExpanded && (
                      <div className="mt-6 space-y-4">
                        {item?.itemData?.map(
                          (subItem: any, subIndex: number) => (
                            <div
                              key={subItem.combinedName}
                              className="flex justify-between items-center p-4 bg-gray-50 rounded-lg shadow-inner hover:shadow-md transition-all"
                            >
                              <div className="text-md font-semibold text-gray-700">
                                {subIndex + 1}. {subItem.combinedName}
                              </div>
                              <div className="flex items-center space-x-2 text-lg font-semibold text-yellow-500">
                                <img
                                  src={icon}
                                  alt="Coins"
                                  className="h-6 w-6"
                                />
                                <span>{subItem.coins}</span>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  // Alternate Design for No Customer Name (else condition)
                  <div>
                    <div className="flex justify-between items-center">
                      <div>
                        <h3 className="text-lg font-semibold text-gray-700">
                          Bonus Coins Awarded
                        </h3>
                        <p className="text-gray-600">
                          Approved By: {item?.approveBy}
                        </p>
                      </div>
                      <div className="flex items-center space-x-3">
                        <div className="text-lg font-semibold">
                          {item?.type === "add" ? (
                            <span className="text-green-600">
                              +{item?.coins}
                            </span>
                          ) : (
                            <span className="text-red-500">
                              -{item?.coins}
                            </span>
                          )}
                        </div>
                        <img src={icon} alt="Coins" className="h-6 w-6" />
                      </div>
                    </div>

                    <div className="mt-4 flex justify-between text-sm text-gray-500">
                      <div>
                        <span className="font-semibold">Date: </span>
                        {item?.createdAt.split(" ")[0]}{" "}
                        {item?.createdAt.split(" ")[1]}
                      </div>
                      <div>
                        <span className="font-semibold text-orange-500">
                          Current Coins: {item?.currentCoins}
                        </span>
                      </div>
                    </div>

                    {isExpanded && (
                      <div className="mt-6 bg-gray-50 p-5 rounded-lg shadow-inner">
                        <div className="flex items-center space-x-2 text-lg font-semibold text-gray-700">
                          <img
                            src={icon}
                            alt="Coins"
                            className="h-6 w-6"
                          />
                          <span>{item?.coins} Coins Awarded</span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </section>
  );
};

export default ContractorOrderHistory;
