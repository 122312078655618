import React, { useEffect, useState } from "react";
import { CgProfile } from "react-icons/cg";
import { MdMailOutline } from "react-icons/md";
import { FaEye, FaPhoneAlt } from "react-icons/fa";
import { IoIosLock, IoMdEyeOff } from "react-icons/io";
import { MdLocationPin } from "react-icons/md";
import { NewLogin } from "./NewLogin";
import { useSignupMutation } from "../../Redux/Api/Signup";
import profile from "../../assets/ProfileIcon.svg";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "tailwindcss/tailwind.css";
import { useDispatch } from "react-redux";
import { SignupWait } from "../../Redux/reducer/LoginReducer";
import { PiSuitcaseSimpleFill } from "react-icons/pi";
const showToastWarning = (message: string) => {
  toast.warn(message, {
    className: "bg-white text-[#5097A4]",
    autoClose: 2000,
  });
};

const AuthForm: React.FC = () => {
  const [signUp, setSignUp] = useState<boolean>(true);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    city: "",
    pinCode: "",
    gst: "",
    password: "",
    contractorType: "",
  });
  const [mutation, { data: signup, error: signupError }] = useSignupMutation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [passwordSuggestions, setPasswordSuggestions] = useState<string[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === "pinCode" ? parseInt(value) : value,
    });
  };

  const toggleEye = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password: string) => {
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,}$/;
    return passwordRegex.test(password);
  };

  const getPasswordSuggestions = () => {
    const suggestions = [];
    if (formData.password.length < 8) {
      suggestions.push("Password should be at least 8 characters long.");
    }
    if (!/[A-Z]/.test(formData.password)) {
      suggestions.push("Include at least one uppercase letter (A-Z).");
    }
    if (!/[a-z]/.test(formData.password)) {
      suggestions.push("Include at least one lowercase letter (a-z).");
    }
    if (!/\d/.test(formData.password)) {
      suggestions.push("Include at least one numeric digit (0-9).");
    }
    if (!/[@#$%^&+=]/.test(formData.password)) {
      suggestions.push("Include at least one special character (@#$%^&+=).");
    }
    setPasswordSuggestions(suggestions);
  };

  const clearPasswordSuggestions = () => {
    setPasswordSuggestions([]);
  };

  const onSignUp = () => {
    const { name, email, phoneNumber, password, pinCode, contractorType } =
      formData;

    if (!name) {
      showToastWarning("Name is mandatory.");
      return;
    }

    if (!phoneNumber) {
      showToastWarning("Phone Number is mandatory.");
      return;
    }
    if (email && !validateEmail(email)) {
      showToastWarning("Invalid Email.");
      return;
    }
    if (phoneNumber && !/^\d{10}$/.test(phoneNumber)) {
      showToastWarning("Phone Number must be 10 digits.");
      return;
    }

    if (pinCode && !/^\d{6}$/.test(pinCode)) {
      showToastWarning("Wrong Pin Code");
      return;
    }

    if (!password) {
      showToastWarning("Password is mandatory.");
      return;
    }

    if (!validatePassword(password)) {
      showToastWarning("Invalid Password. Please check suggestions.");
      getPasswordSuggestions();
      return;
    }
    mutation(formData);
  };

  useEffect(() => {
    if (signup) {
      dispatch(SignupWait(true));
      navigation("/response");
    } else if (signupError) {
      toast.error(signupError?.data?.message, {
        className: "bg-white text-[#5097A4]",
        autoClose: 1500,
      });
    }
  }, [signup, signupError]);

  const handleClick = () => {
    setSignUp(!signUp);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSignUp();
    }
  };


  return (
    <section>

      <div className="relative min-h-screen -mt-[80px] flex">
        {/* Green Background Div */}
        <div
          className={`absolute hidden  sm:flex items-center p-[7.5rem] lg:p-[12rem] top-0 h-full bg-[#5097A4]   transition-transform duration-500 ${
            signUp ? " shadow-2xl animate-slide text-right " : "animate-slide2 text-left justify-end "
          }`}
        >
         
            <div className={`font-bold text-[4rem]  text-white  ${ signUp ? "animate-wel1": "animate-wel"}`}>
              Welcome
            </div>
         
            <div className={`font-bold text-[4rem]  text-white whitespace-nowrap  ${ signUp ? "animate-joinus1": "animate-joinus"}`}>Join with us</div>
         
        </div>

        <div className="relative w-full">
          {signUp ? (
            <NewLogin handleClick={handleClick} signUp={signUp} />
          ) : (
            <div className="sm:absolute sm:shadow-3xl top-[10rem] my-[24%] sm:m-0 w-[80%] m-auto  animate-signin  right-[7rem] sm:w-[35%] lg:w-[28%]   bg-white sm:px-[15px] sm:py-[15px] border rounded-[30px] sm:rounded-[8px] flex flex-col gap-[18px]">
              <div className="bg-gray-100 w-full  justify-center sm:hidden flex gap-9 border-t rounded-t-[30px] pt-[2rem] font-[600] text-[24px] leading-[29px] text-[#5097A4]">
                <span
                  className={`cursor-pointer pb-2 ${
                    signUp ? "border-b-2 border-[#5097A4]" : "opacity-40"
                  }`}
                  onClick={() => setSignUp(!signUp)}
                >
                  Sign In
                </span>
                <span
                  className={`cursor-pointer pb-2 ${
                    !signUp ? "border-b-2 border-[#5097A4]" : ""
                  }`}
                  onClick={() => setSignUp(!signUp)}
                >
                  Sign Up
                </span>
              </div>
              <div className="flex flex-col px-[25px] sm:p-0    gap-5">
                <div className="flex flex-col gap-[15px]">
                  <div className="w-full border h-[40px] flex p-2 rounded-[4px] items-center gap-[10px]">
                    <span className="w-[8px] h-[8px] border rounded-full bg-[#5097A4] "></span>
                    <img src={profile} className="w-[20px] h-[20px]" alt="profile" />
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      className="w-full h-full border-none outline-none"
                      value={formData.name}
                      onChange={handleChange}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                  <div className="w-full border h-[40px] flex p-2 rounded-[4px] items-center gap-[10px]">
                    <MdMailOutline className="w-[20px] h-[20px] text-[#5097A4]" />
                    <input
                      type="text"
                      name="email"
                      placeholder="Email"
                      className="w-full h-full border-none outline-none"
                      value={formData.email}
                      onChange={handleChange}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                  <div className="w-full border h-[40px] flex p-2 rounded-[4px] items-center gap-[10px]">
                    <span className="w-[8px] h-[8px] border rounded-full bg-[#5097A4] "></span>
                    <FaPhoneAlt className="w-[20px] h-[20px] text-[#5097A4]" />
                    <input
                      type="text"
                      name="phoneNumber"
                      placeholder="Phone Number"
                      className="w-full h-full border-none outline-none"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                  <div className="w-full border h-[40px] flex p-2 rounded-[4px] items-center gap-[10px]">
                    <span className="w-[8px] h-[8px] border rounded-full bg-[#5097A4] "></span>
                    <PiSuitcaseSimpleFill className="w-[20px] h-[20px] text-[#5097A4]" />
                    <input
                      type="text"
                      name="contractorType"
                      placeholder="Contractor Type"
                      className="w-full h-full border-none outline-none"
                      value={formData.contractorType}
                      onChange={handleChange}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                  <div className="w-full h-[40px] flex items-center gap-[20px]">
                    <div className="border rounded-[4px] h-full flex items-center gap-[10px] w-1/2 pl-3">
                      <MdLocationPin className="w-[20px] h-[20px] text-[#5097A4]" />
                      <input
                        type="text"
                        name="city"
                        placeholder="City"
                        className="w-full h-full border-none outline-none"
                        value={formData.city}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                    <div className="border rounded-[4px] w-1/2 h-full flex items-center gap-[10px] pl-3">
                      <input
                        type="text"
                        name="pinCode"
                        placeholder="PinCode"
                        className="w-full h-full border-none outline-none"
                        value={formData.pinCode}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                  </div>
                  <div className="w-full border h-[40px] flex p-2 rounded-[4px] items-center gap-[10px]">
                    <input
                      type={"text"}
                      name="gst"
                      placeholder="GST"
                      className="w-full h-full border-none outline-none"
                      value={formData.gst}
                      onChange={handleChange}
                      onKeyPress={handleKeyPress}
                    />
                  </div>

                  <div>
                    <div className="w-full relative border h-[40px] flex p-2 rounded-[4px] items-center gap-[10px] ">
                      <IoIosLock className="w-[20px] h-[20px] text-[#5097A4]" />
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Enter Password"
                        className="w-full h-full border-none outline-none pr-[20px]"
                        value={formData.password}
                        onChange={handleChange}
                        onFocus={clearPasswordSuggestions} // Clear suggestions on focus
                        onKeyPress={handleKeyPress}
                      />
                      <div
                        onClick={toggleEye}
                        className="cursor-pointer absolute right-[10px] top-[10px]"
                      >
                        {showPassword ? (
                          <FaEye className="w-[20px] h-[20px]" />
                        ) : (
                          <IoMdEyeOff className="w-[20px] h-[20px]" />
                        )}
                      </div>
                    </div>
                    {passwordSuggestions.length > 0 && (
                      <div className=" bg-white shadow-lg p-2">
                        <span className="block text-sm text-gray-500 mb-1">
                          Suggestions:
                        </span>
                        {passwordSuggestions.map((suggestion, index) => (
                          <span
                            key={index}
                            className="block text-xs text-red-500"
                          >
                            {suggestion}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className="font-[600] text-[24px] cursor-pointer leading-[29px] border flex justify-center text-white items-center bg-[#5097A4] py-[5px] rounded-[8px]"
                  onClick={onSignUp}
                >
                  Sign Up
                </div>
                <div
                  className="flex justify-center items-center text-red-500 cursor-pointer"
                  onClick={() => setSignUp(!signUp)}
                >
                  If you have an account
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default AuthForm;
