import React, { useState } from 'react';
import { useEditRewardMutation } from '../../../../../Redux/Api/Admin/Reward';

export const RewardChange = ({ handleClose, id, grade }: any) => {
  const [reward, setReward] = useState<number | ''>('');
  const [editReward, { data: Rewards, isLoading, isError }] = useEditRewardMutation();

  const handleSave = () => {
    let updatedCouponData: { [key: string]: number | boolean } = { status: true };

    if (grade === 'a') {
      updatedCouponData.rewardA = reward === '' ? 0 : reward;
    } else if (grade === 'b') {
      updatedCouponData.rewardB = reward === '' ? 0 : reward;
    } else if (grade === 'c') {
      updatedCouponData.rewardC = reward === '' ? 0 : reward;
    }

    editReward({ id, body: updatedCouponData })
      .unwrap()
      .then(() => {
        // console.log('Mutation successful', updatedCouponData);
        handleClose();
      })
      .catch((error: any) => {
        console.error('Mutation failed', error);
      });
  };

  // console.log(id, grade);

  return (
    <div className='w-[215px] h-[142px] border flex flex-col bg-white px-[15px] py-3 gap-[16px]'>
      <div className='w-[190px] gap-[12px]'>
        <span>
          Set Reward Points
        </span>
        <div className='h-[34px] px-[6px] py-[3px] border rounded-md'>
          <input
            type='number'
            value={reward}
            onChange={(e) => setReward(e.target.value === '' ? '' : Number(e.target.value))}
            className='border-none outline-none'
          />
        </div>
      </div>

      <div className='flex justify-between'>
        <div className='px-[10px] py-[4px] bg-white border rounded-md text-blue-500 border-blue-500 font-[500] text-[13px] leading-[22px]'
          onClick={handleClose}
        >
          CANCEL
        </div>

        <div className='px-[10px] py-[4px] border rounded-md text-white bg-blue-500 font-[500] text-[13px] leading-[22px]'
          onClick={handleSave}
        >
          SAVE
        </div>
      </div>
    </div>
  );
};
