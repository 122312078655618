import { IoIosSearch } from "react-icons/io";
import { useDispatch } from "react-redux";
import { setSubSearch } from "../../../Redux/reducer/Filter";
import { useNavigate } from "react-router-dom";

const SearchCard = ({ name }: any) => {
  const dispatch= useDispatch()
 const navigate = useNavigate()

    const handleClick = () => {
      dispatch(setSubSearch(name))
      navigate("/subItems")
    }

  return (
    <div 
    className="w-[324px] h-[60px] flex items-center pl-[1rem] border gap-[1rem] overflow-hidden"
     onClick={handleClick }
    >
      <IoIosSearch className="w-[15px] h-[15px]" />
      <span className="flex-1 whitespace-nowrap overflow-x-auto">
        {name}
      </span>
    </div>
  );
};

export default SearchCard;
