import React, { useEffect, useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { GoBellFill } from "react-icons/go";
import { Link } from "react-router-dom";
import { GoHomeFill } from "react-icons/go";
import { FiSearch } from "react-icons/fi";
import Dropdown from "./components/Dropdown";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import BlueBox from "./components/AdminComponents/BlueBox";
import { useChangeCompanyStatusMutation } from "../../../Redux/Api/Admin/Inventory";
import ItemStatus from "./InventoryComapnyStatus";
import defaultImage from "../../../assets/item.png";
import { useAdminCompaniesQuery } from "../../../Redux/Api/Admin/Inventory";


interface CustomerData {
  GodownId: string;
  GodownName: string;
  createdAt: number;
  email: string;
  image: string;
  maxAge: number;
  name: string;
  phoneNumber: string;
  role: string;
  success: boolean;
  userId: string;
}

const InventoryCompany = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState("15");
  const [showAll, setShowAll] = useState<boolean>(false);
  const [search,setSearch] = useState<string >("")

  const { data: ProductResponse, error, isLoading, refetch } = useAdminCompaniesQuery({
    page: currentPage,
    limit: limit === "All" ? undefined: limit,
    search:search 
  });
  
  useEffect(() => {
    if (error) {
      console.error("Error fetching data:", error);
    }
  }, [error])


  const [updateProductMutation] = useChangeCompanyStatusMutation();
  const [openPopupIndex, setOpenPopupIndex] = useState<number | null>(null);

  const handleTogglePopup = (index: number) => {
    setOpenPopupIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  // Function to handle changes in the input field
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    setSearch(value);
  };

  const options = ["9", "8", "7", "All"];

  
  const handleSelect = (option: any) => {
    // setSelectedOption(option);
    setLimit(option === "All" ? "All" : option);
  };

  const handleStatusChange = async (
    id: string,
    action: string,
    index: number,
    currentStatus: boolean
  ) => {
    try {
      const status = action === "publish" ? !currentStatus : currentStatus;
      await updateProductMutation({ id, status });
      refetch();
    } catch (error) {
      console.log("error hai", error);
    }
  };

  const prevPage = () => {
    const nextPageNumber = currentPage - 1;
    if (nextPageNumber >= 1) {
      setCurrentPage(nextPageNumber);
    }
  };

  const nextPage = () => {
    const nextPageNumber = currentPage + 1; // Increment currentPage
    setCurrentPage(nextPageNumber); // Update currentPage state
  };

  const customerDataString = localStorage.getItem("customerData") || "{}";
  const customerData: CustomerData = JSON.parse(customerDataString);

  useEffect(() => {}, [currentPage, limit,search]);

  
    // Calculate total pages
    const totalPages = Math.ceil((ProductResponse?.total || 0) / parseInt(limit));


  // Calculate item range for the current page
  const startItemIndex = (currentPage - 1) * parseInt(limit) + 1;
  const endItemIndex = Math.min(currentPage * parseInt(limit), ProductResponse?.total || 0);


  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {(error as Error).message}</div>;
  }

  return (
           <div className="bg-[#FFFFFF] rounded-lg mx-4 my-4 px-4 py-3">
              <div className="flex flex-row justify-between">
                <div className="font-inter">
                  <h1 className="text-[#4B5563]">Companies</h1>
                </div>
                <div className="flex flex-row gap-3 ">
                 
                  <div className="w-full flex flex-row items-center p-1 gap-3 border border-[#CFD3D4] rounded-lg">
                    <span>
                      <FiSearch size={20} className="text-[#130F26]" />
                    </span>
                    <input
                      type="text"
                      value={search}
                      onChange={handleChange}
                      placeholder="Search"
                      className="outline-none focus:outline-none text-xs"
                    />
                  </div>
                </div>
              </div>

              {/* Table heading */}
              <div className="overflow-x-auto">
                <table className="table-auto min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]"></th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                       Company
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                        description
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                      Products
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {ProductResponse && ProductResponse?.result?.length > 0 ? (
                      ProductResponse?.result?.map((i: any, index: number) => (
                        <tr key={i?.id}>
                          <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                            <img
                              src={i?.image || defaultImage}
                              className="w-[30px] h-[30px] rounded-[12px]"
                              alt="Product"
                            />
                          </td>
                          <td className="pl-4 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                            <Link to={`/admin/company/${i?.name}`}>{i?.name}</Link>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                            {i?.description}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                            {i?.productCount}
                          </td>
                          <ItemStatus
                            key={index}
                            i={i}
                            index={index}
                            handleStatusChange={handleStatusChange}
                            openPopupIndex={openPopupIndex}
                            handleTogglePopup={handleTogglePopup}
                          />
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5} className="text-center py-4">No data available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {/* Pagination footer */}
              <div className="flex flex-row justify-between p-2 m-1 border-t-2 border-[#E1E2E9]">
                <div>
                  <div className="flex flex-row gap-3">
                    <Dropdown options={options} onSelect={handleSelect} />
                    <p className="text-[#A6A8B1]">Items per page</p>
                    <p className="text-[#666666]">{ProductResponse?.total}
                    Total Companies                   
                    </p>{" "}
                    <p className="text-[#666666]">
                    Displaying  {limit === "All" ? "All": `${startItemIndex} to ${endItemIndex}`  } items
            </p>
                  </div>
                </div>
                <div>
                  <div className="flex flex-row gap-3">
                  <p className="text-[#666666]">{currentPage} of {limit === "All" ? 1 : `${totalPages}`}  pages</p>
                    <div className="flex flex-row items-center gap-2 text-[#666666]">
                      <span>
                        <FaChevronLeft onClick={prevPage} size={15} />
                      </span>
                      <span>
                        <FaChevronRight onClick={nextPage} size={15} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div> 

         
  );
};

export default InventoryCompany;