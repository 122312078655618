import React from 'react'
import { RiArrowDownSLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Redux/store';
import useCategoryNames from '../../../common/hooks/useCategoryName';
import useCompanyNames from '../../../common/hooks/useCompanyName';
import { ScaleLoader } from 'react-spinners';

export const DetailsBar = ({total,isLoading,onClick}: any) => {
    const categoryBox = useSelector((state: RootState) => state.selection.selectedCategories);
    const companyBox = useSelector((state: RootState) => state.selection.selectedCompanies);
    
    // // Get category names using the custom hook
    const categoryNames = useCategoryNames(categoryBox);
    const companyNames = useCompanyNames(companyBox);

  return (
    <div className="flex font-[400]  text-[12px] md:text-[14] leading-[12.52px] md:leading-[16px] justify-between px-[20px] py-[8px] border-b shadow-lg sticky  z-10   bg-white"   
    >
      <span className="flex w-full items-center gap-9 ">
        <div className="flex  whitespace-nowrap ">
          1-{isLoading ? (
            <div className="w-[2px] h-[2px]">
              <ScaleLoader color="black" height={12} margin={2} radius={20} width={2} />
            </div>
          ) : (
            total
          )}
        </div>
        {categoryBox &&
          Array?.isArray(categoryBox) &&
          categoryBox?.length > 0 && (
            <div className="md:flex hidden items-center gap-1">
              <span className="font-semibold whitespace-nowrap">{" "} Category :{" "}</span>
              <span className="flex gap-1">
                {categoryNames?.map((category, index) => {
                  return (
                    <span
                      key={category?.id}
                      className="px-[7px] py-[4px] bg-[#5097A4] h-[30px] text-white border rounded-[6px] font-[300] text-[11px] leading-[20px] cursor-pointer"
                    >
                      {category?.name}
                    </span>
                  );
                })}
              </span>
            </div>
          )}

        {companyBox &&
          Array?.isArray(companyBox) &&
          companyBox?.length > 0 && (
            <div className="md:flex hidden items-center gap-1">
              <span className="font-semibold whitespace-nowrap">Company :</span>
              <span className="flex gap-1">
                {companyNames?.map((company, index) => {
                 
                  return (
                    <span
                      key={company?.id}
                      className="px-[7px] py-[4px] bg-[#5097A4] h-[30px] text-white border rounded-[6px] font-[300] text-[11px] leading-[20px] cursor-pointer"
                    >
                      {company?.name}
                    </span>
                  )
                })}
              </span>
            </div>
          )}
      </span>
      <div className=" flex sm:hidden px-[5px] py-[3px] gap-[4px] border-l cursor-pointer"
       
        onClick={onClick}
      >
        <span className="font-[500] text-[12px] leading-[14.52px] text-[#5097A4] ">{" "} Filter</span>
        <RiArrowDownSLine />
      </div>
    </div>
  )
}
