import { useState } from "react";

export const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const faqs = [
    {
      question: "What types of building materials do you supply?",
      answer:
        "We supply a wide range of building materials, including iron and steel products, TMT bars, cement, pipes, fabrication materials, and other construction essentials from leading brands like Kamdhenu, Jindal Panther, Tata Tiscon, SAIL, and more.",
    },
    {
      question: "How can I place an order for building materials?",
      answer:
        "You can place an order by visiting our website, calling our sales team, or contacting us via email or WhatsApp. We also accept walk-in orders at our physical location.",
    },
    {
      question: "Do you offer bulk discounts for large orders?",
      answer:
        "Yes, we offer competitive pricing and bulk discounts for large orders. Please contact our sales team to discuss your specific requirements.",
    },
    {
      question: "What payment methods do you accept?",
      answer:
        "We accept various payment methods, including bank transfers, credit/debit cards, UPI payments, and cash on delivery.",
    },
  ];

  const toggleAccordion = (index: number) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="w-full mx-auto mt-8 ">
      {faqs.map((faq, index) => (
        <div
          key={index}
          className="border border-gray-300 rounded-lg overflow-hidden mb-4 shadow-sm w-full"
        >
          <button
            className="flex justify-between items-center w-full py-3 px-5 text-left text-lg font-semibold text-gray-800 bg-gray-50 hover:bg-gray-100 transition duration-200"
            onClick={() => toggleAccordion(index)}
          >
            <span>{faq.question}</span>
            <span
              className={`transform transition-transform duration-200 ${
                activeIndex === index ? "rotate-180" : "rotate-0"
              }`}
            >
              {activeIndex === index ? "−" : "+"}
            </span>
          </button>
          {activeIndex === index && (
            <div className="px-5 py-3 text-gray-700 bg-white">{faq.answer}</div>
          )}
        </div>
      ))}
    </div>
  );
};
