import { useState, useEffect } from "react";
// import lohawala from "../../../../assets/explore/lohawala.png";
// import lohawala2 from "../../../../assets/explore/lohawala2.png";
import Acc from "../../assets/acc.png";
import Ambuja from "../../assets/Ambuja.png";
import bangur from "../../assets/Bangur.png";
import duraguard from "../../assets/duraguard.png";
import jklakshmi from "../../assets/jklakshmi.png";
// import accsmall from "../../../../assets/explore/accsmall.png";
// import ambujasmall from "../../../../assets/explore/ambujasmall.png";
// import bangursmall from "../../../../assets/explore/bangursmall.png";
// import duraguardsmall from "../../../../assets/explore/duraguardsmall.png";
// import jklakshmismall from "../../../../assets/explore/jklakshmismall.png";
import rod from "../../assets/rod.png";
import bar from "../../assets/bar.png";
import { FaArrowRight } from "react-icons/fa";
import tin from "../../assets/bigtin.png";
import { Link } from "react-router-dom";

const Explore = () => {
  return (
    <div className=" hidden md:flex flex-col relative bg-[#5097A4] h-[449px]">
      
      <div className=" overflow-hidden">
        <div className="px-3 py-[3rem] h-full  ">
          <div className="w-[375px] md:w-full h-full  md:h-[349px] lg:h-[449px]  relative ">
            <div className=" justify-between hidden md:flex">
              <div
                className="flex items-center justify-center top-[-1%] bg-white   "
                style={{ marginLeft: "-3%",marginTop: "-2%", transform: "rotate(-24.34deg)" }}
              >
                <div className=" ">
                  <div className="font-[400] font-jockey-one text-[21px] text-[#94A3B8] leading-[32px]">
                    LOHAWALLA.LOHAWALLA.LOHAWALLA.
                  </div>
                </div>
              </div>

              <div
                className="flex items-center justify-center "
                style={{ marginRight: "-3%",marginTop: "-2%", transform: "rotate(-24.77deg)" }}
              >
                <div className="font-[500] text-[24px] font-jockey-one    bg-yellow-300 transform rotate-45">
                  LOHAWALLA.LOHAWALLA.LOHAWALLA
                </div>
              </div>
            </div>

            <div className="flex flex-row justify-between  ">
              <div className="relative pl-1 md:pl-16    h-full ">
                <div className="flex flex-col pl-4 gap-2 lg:gap-[30px] pt-4 lg:pt-10 lg:pb-8">
                  <div className="font-semibold md:font-bold  text-[40px] sm:text-[21px] md:text-4xl leading-[20px] md:leading-10 text-white tracking-tighter whitespace-pre-line flex flex-col">
                    <span>Find all building</span>
                    <span>supplies at your</span>
                    <span>doorstep</span>
                  </div>
                  <div className="font-[400] text-[8px] md:text-[18px] text-white  leading-[11.2px] md:leading-[25px] flex flex-col">
                    <span>Explore the wide range of construction</span>
                    <span>product only at lohawalla</span>
                  </div>
                </div>
                <Link to="/items">
                <div className=" border-2 border-gray-300 hover:border-black rounded-4 w-28 h-8 ml-4 mt-2 lg:rounded-[11px] md:w-[230px] md:h-[54.43px] md:p-[10px] md:gap-[10px] bg-[#FEBD69]" >
                  <div className="flex flex-row justify-center items-center py-1">
                    <button className="font-semibold text-xs mr-1  md:font-[600] md:text-[18px] md:mr-[11px]">
                      Explore
                    </button>
                    <FaArrowRight className="w-3 h-3  md:h-5 md:w-5" />
                  </div>
                </div>
                </Link>
              </div>


              <div
                className="absolute bottom-[76px] z-10 flex flex-row items-center ml-[38%] md:ml-[41%] lg:ml-[28%]  mt-[-48px]  "
                // style={{ marginLeft: "26%" }}
              >
                <img src={duraguard} alt="duraguard" className="relative  w-[5.04rem] sm:w-[6.04rem] md:w-[7rem]  lg:w-full  " />
                <img
                  src={jklakshmi}
                  alt="jklakshmi"
                  className="relative -ml-[52.4px] md:-ml-[92.4px] w-[5.04rem] sm:w-[6.14rem] md:w-[7.4rem]  lg:w-full"
                  // style={{ marginLeft: "-92.4px" }}
                />
                <img
                  src={Ambuja}
                  alt="Ambuja"
                  className="relative z-10 -ml-[45.4px] md:-ml-[58px] w-[4.74rem] sm:w-[6.24rem] md:w-[7.9rem]   -mt-[18px]  lg:w-full"
                  // style={{ marginLeft: "-58px", marginTop: "-39px" }}
                />
                <img
                  src={bangur}
                  alt="bangur"
                  className="relative z-9 -ml-[31.7px] md:-ml-[44.7px] w-[4.84rem] sm:w-[5.34rem] md:w-[7.119rem]   lg:w-full"
                  // style={{ marginLeft: "-41.7px" }}
                />
                <img
                  src={Acc}
                  alt="Acc"
                  className="relative -z-10  -ml-[31.7px] md:-ml-[58px] w-[4.04rem] sm:w-[5.04rem] md:w-[7.0rem]  lg:w-full"
                  // style={{ marginLeft: "-38px" }}
                />
              </div>

              <div className="hidden lg:flex absolute bottom-[48px] z-10 -right-3  flex-row items-end ">
                <img src={tin} alt="tin" className="relative bottom-0  lg:w-[34rem] md:w-[23rem] " />
                <img src={rod} alt="rod" className="absolute bottom-0 right-0" />
                <img
                  src={bar}
                  alt="bar"
                  className="absolute -left-[0px] bottom-0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Explore;
