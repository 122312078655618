import { AllCategoriesResponse, InventoryCategoryProduct, InventoryProduct, changeStatusResponse } from "../../../types/api-types";
import { lhwpath } from "../../apiRoutes";
import { api } from "../rtkAPI";

export const InventoryQuery: any = api.injectEndpoints({
    endpoints: (builder) => ({  
      getComProduct: builder.mutation<InventoryProduct,  { company?: string}>({
        query: ({company}) => {
            return {
                url: `/global/customer/admin/searchAssists/${company}`,
                method: "GET",
                
            };
        },

    }),



    changeCompanyStatus: builder.mutation<any,{id:string,status:string} >({
        query: ({id,status}) => {
            // console.log("fgrds",id,status);
              return {
            url: `${lhwpath}/global/customer/admin/publish/company/${id}`,
            method: "PATCH",
            body: {
             publish: status 
            }
        }

        },
        invalidatesTags:["category"],
    }),


    getCompanyProduct : builder.mutation<InventoryCategoryProduct,  string>({
        query: (bodyData) => {
            return {
           url: `${lhwpath}/global/getCompanyList/publish`,
            method: "POST",
           body:bodyData
        }}
    }),

    getCompanySearches: builder.query<InventoryProduct,  { company: string,search?: string}>({
        query: ({company,search=""}) => {
            const constructUrl = (company: string, search?: string): string => {
                const encodedName = encodeURIComponent(company)
                const params = new URLSearchParams();
                if (search) params.append("search", search);
                const url = params.toString()
                  ? `/global/customer/admin/searchAssists/${encodedName}/?${params.toString()}`
                  : `/global/customer/admin/searchAssists/${encodedName}`;
                // console.log("Constructed URL:", url); // Log the URL
                return url;
              };
                return {
                url:constructUrl(company,search),
                method: "GET",
                
            };
        },

    }),
 

    }),
})

export const {
       useGetCompanySearchesQuery , useGetCompanyProductMutation , useChangeCompanyStatusMutation } = InventoryQuery;