import { useState, useEffect } from "react";

import { SearchResult } from "../../types/types";
import CategoriesCard from "./CategoriesCard";
import NoResult from "./Noresult";
import { useNavigate } from "react-router-dom";
import { SearchBoxProps } from "./types";
import { useDispatch } from "react-redux";
import { clearSubResults, setSubSearch } from "../../Redux/reducer/Filter";



const SearchBox = ({ searchResults, isLoading,close }: SearchBoxProps) => {
  const [showSearchResult, setShowSearchResult] = useState(true);
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch()

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
   window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = (name: string) => {
      navigate("/subItems")
      dispatch(clearSubResults())
      dispatch(setSubSearch(name))
     
      close()
  }


  return (
    <>
      <div className="z-50 bg-white w-full flex flex-col gap-4 border px-4 py-2">
        <div className="w-full flex flex-col gap-4">
          <div className="w-full h-40 overflow-auto">
            <div className="w-full flex flex-col overflow-y-auto">

              {searchResults?.length ? (
                <ul>
                  {searchResults.map((searchResult, index) => (
                    <div key={index} className="hover:bg-gray-100 cursor-pointer" onClick={() => handleClick(searchResult?.name)}>
                      <div className="flex items-center">
                        <div className="flex-1">
                          <span className="font-inter font-normal text-xs leading-5">{searchResult?.name}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </ul>
              ) : isLoading ? (
                <div className="flex items-center justify-center h-full pt-20">
                  <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
                </div>
              ) : (
                <NoResult />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBox;
