import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "./components/Dropdown";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { useChangeStatusMutation } from "../../../Redux/Api/Admin/Inventory";
import {  useAdminCategoriesQuery } from "../../../Redux/Api/Category"
import ItemStatus from "./inventoryStatus";
import { CustomerData, Items } from "../types";
import defaultImage from "../../../assets/item.png"
import { FiSearch } from "react-icons/fi";


const Inventory = () => {
  const [inputValue, setInputValue] = useState<string | null>("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState("15");
  const [search,setSearch] = useState<string >("")
   
  const { data: categoryResponse, refetch } = useAdminCategoriesQuery({
    page: currentPage,
    limit: limit === "All" ? undefined : limit,
    search:search 
  });

  const [changeStatusMutaion] = useChangeStatusMutation();
    const navigate = useNavigate();


  // Function to handle changes in the input field
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    setSearch(value);
  };

  const [openPopupIndex, setOpenPopupIndex] = useState<number | null>(null);

  const handleTogglePopup = (index: number) => {
    setOpenPopupIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const toggleRow = (rowId: number) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };
  const options = ["9", "8", "7","All"];

  const handleSelect = (option: any) => {
    setSelectedOption(option);
    setLimit(option === "All" ? "All" : option);
  };

  const handleStatusChange = async (id: string, status: string, index: number) => {
   
    if (status === "publish") {
      await changeStatusMutaion({ id, status: true });
    } else if (status === "unPublish") {
      await changeStatusMutaion({ id, status: false });
    }
       refetch();
      handleTogglePopup(index);
  };
  
 const prevPage = () => {
    const nextPageNumber = currentPage - 1;
    if (nextPageNumber >= 1) {
      setCurrentPage(nextPageNumber);
    }
  };

  const nextPage = () => {
    const nextPageNumber = currentPage + 1; // Increment currentPage
    setCurrentPage(nextPageNumber); // Update currentPage state
  };

  const handleCategoryName= (name:any, id:any)=>{
    navigate(`/admin/category/view/${name}`, {
      state: { name:name, id:id },
    })
  }

  useEffect(() => {}, [currentPage, limit, inputValue]);

  const customerDataString = localStorage.getItem("customerData") || "{}";
  const customerData: CustomerData = JSON.parse(customerDataString);
  //  console.log(categoryResponse,"category")

    // Calculate total pages
    const totalPages = Math.ceil((categoryResponse?.total || 0) / parseInt(limit));

// Calculate item range for the current page
const startItemIndex = (currentPage - 1) * parseInt(limit) + 1;
const endItemIndex = Math.min(currentPage * parseInt(limit), categoryResponse?.total || 0);



  return (

            <div className="bg-[#FFFFFF] rounded-lg  mx-4 my-4 px-4 py-3">
              <div className="flex flex-row justify-between bg-white">
                <div className="flex justify-between w-full">
                  <div>Inventory Categories</div>
                  <div className="flex flex-row gap-3 ">
                  {/* Search */}
                  <div className="w-full flex flex-row items-center p-1 gap-3 border rounded-lg">
                    <span>
                      <FiSearch size={20} className="text-[#130F26]" />
                    </span>
                    <input
                      type="text"
                      value={search}
                      onChange={handleChange}
                      placeholder="Search"
                      className="outline-none focus:outline-none text-xs"
                    />
                  </div>
                </div>
                </div>
                 </div>

              {/* Table heading */}
              <div className="overflow-x-auto">
                <table className="table-auto min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]"></th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                        Category
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                        No of products
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                        Published products
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                        Category Details
                      </th>
                    
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {categoryResponse?.result?.map((i: Items, index: number) => (
                      <tr key={i?.id}>
                        <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                          <img
                            className="w-[36px] h-[36px] border rounded-[15px]"
                            src={i?.image? i?.image: defaultImage}
                            alt={i?.name}
                          />
                        </td>
                        <td
                          className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079] cursor-pointer"
                          onClick={() =>
                           handleCategoryName(i?.name, i?.id)
                          }
                        >
                          {i?.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                          {i?.itemCount}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                          {i?.publishItemCount}
                        </td>
                        <Link to={`/admin/categoryDetails/${i?.id}`} >
                        <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-blue-500 underline  ">
                           change Details
                        </td>
                        </Link>
                      
                        <ItemStatus
                          key={index}
                          i={i}
                          index={index}
                          handleStatusChange={handleStatusChange}
                          openPopupIndex={openPopupIndex}
                          handleTogglePopup={handleTogglePopup}
                        />
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Pagination footer */}
              <div className="flex flex-row justify-between p-2 m-1 border-t-2 border-[#E1E2E9]">
                {/* Fist div */}
                <div>
                  <div className="flex flex-row gap-3 ">
                    <Dropdown options={options} onSelect={handleSelect} />
                    <p className="text-[#A6A8B1]">Items per page</p>
                    <p className="text-[#666666]">  {categoryResponse?.total} Total Categories </p>
                    <p className="text-[#666666]">
              Displaying  {limit === "All" ? "All": `${startItemIndex} to ${endItemIndex}`  } items
            </p>
                  </div>
                </div>
                {/* Second div */}
                <div>
                  <div className="flex flex-row gap-3">
            
                  <p className="text-[#666666]">{currentPage} of {limit === "All" ? 1 : `${totalPages}`}  pages</p>
                    <div className="flex flex-row items-center  gap-2 text-[#666666]">
                      <span onClick={prevPage} className="cursor-pointer">
                        <FaChevronLeft size={15} />
                      </span>
                      <span onClick={nextPage} className="cursor-pointer">
                        <FaChevronRight size={15} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>



     
  );
};

export default Inventory;
