import { useEffect, useState } from "react";
import icon from "../../../assets/Icon.png";
import { useGetContractorCoinHistoryMutation } from "../../../Redux/Api/Order";

const ContractorCoinHistory = () => {
  const [coins, setCoins] = useState<string>("All");
  const [mutation, { data: orderData }] = useGetContractorCoinHistoryMutation();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const [awardedBonus, setAwardedBonus] = useState<number | null>(null);

  useEffect(() => {
    if (coins) {
      mutation({ condition: coins.toLowerCase() })
        .unwrap()
        .catch((error:any) => {
          console.error("Error fetching contractor coin history:", error);
        });
    }
  }, [coins, mutation]);

  const toggleExpanded = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleCoinApi = (selectedData: string) => {
    setCoins(selectedData);
    mutation({ condition: selectedData.toLowerCase() });
  };

  const handleAwardBonus = (bonus: number) => {
    setAwardedBonus(bonus);
    setTimeout(() => setAwardedBonus(null), 2000);
  };

  return (
    <div>
      <div className="px-4 sm:px-8">
        <div className="pt-10 pb-6 flex border-b">
          {["All", "Credited", "Debited"].map((condition) => (
            <span
              key={condition}
              className={`p-3 font-semibold text-lg cursor-pointer ${
                coins === condition
                  ? "text-blue-600 border-b-2 border-blue-600"
                  : "text-gray-600"
              }`}
              onClick={() => handleCoinApi(condition)}
            >
              {condition}
            </span>
          ))}
        </div>
      </div>

      <section>
        {orderData?.data?.length > 0 && (
          <div>
            {orderData.data.map((item: any, index: number) => {
              const isExpanded = expandedIndex === index;

              return (
                <div
                  key={index}
                  className="relative transition-all duration-300"
                >
                  <div
                    className={`relative p-6 rounded-lg shadow-md transition-colors duration-300 ${
                      isExpanded
                        ? "border-l-4 border-blue-500 bg-blue-50"
                        : "bg-white"
                    } hover:shadow-lg cursor-pointer`}
                    onClick={() => toggleExpanded(index)}
                  >
                    {item.customerName ? (
                      <div className="w-full h-full text-xl divide-gray-200 p-4 shadow-md rounded-lg">
                        <ul className="flex justify-between items-center">
                          <li className="flex items-center p-3">
                            <span className="font-semibold text-lg text-gray-600">
                              Order Number:
                            </span>
                            <span className="text-blue-500 mx-2 font-bold">
                              {item.orderNumber}
                            </span>
                            <span className="font-semibold text-lg text-gray-600 ml-4">
                              Customer Name:
                            </span>
                            <span className="text-blue-600 text-lg font-semibold mx-2">
                              {item.customerName}
                            </span>
                          </li>
                          <li className="flex items-center">
                            <span
                              className={`font-semibold text-lg ${
                                item.type === "add" ? "text-green-600" : "text-red-500"
                              }`}
                            >
                              {item.type === "add" ? `+${item.totalCoins}` : item.totalCoins}
                            </span>
                            <img src={icon} alt="Coins Icon" className="px-2 h-7" />
                          </li>
                        </ul>

                        <div className="flex w-full justify-between p-3">
                          <div className="text-sm flex gap-5">
                            <span className="font-semibold">
                              Date:
                              <span className="text-gray-500 ml-1">
                                {`${item.createdAt?.split(" ")[0]} ${item.createdAt?.split(" ")[1]}`}
                              </span>
                            </span>
                          </div>
                          <div>
                            <span className="text-orange-500 font-bold">
                              {item.currentCoins}
                            </span>
                          </div>
                        </div>
                        {isExpanded && item.itemData?.length > 0 && (
                          <div className="mt-6 space-y-4">
                            {item.itemData.map((subItem: any, subIndex: number) => (
                              <div
                                key={subItem.combinedName}
                                className="flex justify-between items-center p-4 bg-gray-50 rounded-lg shadow-inner hover:shadow-md transition-all"
                              >
                                <div className="text-md font-semibold text-gray-700">
                                  {subIndex + 1}. {subItem.combinedName}
                                </div>
                                <div className="flex items-center space-x-2 text-lg font-semibold text-yellow-500">
                                  <img src={icon} alt="Coins" className="h-6 w-6" />
                                  <span>{subItem.coins}</span>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div
                        className={`w-full h-full p-4 shadow-md rounded-lg transition-transform duration-300 ${
                          awardedBonus === item.coins ? "bg-green-50 scale-105" : "bg-white"
                        }`}
                        onMouseEnter={() => handleAwardBonus(index)}
                      >
                        <div className="flex justify-between items-center">
                          <div className="text-gray-600 flex items-center">
                            <span className="font-semibold text-lg">Bonus Coins</span>
                            <img src={icon} alt="Coins Icon" className="px-2" />
                            <span className="text-2xl font-bold">{item.coins}</span>
                            {item.type === "add" ? (
                              <span className="text-green-600 font-bold px-2">Added</span>
                            ) : (
                              <span className="text-red-500 font-bold px-2">Removed</span>
                            )}
                          </div>
                        </div>

                        <div className="flex w-full justify-between p-3">
                          <div className="text-sm flex gap-5">
                            <span className="font-semibold">
                              Date:
                              <span className="text-gray-500 ml-1">
                                {`${item.createdAt?.split(" ")[0]} ${item.createdAt?.split(" ")[1]}`}
                              </span>
                            </span>
                          </div>
                        </div>
                        {isExpanded && item.bonusData?.length > 0 && (
                          <div className="mt-6 space-y-4">
                            {item.bonusData.map((bonusItem: any, bonusIndex: number) => (
                              <div
                                key={bonusIndex}
                                className="flex justify-between items-center p-4 bg-gray-50 rounded-lg shadow-inner hover:shadow-md transition-all"
                              >
                                <div className="text-md font-semibold text-gray-700">
                                  {bonusIndex + 1}. {bonusItem.bonusDescription}
                                </div>
                                <div className="flex items-center space-x-2 text-lg font-semibold text-yellow-500">
                                  <img src={icon} alt="Coins" className="h-6 w-6" />
                                  <span>{bonusItem.coins}</span>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                        {awardedBonus === index && (
                          <div className={`absolute inset-0  bg-opacity-75 flex items-center justify-center rounded-lg text-center font-semibold ${ item.type === "add"? "bg-green-200":"bg-red-200"} `}>
                            {item.type === "add" ? (
                              <span className="text-green-600 font-bold px-2"> Bonus Awarded!</span>
                            ) : (
                              <span className="text-red-500 font-bold px-2">  Bonus Removed!</span>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </section>
    </div>
  );
};

export default ContractorCoinHistory;
