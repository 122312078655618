import { Link, useNavigate } from "react-router-dom";
import { GoStarFill } from "react-icons/go";
import { usePriceMutation } from "../Redux/Api/priceAPI";
import { useEffect, useState } from "react";
import { useTopSellingQuery } from "../Redux/Api/top";
import TopSellingCard from "./TopSellinCard";

type ImageUrl = string;
type MobileCardProps = {
  name?: string;
  description?: string;
  mrpPrice?: string;
  image?: string | undefined;
  _id?: string;
  status?: boolean;
  productName?: string;
  companyName?: string;
  total?: number;
  unit?: string;
  cname?: string;
  itemCode?: string;
};

interface Selling {
  name?: string;
  id?: string;
  type: string;
  view: number;
}

interface Price {
  id: string;
  finalPrice: number;
  unit: string;
}

const TopSelling = ({
  name,
  description,
  image,
  mrpPrice,
  _id,
  status,
  productName,
  companyName,
  itemCode,
}: MobileCardProps) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  
  const { data: selling } = useTopSellingQuery();
  const [priceMutation] = usePriceMutation();
  const [priceData, setPriceData] = useState<{ [key: string]: Price }>({});

  useEffect(() => {
    if(selling){
      setData(selling);
    }
}, [selling]);

  useEffect(() =>{
     const fetchPrices = async (productId: string[]) => {
      try {
        const priceResponse = await priceMutation ({productId})
        const priceDataObj: {[key:string]: Price} = {};
        priceResponse?.data?.results?.slice(0,4).forEach((result: any) => {
                  priceDataObj[result.productId] = {
                    id: result.productId,
                    finalPrice: result.finalPrice,
                    unit: result.unit,
                  };
                });
                 setPriceData(priceDataObj);
    } catch (error) {
        console.log(error,"error occured")
    }
     }

    if(selling?.length>0){
      const productId = selling.map((selling: Selling) => selling.id)
       fetchPrices(productId)
    }
  },[selling,priceMutation])


  return (
    <div className=" flex flex-wrap justify-center md:justify-between gap-8 mx-auto md:mx-24 my-3 "> 
        {data && data.slice(0, 4).map((item: any) =>  {
              if(item.id === priceData[item.id]?.id)  {
                 return (
                  <TopSellingCard data={item} priceData={priceData[item.id]} key={item.id} />
                )
              }
            return null
          }
         
          )}
      
    </div>
  );
};

export default TopSelling;
