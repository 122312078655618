import { useEffect, useState } from 'react'
import { CiSearch } from "react-icons/ci";
import { CiFilter } from "react-icons/ci";
import Dropdown from '../Inventory/components/Dropdown';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { AllContractors } from './components/AllContactors';
import { usePublishedContractorMutation } from '../../../Redux/Api/Contractor';
import { RiFileExcel2Fill } from "react-icons/ri";
import { useDispatch } from 'react-redux';
import { closeProfile, showProfile } from '../../../Redux/reducer/RequestReducer';
import { useNavigate } from 'react-router-dom';

export const RewardSytem = () => {
     const dispatch = useDispatch()
    const [selectedOption, setSelectedOption] = useState(null);
    const [limit, setLimit] = useState("20"); 
    const [page, setPage] = useState<any>(1);
    const [totalPages, setTotalPages] = useState(1); 
    const [fetchContractors, { data, isLoading }] =
    usePublishedContractorMutation();
      const [visibleComponent, setVisibleComponent] =
      useState<string>("contractors");
      const [comp, setComp] = useState<string | null>(null);
    const handleSelect = (option: any) => {
        setSelectedOption(option);
        setLimit(option);
      };
       const navigate = useNavigate()

      const toggleComponent = (component: string) => {
        setComp(component);
        setVisibleComponent(component);
        dispatch(closeProfile())
      };

      const options = ["9", "8", "7"];


      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetchContractors({ limit, page });
            // Assuming response.data.totalPages exists and is correctly set in the response structure
            setTotalPages(response.data.totalPages);
          } catch (error) {
            console.error("Error fetching contractors:", error);
          }
        };
      
        fetchData();
      }, [page, limit, fetchContractors]);

      const prevPage = () => {
        if (page > 1) {
          setPage(page - 1);
        }
      };
    
      const nextPage = () => {
        if (page < totalPages) {
          setPage(page + 1);
        }
      };



  return (
      <div className='h-full bg-white'>
        {/* <AllContractors /> */}
        <div className='border rounded-[12px]  flex flex-col gap-[24px] py-[20px] px-[20px]'>
        
        {/* First Line */}
         <div className='flex justify-between'>
            <div className='flex gap-[7px]'>
            <div className='flex items-center w-[170px] h-[30px] gap-[10px] p-[8px] border rounded-[4px]'>
                <CiSearch className='w-[16px] h-[16px]' />
                <input 
                type='text'
                className='w-[90%] border-none outline-none font-[400] text-[12px] leading-[14px]'
                  placeholder='Search'
                 />
            </div>

            <div className='flex  w-[65px] h-[30px] border gap-[10px] p-[8px] items-center rounded-[4px] border-[#53545C]'>
             <CiFilter />
              <span className='font-[400] text-[11px] leading-[13px]'>Filter</span>
            </div>
             </div>

             <div className='flex gap-[10px]'>
             <div className='flex items-center px-[24px] py-[3px] border gap-[4px] rounded-[4px] text-blue-500  border-blue-500 bg-[#F5F5F5]'>
             <span className='font-[600] text-[16px] leading-[20px]'>Set Reward</span> 
           </div>

             <div className='flex items-center px-[24px] py-[3px] border gap-[4px] rounded-[4px] text-[#16A34A] border-[#16A34A] bg-[#F5F5F5]'>
             <span className='font-[600] text-[16px] leading-[20px]'> Export  </span> <RiFileExcel2Fill  className='w-[20px] h-[20px]'/>
           </div>

             </div>
           

         </div>

              {/* table */}
         <div className='flex'>
         <table className="table-auto min-w-full divide-y divide-gray-200">
                  <thead className="bg-white">
                    <tr className='border-t'>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]"></th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                        Category
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                      Product Name 
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                       Reward
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                       Status 
                      </th>
                    
                     

                   
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                     {data?.contractors?.map((i:any,index:any) =>(
                          <tr >
                       <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                            {/* <img
                              className="w-[36px] h-[36px] border rounded-[15px]" src="" alt="image"
                            /> */}
                          </td>
                          <td
                            className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#0078FB] underline cursor-pointer"
                          onClick={() => navigate(`/contractors/profile/${i?._id}`)}>
                            {/* {i?.name} */} cement
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                            {i?.phoneNumber} jk lakshmi
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                            50 
                          </td>
                        
                          <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                           active 
                       </td>
                        </tr>
                     ))}
                  
                
                  </tbody>
                </table>
         </div>
   
         {/* Pagination */}
         <div className='flex flex-row justify-between p-2 m-1 border-t-2 '>
               <div>
                  <div className='flex flex-row gap-3'>
                    <Dropdown options={options} onSelect={handleSelect} />
                    <p className='text-[#A6A8B1]'>Items Per Page</p>
                    <p>Categories</p>
                  </div>
                </div> 

                 <div>
                  <div className='flex flex-row gap-3'>
                    <Dropdown options={options} onSelect={handleSelect} />
                    <p className='text-[#A6A8B1]'>of 44 pages</p>
                    <div className='flex flex-row items-center gap-2 text-[#666666]'>
                        <span >
                        <FaChevronLeft size={15} />
                        </span>

                        <span >
                        <FaChevronRight size={15} />
                        </span>
                        </div>
                  </div>
                </div>   
         </div>


        </div>
    </div>
  )
}
