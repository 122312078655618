import React, { useEffect, useState } from "react";
import Dropdown from "../Inventory/components/Dropdown";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { CiFilter, CiSearch } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { closeProfile } from "../../../Redux/reducer/RequestReducer";
import { TbPencilMinus } from "react-icons/tb";
import {
  useAddCoinsMutation,
  useChangeCategoriesMutation,
  useChangeGradeMutation,
  useGetContractorQuery,
  useRemoveCoinsMutation,
} from "../../../Redux/Api/Contractor";
import { useNavigate, useParams } from "react-router-dom";
import { MdArrowDropUp, MdOutlineArrowDropDown } from "react-icons/md";
import { useCategoriesNameQuery } from "../../../Redux/Api/NamesAPI";
import { CategoryFilter } from "./components/CategoryFilter";
import { RootState } from "../../../Redux/store";
import { Categories } from "../types";
import { setSelectedCategories } from "../../../Redux/reducer/Filter";
import { RiArrowDownSFill } from "react-icons/ri";
import { useChangePasswordMutation } from "../../../Redux/Api/Contractor";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContractorOrderHistory from "./ContractorOrderHistory";
import ContractorMessage from "./contractorMessage";
import "./components/Customer.css";


export const Customer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [visibleComponent, setVisibleComponent] =
    useState<string>("contractors");
  const [comp, setComp] = useState<string | null>(null);
  const [showHistory, setShowHistory] = useState<boolean>(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isCatgeoryOpen, setIsCategoryOpen] = useState<boolean>(false);
  const [rewardValue, setRewardValue] = useState<number | null>(null);
  const [operation, setOperation] = useState("");
  const [grade, setGrade] = useState<{ grade: string }>({ grade: "" });
  const [categories, setCategories] = useState<{ category: string[] }>({
    category: [],
  });
  const [limit, setLimit] = useState("6");
  const [showModal, setShowModal] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

  const { data } = useGetContractorQuery(id);
  const [addCoins] = useAddCoinsMutation();
  const [removeCoins] = useRemoveCoinsMutation();
  const [changeCategory] = useChangeCategoriesMutation();
  const [changePassword, { data: changedPassword }] =
    useChangePasswordMutation();
  const [password, setPassword] = useState("");

  const handleOpenModal = () => {
    if (rewardValue) {
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirm = (id: string) => {
    if (operation === "add") {
      addCoins({ id, body: { coins: rewardValue } });
      setOperation("");
      forceRenderHistory();
    } else if (operation === "subtract") {
      removeCoins({ id, body: { coins: rewardValue } });
      setOperation("");
      forceRenderHistory();
    }
    setShowModal(false);
  };
  const [changeGrade] = useChangeGradeMutation();

  const { data: categoriesResponse } = useCategoriesNameQuery(
    {},
    { refetchOnMountOrArgChange: true, keepPreviousData: true }
  );
  const options = ["a", "b", "c"];
  const [categoryMap, setCategoryMap] = useState<
    { id: string; name: string }[]
  >([]);

  const allCategories = data?.message?.categoryProduct;

  useEffect(() => {
    if (allCategories) {
      dispatch(setSelectedCategories(allCategories));
    }
  }, [allCategories]);

  const handleReward = (e: any) => {
    setRewardValue(e.target.value);
  };

  const toggleComponent = (component: string) => {
    setComp(component);
    setVisibleComponent(component);
    dispatch(closeProfile());
  };

  const handleSelect = (option: string) => {
    setSelectedOption(option);
    setGrade({ grade: option });
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    if (selectedOption && grade) {
      changeGrade({ id, body: grade });
    }
  }, [selectedOption, grade]);

  const selectedCategories = useSelector(
    (state: RootState) => state.selection.selectedCategories
  );

  useEffect(() => {
    if (categoriesResponse && selectedCategories.length > 0) {
      const selectedCategoryDetails = categoriesResponse
        .filter((category: Categories) =>
          selectedCategories.includes(category.id)
        )
        .map((category: Categories) => category.name);
      setCategoryMap(selectedCategoryDetails);
    }

    if (selectedCategories.length === 0) {
      setCategoryMap(selectedCategories);
    }
  }, [categoriesResponse, selectedCategories]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const CategoryToChange = () => {
    setCategories({ category: selectedCategories });
    setIsCategoryOpen(false);
  };

  useEffect(() => {
    if (categories.category.length > 0) {
      changeCategory({ id, body: categories });
    }
  }, [categories, changeCategory, id]);

  const togglePasswordModal = () => {
    setIsPasswordModalOpen(!isPasswordModalOpen);
  };

  const toggleChangePassword = (id: string) => {
    setIsPasswordModalOpen(!isPasswordModalOpen);
    changePassword({
      body: {
        newPassword: password,
      },
      id,
    });
  };

  useEffect(() => {
    if (changedPassword?.message) {
      toast.success("Password changed successfully!");
    }
  }, [changedPassword]);

  const [historyKey, setHistoryKey] = useState(0);

  const forceRenderHistory = () => {
    setHistoryKey((prevKey) => prevKey + 1);
  };
  return (
    <div className="h-full flex flex-col gap-[10px] bg-slate-100">
      <div className="px-[10px] py-[10px] bg-white flex justify-between border rounded-[12px]">
        <div className="flex flex-col gap-[8px] ml-4">
          <div className="font-[400] text-[12px] leading-[14px]">
            Contarctor Type
          </div>
          <div className="font-[600] text-[16px] leading-[19px]">
            {data?.message?.name}
          </div>
          <div className="font-[600] text-[16px] leading-[19px] flex text-[#8B8D97]">
            Grade:{" "}
            <span className="text-amber-500 ml-[6px]">
              {data?.message?.contractorGrade}{" "}
            </span>
            <TbPencilMinus
              className="ml-[8px] cursor-pointer"
              onClick={toggleDropdown}
            />
            {isDropdownOpen ? <MdArrowDropUp /> : <MdOutlineArrowDropDown />}
            {isDropdownOpen && (
              <div className="absolute mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                {options.map((option) => (
                  <div
                    key={option}
                    onClick={() => handleSelect(option)}
                    className="cursor-pointer px-10 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="font-[600] text-[16px] leading-[19px] items-center flex text-[#8B8D97] gap-6">
            <div>
              <span>Total Balance Reward:</span>{" "}
              <span className="text-green-600">
                {data?.message?.totalReward}{" "}
              </span>
            </div>
            <div className="flex gap-6">
              <select
                id="operation"
                value={operation}
                onChange={(e) => setOperation(e.target.value)}
                className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              >
                <option>Select</option>
                <option value="add">Add</option>
                <option value="subtract">Subtract</option>
              </select>

              {operation === "add" ? (
                <div className="flex gap-4">
                  <input
                    className="border-2 p-2  hover:border-red-400 text-amber-500 rounded-[10px]"
                    placeholder="Enter the Rewards"
                    onChange={handleReward}
                  />
                  <button
                    className="py-2 px-5 text-white bg-blue-900 rounded-[10px]"
                    onClick={handleOpenModal}
                  >
                    Add
                  </button>
                </div>
              ) : operation === "subtract" ? (
                <div className="flex gap-4">
                  <input
                    className="border-2 p-2  hover:border-red-400 text-amber-500 rounded-[10px]"
                    placeholder="Enter the Rewards"
                    onChange={handleReward}
                  />
                  <button
                    className="py-2 px-5 text-white bg-blue-900 rounded-[10px]"
                    onClick={handleOpenModal}
                  >
                    Subtract
                  </button>
                </div>
              ) : (
                ""
              )}

              {showModal && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
                  <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                    <div className="mt-3 text-center">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Are you sure?
                      </h3>
                      <div className="mt-2 px-7 py-3">
                        <p className="text-sm text-gray-500">
                          Do you really want to proceed with this action?
                        </p>
                      </div>
                      <div className="items-center px-4 py-3">
                        <button
                          className="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-600"
                          onClick={() => handleConfirm(data?.message?._id)}
                        >
                          Yes
                        </button>
                        <button
                          className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-600 mt-2"
                          onClick={handleCloseModal}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {isPasswordModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center">
                  <div className="absolute inset-0 bg-black opacity-50"></div>
                  <div className="relative bg-gradient-to-r from-blue-500 to-purple-600 p-8 rounded-lg shadow-lg">
                    <h2 className="text-2xl font-bold text-white mb-4">
                      Change Password
                    </h2>
                    <input
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      placeholder="Confirm New Password"
                      className="w-full p-2 mb-4 rounded"
                    />
                    <div className="flex justify-end">
                      <button
                        className="bg-white text-blue-600 px-4 py-2 rounded mr-2"
                        onClick={togglePasswordModal}
                      >
                        Cancel
                      </button>
                      <button
                        className="bg-blue-700 text-white px-4 py-2 rounded"
                        onClick={() => {
                          // Handle password change logic here
                          toggleChangePassword(data?.message?._id);
                        }}
                      >
                        Change Password
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col-reverse font-[400] text-[16px] leading-[19px]">
          <div className="flex py-[4px] px-[12px] border rounded-[4px] bg-[#FFF8F0]">
            <span className="text-[#8B8D97]">Total Pending Reward:</span>{" "}
            <span className="text-amber-500">0</span>
          </div>
        </div>
      </div>

      <div className="w-full bg-white p-[10px] flex justify-between items-center gap-[6px] border rounded-[12px]">
        <div className="flex items-center gap-[6px]">
          <div className="text-[#8B8D97] font-[700] text-[16px] leading-[19px]">
            Category Assign:
          </div>

          <div className="w-[500px] overflow-hidden">
            <div className="flex overflow-x-auto scrollbar-custom">

              {categoryMap &&
                categoryMap.map((i: any, index: number) => (
                  <div
                    key={index}
                    className="font-[700] w-full text-[16px] leading-[28px] px-2 flex nowrap whitespace-nowrap border-r-2"
                  >
                    {i}
                  </div>
                ))}
            </div>
          </div>
        </div>

        <TbPencilMinus
          className="mr-[20px]"
          onClick={() => setIsCategoryOpen(true)}
        />
        {isCatgeoryOpen && (
          <div className="absolute right-0 top-[18rem] border rounded-[4px] bg-white w-[360px] h-[300px]">
            {/* Wrap CategoryFilter with a relative container */}
            <div className="relative w-full h-full ">
              {/* Custom buttons positioned absolutely */}
              <div className="absolute bottom-0 left-0 right-0 flex  items-center justify-center gap-2 p-2  bg-white z-10">
                <button
                  className="px-[12px] py-[8px] border-[#0078FB] text-[#0078FB] border rounded-[4px] w-full justify-center items-center flex cursor-pointer"
                  onClick={() => setIsCategoryOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="px-[12px] py-[8px] bg-[#0078FB] text-[#FFF] border rounded-[4px] w-full justify-center items-center flex cursor-pointer"
                  onClick={() => CategoryToChange()}
                >
                  Add
                </button>
              </div>
              <CategoryFilter />
            </div>
          </div>
        )}
      </div>

      <div className="h-full bg-white">
        <div className="border rounded-[12px] px-[12px] flex flex-col gap-[24px] py-[12px]">
          <div className="flex justify-between">
            {/* line containing search filter and action  */}
            <div className="flex gap-[7px]">
              <div className="flex items-center border rounded-[4px] px-[8px]">
                <CiSearch className="w-[16px] h-[16px]" />
                <input
                  type="text"
                  className="flex-1 border-none outline-none ml-[4px]"
                />
              </div>

              <div className="flex items-center border gap-[8px] p-[8px] rounded-[4px]">
                <CiFilter />
                <span className="font-[400] text-[16px] leading-[19px]">
                  Filter
                </span>
              </div>

              <div className="flex items-center border gap-[8px] p-[8px] rounded-[4px]">
                <span className="font-[400] text-[16px] leading-[19px]">
                  Action
                </span>
                <RiArrowDownSFill className="w-[16px] h-[16px]" />
              </div>
            </div>
          </div>

          {/* table */}
          <div className="flex">
            <table className="table-auto min-w-full divide-y divide-gray-200">
              <thead className="bg-white">
                <tr className="border-t">
                  <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]"></th>
                  <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                    Contact
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                    Contractor Type
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                    City
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                    Gst
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                    Grade
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                    Password
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079] cursor-pointer">
                    {/* {data?.message?.name}  */}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                    {data?.message?.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                    {data?.message?.phoneNumber}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                    {/* {data?.message?.phoneNumber}  */}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                    {data?.message?.city}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                    {data?.message?.gst}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                    {data?.message?.contractorGrade}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                    <span
                      className="hover:text-red-500 hover:text-[14px]  font-semibold cursor-pointer"
                      onClick={togglePasswordModal}
                    >
                      Change Password
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="flex flex-row justify-between p-2 m-1 border-t-2 ">
            <div>
              <div className="flex flex-row gap-3">
                <Dropdown options={options} onSelect={handleSelect} />
                <p className="text-[#A6A8B1]">Items Per Page</p>
                <p>Categories</p>
              </div>
            </div>

            <div>
              <div className="flex flex-row gap-3">
                <Dropdown options={options} onSelect={handleSelect} />
                <p className="text-[#A6A8B1]">of 44 pages</p>
                <div className="flex flex-row items-center gap-2 text-[#666666]">
                  <span>
                    <FaChevronLeft size={15} />
                  </span>

                  <span>
                    <FaChevronRight size={15} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="flex px-5 py-2 bg-white gap-14">
        <span
          className={`p-2 font-bold cursor-pointer rounded-[5px] ${
            showHistory ? "bg-gray-700 text-white"  : "bg-gray-300 text-gray-800"
          }`}
          onClick={() => setShowHistory(true)}
        >
          History
        </span>

        <span
          className={`p-2 font-bold cursor-pointer rounded-[5px] ${
            showHistory ? "bg-gray-300 text-gray-800": "bg-gray-700 text-white" 
          }`}
          onClick={() => setShowHistory(false)}
        >
          Message
        </span>
      </div>

      <div className="h-full">
        {showHistory ? <ContractorOrderHistory /> : <ContractorMessage />}
      
      </div>
    </div>
  );
};
