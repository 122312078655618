import { FaStar } from "react-icons/fa6";
import { FaStarHalfStroke } from "react-icons/fa6";
import { IoIosArrowDown, IoIosCheckmarkCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import icon from "../../../assets/Icon.png";
import whatsapp from "../../../assets/whatsappicon.svg"
import { IoWarningOutline } from "react-icons/io5";
import { subSearches } from "../../../types/types";
const ItemCard: React.FC<{ data: subSearches}> = ({
  data,
}) => {
  const id =  data?.id;
  const navigate = useNavigate();

  const handleWhatsappClick = () => {
    const phoneNumber = '9639921932';
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };


  return (
    <div className=" flex w-full justify-between ">
      <div className="flex gap-[2rem] py-[15px] border-t border-b w-full ">
        <div
          onClick={() => navigate(`/singleproduct/${id}`, { state: { id } })}
          className="cursor-pointer w-[170px] h-[170px] md:w-[197px] md:h-[197px] overflow-hidden"
        >
          <img
            src={data?.images}
            alt="item"
            className="w-[170px] md:w-[197px] md:h-[190px] object-cover "
          />
        </div>

        <div className="flex flex-col gap-[8px] ">
          <div
            className="font-[500] text-[12px] sm:text-[18px] leading-[21px] cursor-pointer"
            onClick={() => navigate(`/singleproduct/${id}`, { state: { id } })}
          >
            {data?.name ? data.name : data?.productName }
          </div>

          <div className="flex flex-col gap-[8px]">
            <div className="font-[500] text-[14px] leading-[17px] flex items-center gap-[9px] ">

              {data?.finalPrice ===null
                ? "Price is not available"
                : `₹${data?.finalPrice?.toFixed(2)}/${data?.unit}`}

            </div>

            {data.status ? (
              <span>
                <button className="border-2 flex gap-2 px-2 border-green-300 p-1 rounded-full bg-green-100 text-[10px] sm:text-[14px] font-semibold text-black">
                  <span>
                    <IoIosCheckmarkCircle className="text-[14px] sm:text-[20px] text-green-500" />
                  </span>
                  Active
                </button>
              </span>
            ) : (
              <span>
                <button className="border-2 flex gap-1 px-2 border-red-200 p-1 rounded-full bg-red-100 text-[10px] sm:text-[14px] font-semibold text-black">
                  <span>
                    <IoWarningOutline className="text-[12px] sm:text-[18px] text-red-500" />
                  </span>
                  InActive
                </button>
              </span>
            )}

            <div className="font-400 text-[10px] sm:text-[14px] leading-[22px] text-blue-400 flex items-center gap-2">
              <span> (For Best Rate , Please Contact Us)</span>
              <img
                src={whatsapp}
                className="w-[22px] h-[22px] cursor-pointer"
                onClick={handleWhatsappClick}
                alt="whatsapp"
              />
            </div>
          </div>

          {data?.rewards ? (
            <div className="flex items-center">
              You will earn {<img className="mx-2" src={icon} alt="icon" />}{" "}
              <span className="text-[20px] mr-2">{data?.rewards}</span> super coins
            </div>
          ) : null}

          <div
            className="text-[#565959] font-[400] text-[10px] sm:text-[14px] leading-[12px] sm:leading-[16px]"
            onClick={() => navigate(`/singleproduct/${id}`, { state: { id } })}
          >
            {/* {data?.description ? data?.description : data?.companyName} */}
          </div>
          <div className="flex text-center text-[#007185] items-center text-[10px] ">
            <div className="flex pr-[12px] font-[400] text-[10px] sm:text-[14px] leading-[16px] text-[#007185]">
              <FaStar className="text-yellow-600" />{" "}
              <FaStar className="text-yellow-600" />{" "}
              <FaStar className="text-yellow-600" />{" "}
              <FaStar className="text-yellow-600" />{" "}
              <FaStarHalfStroke className="text-yellow-600" />
            </div>
            <IoIosArrowDown className="text-black" />
            <span className="text-[10px] sm:text-[14px]">1,084</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemCard;