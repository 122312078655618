import  { useEffect } from "react";
import { useGetAdminSingleContractorMessageMutation } from "../../../Redux/Api/Order";
import { useNavigate, useParams } from "react-router-dom";

const ContractorMessage = () => {
  const { id } = useParams<{ id: string }>();

  const [mutation, { data: messageData }] =
    useGetAdminSingleContractorMessageMutation();

  useEffect(() => {
    mutation(id);
  }, [mutation, id]);


  return (
    <section className="h-[90vh]">
      {messageData?.data?.length > 0 && (
        <div>
          {messageData?.data?.map((item: any) => {
            return (
              <div className=" my-5 m-auto w-[95%]">
                <div className="bg-white w-full h-full text-xl  divide-gray-200">
                  <ul className=" ">
                    <li className=" items-center flex px-3">
                      <span className="font-semibold text-[16px] leading-[19px] flex text-[#8B8D97]">
                        Customer Name:
                      </span>
                      <span className="text-blue-500  mx-2  font-semibold  cursor-pointer">
                        {item?.name}
                      </span>
                    </li>
                    <li className=" items-center flex px-3">
                      <span className="font-semibold text-[16px] leading-[19px] flex text-[#8B8D97]">
                        Message :
                      </span>
                      <span className="text-blue-500  mx-2  font-semibold  cursor-pointer">
                        {item?.message}
                      </span>
                    </li>
                    <li className=" items-center flex px-3">
                      <span className="font-semibold text-[16px] leading-[19px] flex text-[#8B8D97]">
                        Customer Arrival Date & Time
                      </span>
                      <span className="text-blue-500  mx-2  font-semibold  cursor-pointer">
                        {`${item?.date?.split("T")[0]} ${
                          item?.date?.split("T")[1]?.split(":")[0]
                        }:${item?.date?.split("T")[1]?.split(":")[1]}`}
                      </span>
                    </li>
                  </ul>

                  <div className="text-[12px] font-bold text-[#8B8D97] p-3">
                        {`${item?.createdAt?.split(" ")[0]} ${
                          item?.createdAt?.split(" ")[1]
                        }`}
                     
                   
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </section>
  );
};

export default ContractorMessage;
