import { useEffect } from "react";
import Imagesection from "./Components/Imagesection";
import ItemData from "./Components/ItemData";
import Description from "./Components/Description";
import { useNavigate, useParams } from "react-router-dom";
import Explore from "../Components/Explore";
import TopSelling from "../../common/TopSelling";
import { useSingleProductMutation } from "../../Redux/Api/priceAPI";
import { FaArrowLeft } from "react-icons/fa";

import { lhwpath } from "../../Redux/apiRoutes";
import usePageMeta from "../../common/hooks/usePageMeta";

const SingleProduct = () => {
  const params = useParams();
  const productId = params.id;
  const navigate = useNavigate();
  const [singleProductMutation, { data: singleProduct, isError, error }] =
    useSingleProductMutation();

  useEffect(() => {
    if (productId) {
      singleProductMutation(productId);
    }
  }, [productId]);

  const handleWhatsappClick = () => {
    const phoneNumber = "9639921392";
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    document.title = "SingleProduct Page - Chawla Ispat";
  }, []);

  console.log(singleProduct, "single product")

  const title = `${singleProduct?.productName || ""} Product Overview | Chawla Ispat | Buy Steel Products Building Materials`;
  const description = "Explore the details of this single product on Chawla Ispat.";
  const canonicalUrl = lhwpath;

  usePageMeta(title, description, canonicalUrl);

  return (
    <div className="bg-white w-11/12 m-auto">
      {/* {isError && (
        <div className="error-message">
          <p>Error fetching product: {error?.message || "An unexpected error occurred"}</p>
        </div>
      )} */}
      <div className="w-fit p-1 bg-gray-300 sm: mt-3">
        <FaArrowLeft
          className="w-5 h-5"
          onClick={() =>
            navigate("/items", { state: { fromSingleProduct: true } })
          }
        />
      </div>

      <div className=" sm:mt-[1.6rem]  ">
        {/* <RelatedItem categoryName={singleProduct?.categoryName} /> */}

        <div className=" flex md:gap-5 flex-col md:flex-row lg:gap-10 mt-[1rem] sm:mt-[4.2rem] md:mt-[1rem]">
          <div className="text-2xl text-gray-500 my-3  font-medium sm:hidden">
            {singleProduct?.productName}
          </div>

          <div className="flex sm:gap-2 md:block h-full  justify-between">
            <Imagesection image={singleProduct?.productImage} />
            <div className="flex-col sm:flex  justify-between h-[22.5rem]  md:h-[30.5rem]  hidden gap-3  md:hidden">
              <div className="space-y-4">
                <div className="text-2xl font-medium ">
                  {singleProduct?.productName}
                </div>
                <div className="font-semibold text-xl">
                  {singleProduct?.finalPrice !== undefined
                    ? `${singleProduct?.finalPrice?.toFixed(2)} / ${singleProduct.priceStructureUnit
                    }`
                    : "Price information not available"}
                </div>
                <div className="font-400 text-[10px] font-semibold sm:text-[1rem] leading-[22px] text-blue-400">
                  (Prices are Negotiable)
                </div>
              </div>

              <div className="w-full space-y-10 ">
                <button
                  className="bg-[#FEBD69] text-black font-bold  py-2 md:py-3 w-full whitespace-nowrap rounded
          hover:bg-white hover:text-[#1E293B] border-2 border-[#FEBD69] hover:border-[#1E293B] hover:transition hover:duration-500"
                >
                  Get Best Price
                </button>

                <div
                  className="bg-green-500  text-center text-white font-bold py-3  whitespace-nowrap rounded px-[10px] md:w-full
 hover:bg-white hover:text-[#1E293B] border-2 border-green-500 hover:border-[#1E293B] hover:transition hover:duration-500"
                >
                  <a
                    href="https://api.whatsapp.com/send?phone=YOUR_PHONE_NUMBER&text=YOUR_MESSAGE"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Connect Through WhatsApp
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="  lg:w-[60%] h-full">
            <ItemData singleProduct={singleProduct} />
          </div>
        </div>
      </div>
      <div>
        <Description singleProduct={singleProduct} />
      </div>

      <Explore />

      <div className="flex flex-col gap-10 mt-12 mb-12 ">
        <div className="w-11/12 m-auto font-[600] sm:text-[35px] text-[25px] leading-[32px]">
          Best selling Items
        </div>

        <div>
          <TopSelling />
        </div>
      </div>
    </div>
  );
};

export default SingleProduct;
