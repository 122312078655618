import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Reward {
  id: string | null;
  name: string | null;
}

interface CategoryItem {
  id: string | null;
}

 interface Contractor {
  data: any
  //  total: number | null;
  //  approved: number | null;
  //  pending: number | null;
}

interface Admin {
  currentReward: Reward;
  currentCategoryItem: CategoryItem;
  Contractor: Contractor
}

const initialState: Admin = {
   currentReward: {id: null, name: null},
   currentCategoryItem: {id:null},
   Contractor: {data:null}

};

const AdminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    RewardData(state, action: PayloadAction<Reward>) {
      state.currentReward.id = action.payload.id;
      state.currentReward.name = action.payload.name;
    },
    clearRewardData(state) {
      state.currentReward.id = null;
      state.currentReward.name = null;
    },
    setCategoryItemData(state, action: PayloadAction<CategoryItem>) {
      state.currentCategoryItem = action.payload;
    },
    clearCategoryItemData(state) {
      state.currentCategoryItem = { id: null };
    },
    setContractorData(state,action: PayloadAction<any>){
     state.Contractor.data = action.payload
    }
  },
});

export const { RewardData, clearRewardData,setCategoryItemData, setContractorData } = AdminSlice.actions;
export default AdminSlice.reducer;
