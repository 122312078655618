import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { usePostMessageMutation } from "../../../Redux/Api/Contractor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm, Controller } from "react-hook-form";

interface FormData {
  name: string;
  number: string | number | null;
  time: string;
  date: string;
  message: string;
}

export const CustomerEntry = () => {
  const today = new Date();
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [showCalendar, setShowCalendar] = useState(false);
  const datePickerRef = useRef<HTMLDivElement | null>(null);
  const formatDate = (date: Date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const [mutation] = usePostMessageMutation();

  // Initialize react-hook-form
  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: "",
      number: "",
      time: "12:00 AM",
      date: formatDate(today),
      message: "",
    },
  });

  const formData = watch();

  // Toggle calendar visibility
  const toggleCalendar = () => setShowCalendar(true);

  // Handle date change
  const handleDateChange = (date: Date | null) => {
    if (date && date <= new Date()) {
      setStartDate(date);
      setValue("date", formatDate(date)); // Update form value
    }
    setShowCalendar(false);
  };

  // Handle click outside to close calendar
  const handleClickOutside = (event: MouseEvent) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target as Node)
    ) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleTimeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value, id } = event.target;
    const [hours, minutes, period] = formData.time.split(/[: ]/);
    let newTime: string;

    switch (id) {
      case "hours":
        newTime = `${value}:${minutes} ${period}`;
        break;
      case "minutes":
        newTime = `${hours}:${value} ${period}`;
        break;
      case "period":
        newTime = `${hours}:${minutes} ${value}`;
        break;
      default:
        newTime = formData.time;
    }

    setValue("time", newTime); // Update form value
  };

  const handleForm = (data: FormData) => {
    mutation(data).then(() =>{
      // Reset the form
      reset({
        name: "",
        number: "",
        time: "12:00 AM",
        date: formatDate(today),
        message: "",
      });
      toast.success("Entry Registered!")
    })
  };

  return (
    <div className="px-[12px] md:px-[40px] pt-[40px] pb-[60px] w-full">
      <div className="px-[12px] md:px-[44px] pb-[36px] pt-[12px] md:py-[36px] flex flex-col gap-[36px] border rounded-[12px]">
        <div className="flex flex-col gap-[24px] md:gap-[36px] w-full">
          {/* Customer */}
          <div className="flex flex-col md:flex-row gap-[24px] md:gap-[10px] w-full">
            <div className="flex flex-col gap-[12px] w-full">
              <span className="font-[600] text-[16px] leading-[19px]">
                Customer Name
              </span>
              <Controller
                control={control}
                name="name"
                rules={{
                   required: "Name is required",
                   minLength: {
                    value: 2,
                    message: "Name must be at least 2 characters"
                  },
                    maxLength: {
                       value: 50,
                       message: "Name must be 50 characters or less"
                    },
                    pattern: {
                      value: /^[A-Za-z\s]+$/i,
                      message: "Name can only contain letters and spaces",
                    }
                  }}
                render={({ field }) => (
                  <input
                    type="text"
                    placeholder="Enter Customer Name..."
                    className="w-full border px-[14px] py-[18px] rounded-md"
                    {...field}
                  />
                )}
              />
              {errors.name && (
                <p className="text-red-500">{errors.name.message}</p>
              )}
            </div>

            <div className="flex flex-col gap-[12px] w-full">
              <span className="font-[600] text-[16px] leading-[19px]">
                Customer Contact Number
              </span>
              <Controller
                control={control}
                name="number"
                rules={{
                   required: "Contact Number is required" ,
                   pattern: {
                    value: /^\d{10}$/ ,
                    message: "Contact Number must be exactly of 10 Numbers"
                 }
                  }}
                render={({ field }) => (
                  <input
                    type="text"
                    placeholder="Enter Customer Contact Number..."
                    className="w-full border px-[14px] py-[18px] rounded-md"
                    value={field.value ?? ""}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    ref={field.ref}
                  />
                )}
              />

              {errors.number && (
                <p className="text-red-500">{errors.number.message}</p>
              )}
            </div>
          </div>

          {/* Arrival */}
          <div className="flex flex-col md:flex-row gap-[24px] md:gap-[10px] w-full">
            <div className="flex flex-col gap-[12px] w-full">
              <span className="font-[600] text-[16px] leading-[19px]">
                Arrival Time
              </span>
              <div className="flex gap-[10px] w-full">
                <select
                  id="hours"
                  value={formData.time.split(":")[0]}
                  onChange={handleTimeChange}
                  className="w-full border px-[14px] py-[18px] rounded-md"
                >
                  {Array.from({ length: 12 }, (_, i) => (
                    <option key={i} value={i + 1}>
                      {i + 1 < 10 ? `0${i + 1}` : i + 1}
                    </option>
                  ))}
                </select>
                <select
                  id="minutes"
                  value={formData.time.split(":")[1].split(" ")[0]}
                  onChange={handleTimeChange}
                  className="w-full border px-[14px] py-[18px] rounded-md"
                >
                  {Array.from({ length: 60 }, (_, i) => (
                    <option key={i} value={i}>
                      {i < 10 ? `0${i}` : i}
                    </option>
                  ))}
                </select>
                <select
                  id="period"
                  value={formData.time.split(" ")[1]}
                  onChange={handleTimeChange}
                  className="w-full border px-[14px] py-[18px] rounded-md"
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>
            </div>

            <div className="flex flex-col gap-[12px] w-full">
              <span className="font-[600] text-[16px] leading-[19px]">
                Arrival Date
              </span>
              <div
                id="date"
                className="relative flex items-center border w-full cursor-pointer"
                onClick={toggleCalendar}
                ref={datePickerRef}
              >
                <DatePicker
                  selected={startDate}
                  onChange={handleDateChange}
                  className="w-full px-[14px] py-[18px] pr-[40px] border-none outline-none"
                  dateFormat="MMMM d, yyyy"
                  open={showCalendar}
                  showMonthDropdown
                  showYearDropdown
                  minDate={new Date("2000-01-01")}
                  maxDate={new Date()}
                />
                <FaCalendarAlt className="absolute right-[10px] top-[50%] transform -translate-y-[50%] text-gray-500" />
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-[10px] w-full">
            <span className="font-[600] text-[16px] leading-[19px]">
              Arrival Description
            </span>
            <div className="w-full h-[137px] border rounded-md">
              <Controller
                control={control}
                name="message"
                rules={{ required: "Message is required" }}
                render={({ field }) => (
                  <textarea
                    id="message"
                    className="w-full h-full border rounded-md px-3 py-1 font-[400]"
                    rows={4}
                    placeholder="Write Your Message...."
                    {...field}
                  />
                )}
              />
              {errors.message && (
                <p className="text-red-500">{errors.message.message}</p>
              )}
            </div>
          </div>
        </div>


       
        <div className="flex md:flex-row-reverse">
          <button 
          className="md:px-[40px] cursor-pointer py-[16px] border rounded-[8px] flex justify-center items-center w-full md:w-[136px] bg-[#5097A4] text-white"
          onClick={handleSubmit(handleForm)}
          >
            Save
          </button>
        </div>


      </div>
    </div>
  );
};
