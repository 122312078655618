import kamdhenu from "../../assets/assets/kamdhenucer-min.png";
import indostar from "../../assets/assets/indostarcer-min.png";
import ambuja from "../../assets/assets/ambujacer-min.png";
import everest from "../../assets/assets/everestcer-min.png";
import panther from "../../assets/assets/panthercer-min.png";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";

export const Certificates = () => {
   const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
   };

   const certificates = [
      { src: ambuja, alt: "Ambuja Certificate" },
      { src: everest, alt: "Everest Certificate" },
      { src: panther, alt: "Panther Certificate" },
      { src: indostar, alt: "Indostar Certificate" },
      { src: kamdhenu, alt: "Kamdhenu Certificate" },
   ];

   return (
      <div className='flex flex-col gap-[36px]'>
         <h1 className='font-[700] text-[36px] md:text-[48px] leading-[58px]'>
            Our Certifications
         </h1>

         <div className='hidden sm:flex flex-wrap justify-between gap-[21px]'>
            {certificates?.map((cert, index) => (
               <div key={index} className="w-[346px] h-[205px] md:w-[396px] md:h-[205px]">
                  <img src={cert?.src} alt={cert?.alt} className='w-full h-full' loading="lazy" />
               </div>
            ))}
         </div>

         <div className='flex flex-col items-center sm:hidden'>
            <div className='w-[330px] h-[330px] relative '>
               <Slider {...settings}>
                  {certificates?.map((cert, index) => (
                     <div key={index} className="w-[330px] h-[230px] overflow-hidden">
                        <img 
                           src={cert?.src} 
                           alt={cert?.alt} 
                           className='w-full h-full' 
                           loading="lazy"
                        />
                     </div>
                  ))}
               </Slider>
            </div>
         </div>
      </div>
   );
}

const NextArrow = (props: any) => {
   const { onClick } = props;
   return (
      <div
         className="absolute top-2/3 transform -translate-y-1/2 right-8 w-[120px] h-[40px] bg-white flex justify-center items-center z-10 shadow-lg border rounded-md"
         onClick={onClick}
      >
         <GoArrowRight className='w-[32px] h-[32px] text-[#5097a4]' />
      </div>
   );
};

const PrevArrow = (props: any) => {
   const { onClick } = props;
   return (
      <div
         className="absolute top-2/3 transform -translate-y-1/2 left-8 w-[120px] h-[40px] bg-white flex justify-center items-center z-10 shadow-lg border rounded-md"
         onClick={onClick}
      >
         <GoArrowLeft className='w-[32px] h-[32px] text-[#5097a4]' />
      </div>
   );
};
