import { useEffect, useState } from "react";
import { useGetContractorCategoryQuery } from "../Redux/Api/Contractor";
import CategoryRewardCard from "../screens/Profile/Components/CategoryRewardCard";
import { CombinedState } from "../types/api-types";
import { useSearchFiltersMutation } from "../Redux/Api/ProductAPI";
import { RingLoader } from "react-spinners";
import usePageMeta from "./hooks/usePageMeta";
import { chawlaispat } from "../Redux/apiRoutes";

export const AssignedRewardss = () => {
  const { data: categoryData, isLoading: isCategoryLoading } =
    useGetContractorCategoryQuery(undefined, {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    });
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(true);
  const [
    searchFilters,
    { data: SearchProductsResponse, isLoading: isProductLoading },
  ] = useSearchFiltersMutation();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  const [combinedState, setCombinedState] = useState<CombinedState>({
    search: null,
    company: [],
    category: categoryData?.catgeories?.map((item: any) => item._id) || [],
    limit: itemsPerPage,
    page: currentPage,
  });

  useEffect(() => {
    if (categoryData) {
      setCombinedState((prevState) => ({
        ...prevState,
        category: categoryData?.catgeories?.map((item: any) => item._id) || [],
      }));
    }
  }, [categoryData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          page: currentPage,
          combinedState: {
            ...combinedState,
            category: combinedState.category,
            limit: itemsPerPage,
          },
        };

        await searchFilters(payload);
      } catch (error) {
        console.error("Error found", error);
      }
    };
    fetchData();
  }, [combinedState, searchFilters, currentPage, itemsPerPage]);

  const handleClick = (id: string) => {
    if (id === "All") {
      setSelectedCategory(null);
      setIsAllSelected(true);
      setCombinedState((prevState) => ({
        ...prevState,
        category: categoryData?.catgeories?.map((item: any) => item?._id) || [],
      }));
    } else {
      setSelectedCategory(id);
      setIsAllSelected(false);
      setCombinedState((prevState) => ({
        ...prevState,
        category: [id],
      }));
    }
    setCurrentPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    setCombinedState((prevState) => ({
      ...prevState,
      page: newPage,
    }));
  };

  const totalPages = Math.ceil((SearchProductsResponse?.total || 0) / itemsPerPage);


  const title = "Assigned Rewards | Chawla Ispat | Buy Steel Products Building Materials";
  const description = "description";
  const baseUrl = chawlaispat;

  usePageMeta(title, description, baseUrl);

  if (isCategoryLoading || isProductLoading) {
    return <div className="flex justify-center h-[50vh] items-center ">
      <RingLoader color="#5097A4" size={200} />
    </div>
  }

  return (
    <div className="font-inter bg-white">
      <div className="py-12 w-11/12 m-auto">
        <div className="flex justify-between">
          <div className="font-[700] text-[32px] leading-[38px]">
            Assigned Rewards
          </div>

        </div>
        <div className="flex gap-[24px]  mt-10 mb-4">
          <span className="font-[600] text-[16px] leading-[20px]">
            Categories:
          </span>
          <div className="flex gap-[12px] overflow-x-auto font-[600] text-[16px] leading-[20px]">
            <div key="all">
              <span
                className={`mr-4 cursor-pointer px-2 py-1 rounded ${isAllSelected ? "text-blue-300" : ""
                  }`}
                onClick={() => handleClick("All")}
              >
                All
              </span>
            </div>
            {categoryData?.catgeories?.length > 0 ? (
              categoryData?.catgeories?.map(
                (item: { _id: string; name: string }) => (
                  <div key={item?._id} className="whitespace-nowrap ">
                    <span
                      className={`mr-2 cursor-pointer px-2 py-1 rounded  ${selectedCategory === item._id
                        ? "text-blue-300"
                        : combinedState.category?.includes(item._id)
                          ? ""
                          : "bg-transparent"
                        }`}
                      onClick={() => handleClick(item._id)}
                    >
                      {item.name}
                    </span>
                  </div>
                )
              )
            ) : (
              <p>No categories available</p>
            )}
          </div>
        </div>
        <div>
          {SearchProductsResponse?.products?.length > 0 ? (
            <>
              {SearchProductsResponse.products.map((product: any) => (
                <div key={product?.id} className="mb-4">
                  <CategoryRewardCard product={product} />
                </div>
              ))}
              <div className="flex justify-center my-4">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="px-4 py-2 mr-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
                >
                  Previous
                </button>
                <span className="px-4 py-2">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="px-4 py-2 ml-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
                >
                  Next
                </button>
              </div>
            </>
          ) : (
            <p>No products available</p>
          )}
        </div>
      </div>
    </div>
    // <div className="flex flex-col gap-10">
    //   <div className="pt-[24px] gap-[36px]">
    //     <div className="py-[24px] px-[12px] font-[600] text-[24px] leading-[28px]">
    //       Assigned Rewards
    //     </div>
    //     <div className="flex gap-[24px] px-[24px]">
    //       <span className="font-[600] text-[16px] leading-[20px]">
    //         Categories:
    //       </span>
    //       <div className="flex gap-[12px] overflow-x-auto font-[600] text-[16px] leading-[20px]">
    //         <div key="all">
    //           <span
    //             className={`mr-4 cursor-pointer px-2 py-1 rounded ${
    //               isAllSelected ? "text-blue-300" : ""
    //             }`}
    //             onClick={() => handleClick("All")}
    //           >
    //             All
    //           </span>
    //         </div>
    //         {categoryData?.catgeories?.length > 0 ? (
    //           categoryData?.catgeories?.map(
    //             (item: { _id: string; name: string }) => (
    //               <div key={item?._id} className="whitespace-nowrap ">
    //                 <span
    //                   className={`mr-2 cursor-pointer px-2 py-1 rounded  ${
    //                     selectedCategory === item._id
    //                       ? "text-blue-300"
    //                       : combinedState.category?.includes(item._id)
    //                       ? ""
    //                       : "bg-transparent"
    //                   }`}
    //                   onClick={() => handleClick(item._id)}
    //                 >
    //                   {item.name}
    //                 </span>
    //               </div>
    //             )
    //           )
    //         ) : (
    //           <p>No categories available</p>
    //         )}
    //       </div>
    //     </div>
    //   </div>
    //   <div className="px-[24px]">
    //     {SearchProductsResponse?.products?.length > 0 ? (
    //       <>
    //         {SearchProductsResponse.products.map((product: any) => (
    //           <div key={product?.id} className="mb-4">
    //             <CategoryRewardCard product={product} />
    //           </div>
    //         ))}
    //         <div className="flex justify-center my-4">
    //           <button
    //             onClick={() => handlePageChange(currentPage - 1)}
    //             disabled={currentPage === 1}
    //             className="px-4 py-2 mr-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
    //           >
    //             Previous
    //           </button>
    //           <span className="px-4 py-2">
    //             Page {currentPage} of {totalPages}
    //           </span>
    //           <button
    //             onClick={() => handlePageChange(currentPage + 1)}
    //             disabled={currentPage === totalPages}
    //             className="px-4 py-2 ml-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
    //           >
    //             Next
    //           </button>
    //         </div>
    //       </>
    //     ) : (
    //       <p>No products available</p>
    //     )}
    //   </div>
    // </div>
  );
};
