import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { lhwpath } from '../apiRoutes'
 
export const api = createApi({

    baseQuery: fetchBaseQuery({
        
        baseUrl: lhwpath,  
        prepareHeaders: async (headers) => {
            // headers.set('Content-Type', 'application/json')
            return headers
        },
        credentials: 'include',
    }),
    tagTypes: ['Contractor','Rewards' ,'cart', 'address', 'inventory', 'category','ContractorRequest'],
    endpoints: () => ({}),
    reducerPath: 'api',   
})
