import { Link, useNavigate } from "react-router-dom";
import { MobileCardProps } from "../screens/Components/types";
import { BsTelephone } from "react-icons/bs";

const ProductCard = ({ data }: MobileCardProps) => {
  const navigate = useNavigate();

  const totalPrice = data?.finalPrice;
  const unit = data?.unit;

  const handleWhatsappClick = () => {
    const phoneNumber = "9639921392";
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };

  return (
    <div className="w-[16rem] h-[348px] bg-white border border-[#ECF0F0] md:border-[#ECF0F0] rounded-lg hover:shadow-[#5097A4] hover:shadow-lg p-[12px] flex flex-col">
      <div className="flex-grow flex flex-col">
        <div className="flex w-[232px] h-[144px] justify-center group overflow-hidden mx-auto">
          <Link to={`/singleproduct/${data?.id}`}>
            <img
              src={data?.images}
              alt={data?.productName}
              className="relative md:h-[14rem] h-[11.1rem] w-[17rem] py-1 group-hover:scale-110 transition-transform duration-300"
            />
          </Link>
        </div>

        <div className="flex-grow flex flex-col justify-between">
          <div className="ml-2">
            <h1
              className="font-inter text-[#111827] text-[18px] leading-[28px] my-1 overflow-hidden font-bold cursor-pointer"
              onClick={() =>
                navigate(`/singleproduct/${data?.id}`, {
                  state: { id: data?.id },
                })
              }
            >
              {data && data?.productName
                ? data?.productName
                    ?.split(" ")
                    .slice(0, window.innerWidth >= 768 ? 8 : 5)
                    .join(" ")
                : ""}
            </h1>

            <p className="font-bold font-inter text-gray-500 md:text-xs text-[12px] md:leading-6 leading-4 flex gap-[7px]">
              {totalPrice ? `₹${totalPrice.toFixed(2)}/${unit}` : ""}{" "}
              <span className="text-blue-400">
                {totalPrice ? "(Negotiable)" : "(Prices are Negotiable)"}
              </span>
            </p>
          </div>

          <div className="mt-auto">
            <div className="relative w-full h-11 group">
              {/* Default state */}
              <div className="w-full h-full px-4 py-3 bg-white rounded-lg border border-slate-200 flex justify-center gap-3 items-center transition-opacity duration-300 absolute inset-0 group-hover:opacity-0">
                <div className="text-[#5097A4] text-sm flex whitespace-nowrap font-semibold font-['Inter'] leading-[20px]">
                  Contact For Better Pricing
                </div>
                <BsTelephone className="w-5 h-5 relative text-[#5097A4]" />
              </div>
              {/* Hover state */}
              <div
                className="w-full h-full px-4 py-3 bg-[#5097A4] rounded-lg shadow flex items-center gap-3 justify-center transition-all duration-300 opacity-0 group-hover:opacity-100 group-hover:shadow-2xl absolute inset-0 overflow-hidden cursor-pointer"
                onClick={handleWhatsappClick}
              >
                <BsTelephone className="w-5 h-5 relative text-white opacity-0 transition-opacity duration-100 delay-300 group-hover:opacity-100" />
                <div className="text-white text-sm flex whitespace-nowrap font-semibold font-['Inter'] leading-tight transition-transform duration-300 transform translate-x-full group-hover:translate-x-0">
                  Contact For Better Pricing
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;