import { useEffect, useState } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { useGetContractorsMutation } from "../../../../Redux/Api/Admin/Dashboard";

interface Data {
  contractorJoined: number;
  date: string; // Keeping date as string to match the incoming data format
  totalContractor?: number;
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const BarChartComponent = () => {
  const [body, setBody] = useState({
    startDate: "",
    endDate: "",
  });

  const [ContractorData, setContractorData] = useState<Data[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<number>(
    new Date().getMonth()
  );

  useEffect(() => {
    const currentDate = new Date();
    const currentMonthIndex = selectedMonth;
    const currentYear = currentDate.getFullYear();

    const startDate = new Date(currentYear, currentMonthIndex, 1);
    const endDate = new Date(currentYear, currentMonthIndex + 1, 0);

    const formattedStartDate = `${startDate.getFullYear()}-${String(
      startDate.getMonth() + 1
    ).padStart(2, "0")}-${String(startDate.getDate()).padStart(2, "0")}`;
    const formattedEndDate = `${endDate.getFullYear()}-${String(
      endDate.getMonth() + 1
    ).padStart(2, "0")}-${String(endDate.getDate()).padStart(2, "0")}`;

    setBody({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  }, [selectedMonth]);

  const [ContractorMutation] = useGetContractorsMutation();

  useEffect(() => {
    if (body.endDate && body.startDate) {
      ContractorMutation(body).then((result: any) => {
             if (result?.data?.monthData) {
          setContractorData(result.data.monthData); // Assuming 'dailyData' is the key in your API response
        }
      });
    }
  }, [ContractorMutation, body]);

  // Custom date formatter to extract the day part
  const dayFormatter = (dateString: string) => {
    const date = new Date(dateString);
    return String(date.getDate()).padStart(2, "0"); // Pad day to ensure it is always two digits
  };

  return (
    <div className="bg-gray-100 p-4 rounded-lg shadow-sm">
      <h2 className="text-xl font-semibold mb-4">Contractors Chart</h2>

      <div className="">
        <select
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(Number(e.target.value))}
          className="border p-2 rounded"
        >
          {months.map((month: string, index: number) => (
            <option value={index} key={index}>
              {month}
            </option>
          ))}
        </select>
      </div>

      <ResponsiveContainer width="100%" height={400}>
        <AreaChart
          data={ContractorData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={dayFormatter} />{" "}
          {/* Apply custom formatter */}
          <YAxis />
          <Tooltip />
          <Legend />
          <Area
            type="monotone"
            dataKey="contractorJoined"
            stroke="#ff0000"
            fill="#8884d8"
          />
          <Area
            type="monotone"
            dataKey="totalContractor"
            stroke="#82ca9d"
            fill="#82ca9d"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartComponent;
