

interface PriceData {
    finalPrice: Number;
    unit: string;
}

interface PriceSectionProps {
    data: PriceData;
    isLoading: boolean;
}

const PriceSection = ({ data, isLoading }: PriceSectionProps) => {
    return (
        <div className="flex flex-col justify-center gap-0.5 md:w-[13rem]">
            <div className="font-[500] text-[1.49rem] md:text-[2.21rem]  leading-[1.0rem] md:leading-[1.80rem]  text-gray-700  items-center w-full border-l-2 border-l-black pl-1 py-2 ">
                {isLoading ? (
                    <div className="animate-pulse space-y-2">
                        <div className="bg-gray-300 h-5 w-16 rounded-md"></div>
                        <div className="bg-gray-300 h-6 w-24 rounded-md"></div>
                    </div>
                ) : (
                    <div className="w-full text-[23px] flex flex-col  items-start">
                        <div>Start At</div>
                        <div className="mt-2 md:mt-0">
                            {data &&
                                `₹${data?.finalPrice.toFixed(2)}/${data?.unit}`}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PriceSection;
