import group from "../../../assets/BusinessDeal.png";

export const SignupResponse = () => {
  return (
    <div>
  
      <div>
        <div className="w-11/12 md:flex m-auto mt-[4rem] md:mt-2 ">
          <div className="">
            <div className="  text-[#CBD5E1] [font-family:'Poppins',Helvetica] font-bold text-theme-colors-neutral-300 text-[3rem]  md:text-[3.6rem]  tracking-[0] md:leading-[80px] leading-[1px]  ">
              THANK YOU !
            </div>
            <div className="font-bold text-[2.4rem] sm:text-[3rem] w-[75%] sm:w-[60%] md:w-[80%] leading-[50px] mt-7  md:leading-[80px]">
              Applying for our partner program.
            </div>
            <div className="  text-[#CBD5E1] [font-family:'Poppins',Helvetica] font-bold text-theme-colors-neutral-300 text-[3rem]  md:text-[6rem] tracking-[0] leading-[100px] md:leading-[140px] whitespace-nowrap">
              Wait for 
            </div>
            <div className="  text-[#CBD5E1] [font-family:'Poppins',Helvetica] font-bold text-theme-colors-neutral-300 text-[8rem] md:text-[11.25rem] tracking-[0] leading-[80px] sm:leading-[180px] whitespace-nowrap">
              24  <span className="font-bold text-[80px] w-[75%] ">
              HR
            </span>
            </div>
          </div>

          <img
            className=" w-[540px] h-[581px] md:w-[340px] md:h-[381px] lg:w-[540px] lg:h-[581px] sm:mt-4"
            alt="Groupc:\Users\Vikas\Downloads\AnimaPackage-React-t8Rbr (1)\static\img\star-1.svg"
            src={group}
          />
          <div className="flex w-[344px] items-start justify-center gap-2.5 "></div>
        </div>
      </div>
    </div>
  );
};
