import { useEffect, useState } from "react";
import { CiSearch, CiFilter } from "react-icons/ci";
import { RiFileExcel2Fill } from "react-icons/ri";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { LuPencilLine } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import Dropdown from "../Inventory/components/Dropdown";
import { usePublishedContractorMutation } from "../../../Redux/Api/Contractor";
import {
  useDeleteRewardMutation,
  useEditRewardMutation,
  useGetAdminRewardsQuery,
} from "../../../Redux/Api/Admin/Reward";
import { Rewards } from "../../../Redux/Api/types";
import { Coupons } from "./components/Coupons";
import { RewardChange } from "./components/Filter/RewardChange";
import * as XLSX from "xlsx";
import { MdDelete } from "react-icons/md";
import { UploadImage } from "./components/uploadImage";

interface RewardStates {
  [key: number]: string;
}

export const Reward = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [limit, setLimit] = useState("20");
  const [page, setPage] = useState<any>(1);
  const [imageId, setImageId] = useState<any>("");
  const [totalPages, setTotalPages] = useState(1);
  const [fetchContractors, { data }] = usePublishedContractorMutation();
  const { data: Reward } = useGetAdminRewardsQuery();
  const [isCouponVisible, setIsCouponVisible] = useState<boolean>(false);
  const [isImageUpload, setIsImageUpload] = useState<boolean>(false);
  const [rewardStates, setRewardStates] = useState<RewardStates>({});
  const [editReward] = useEditRewardMutation();
  const [deleteRewarddMutation] = useDeleteRewardMutation();
  const [editableNameIndex, setEditableNameIndex] = useState<number | null>(
    null
  );
  const [newName, setNewName] = useState<string>("");


  const handleSelect = (option: any) => {
    setSelectedOption(option);
    setLimit(option);
  };

  const options = ["9", "8", "7"];

  const handleShowCoupon = () => {
    setIsCouponVisible(true);
  };

  const handleCloseCoupon = () => {
    setIsCouponVisible(false);
  };
  const handleCloseImageUpload = () => {
    setIsImageUpload(false);
  };

  const handleRewardChange = (rowIndex: number, grade: string) => {
    setRewardStates((prevState: RewardStates) => ({
      ...prevState,
      [rowIndex]: grade,
    }));
  };

  const handleCloseRewardChange = (rowIndex: number) => {
    setRewardStates((prevState: RewardStates) => ({
      ...prevState,
      [rowIndex]: "",
    }));
  };

  const handleStatus = (status: boolean, id: string) => {
    const body = { status: status };
    editReward({ id, body }); // Pass as a single object
  };

  // const handleName = (name: string, id: string) => {
  //   const body = { name: name };
  //   editReward({ id, body }); // Pass as a single object
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchContractors({ limit, page });
        // Assuming response.data.totalPages exists and is correctly set in the response structure
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching contractors:", error);
      }
    };

    fetchData();
  }, [page, limit, fetchContractors]);

  // const prevPage = () => {
  //   if (page > 1) {
  //     setPage(page - 1);
  //   }
  // };

  // const nextPage = () => {
  //   if (page < totalPages) {
  //     setPage(page + 1);
  //   }
  // };

  const deleteReward = async (id: string) => {
    try {
      await deleteRewarddMutation(id).unwrap();
    } catch (error) {
      console.error("Error deleting contractor:", error);
    }
  };

  const exportToExcel = () => {
    // Create a worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(data?.contractors || []);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Contractors");

    // Generate buffer and save
    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "contractors.xlsx";
    link.click();
    window.URL.revokeObjectURL(url);
  };

  const handleNameClick = (index: number, currentName: string) => {
    setEditableNameIndex(index);
    setNewName(currentName);
  };

  const saveName = async (id: string) => {
    const body = { name: newName };
    await editReward({ id, body });
    setEditableNameIndex(null);
  };
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
  };

  return (
    <div className="h-full rounded-[12px]  bg-white">
      {isImageUpload && (
        <UploadImage onClose={handleCloseImageUpload} id={imageId} />
      )}
      <div className="border rounded-[12px] h-full flex flex-col gap-[24px] py-[20px] px-[20px]">
        <div className="flex justify-between">
          <div className="flex gap-[7px]">
            <div className="flex items-center w-[170px] h-[30px] gap-[10px] p-[8px] border rounded-[4px]">
              <CiSearch className="w-[16px] h-[16px]" />
              <input
                type="text"
                className="w-[90%] border-none outline-none font-[400] text-[12px] leading-[14px]"
                placeholder="Search"
              />
            </div>

            <div className="flex  w-[65px] h-[30px] border gap-[10px] p-[8px] items-center rounded-[4px] border-[#53545C]">
              <CiFilter />
              <span className="font-[400] text-[11px] leading-[13px]">
                Filter
              </span>
            </div>
          </div>

          <div className="flex gap-[10px]">
            <div
              className="flex items-center px-[24px] py-[3px] border gap-[4px] rounded-[4px] text-[#0078FB]  border-[#0078FB] bg-[#F5F5F5] cursor-pointer"
              onClick={handleShowCoupon}
            >
              <span className="font-[600] text-[16px] leading-[20px]">
                Add Coupons
              </span>
            </div>

            <div
              className="flex items-center px-[24px] py-[3px] border gap-[4px] rounded-[4px] text-[#16A34A] border-[#16A34A] bg-[#F5F5F5]"
              onClick={exportToExcel}
            >
              <span className="font-[600] text-[16px] leading-[20px]">
                {" "}
                Export{" "}
              </span>{" "}
              <RiFileExcel2Fill className="w-[20px] h-[20px]" />
            </div>
          </div>
        </div>

        {isCouponVisible && <Coupons onClose={handleCloseCoupon} />}

        <div className="flex">
          <table className="table-auto min-w-full divide-y divide-gray-200">
            <thead className="bg-white ">
              <tr className="border-t">
                <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]"></th>
                <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]"></th>
                <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                  Coupon Name
                </th>
                <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B] flex flex-col">
                  <span>Reward</span>
                </th>
                <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {Reward?.response?.map((i: Rewards, index: number) => (
                <tr key={index} className="">
                  <td className="px-6 py-4 text-center  whitespace-nowrap font-inter text-sm text-[#6E7079]"></td>
                  <td>
                    <div className="h-full p-2 text-center">
                      {
                        <img
                          src={i?.image}
                          className="w-full rounded-md h-[100px] object-cover "
                          alt={i?.name}
                        />
                      }
                    </div>

                    <button
                      onClick={() => {
                        setIsImageUpload(true);
                        setImageId(i?._id);
                      }}
                      className="cursor-pointer border-2 px-1  text-sm text-white bg-red-400"
                    >
                      Change Image
                    </button>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079] cursor-pointer">
                    {editableNameIndex === index ? (
                      <>
                        <input
                          value={newName}
                          onChange={handleNameChange}
                          className="w-1/2 border-b-2 border-gray-300 outline-none"
                        />
                        <span
                          className="text-blue-500 font-bold"
                          onClick={() => saveName(i._id)}
                        >
                          Save
                        </span>
                      </>
                    ) : (
                      <span className="flex gap-1 items-center">
                        <span>{i?.name}</span>{" "}
                        <LuPencilLine
                          className="text-[#0078FB]"
                          onClick={() => handleNameClick(index, i?.name)}
                        />
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                    <div className="relative">
                      <div className="flex gap-[12px]">
                        <div className="flex flex-col relative">
                          <span className="text-black">Grade A</span>
                          <span className="text-amber-500 flex gap-[23px] items-center">
                            {i?.points?.rewardA}{" "}
                            <LuPencilLine
                              className="text-[#0078FB]"
                              onClick={() => handleRewardChange(index, "A")}
                            />
                            {rewardStates[index] === "A" && (
                              <div
                                className="absolute top-[40px] left-0 z-50 "
                                style={{ transform: "translateX(-50%)" }}
                              >
                                <RewardChange
                                  grade={"a"}
                                  id={i?._id}
                                  handleClose={() =>
                                    handleCloseRewardChange(index)
                                  }
                                />
                              </div>
                            )}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="text-black">Grade B</span>
                          <span className="text-amber-500 flex gap-[23px] items-center">
                            {i?.points?.rewardB}{" "}
                            <LuPencilLine
                              className="text-[#0078FB]"
                              onClick={() => handleRewardChange(index, "B")}
                            />
                            {rewardStates[index] === "B" && (
                              <div
                                className="absolute top-[40px] left-0 z-50 "
                                style={{ transform: "translateX(-50%)" }}
                              >
                                <RewardChange
                                  grade={"b"}
                                  id={i?._id}
                                  handleClose={() =>
                                    handleCloseRewardChange(index)
                                  }
                                />
                              </div>
                            )}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="text-black">Grade C</span>
                          <span className="text-amber-500 flex gap-[23px] items-center">
                            {i?.points?.rewardC}{" "}
                            <LuPencilLine
                              className="text-[#0078FB]"
                              onClick={() => handleRewardChange(index, "C")}
                            />
                            {rewardStates[index] === "C" && (
                              <div
                                className="absolute top-[40px] left-0 z-50 "
                                style={{ transform: "translateX(-50%)" }}
                              >
                                <RewardChange
                                  grade={"c"}
                                  id={i?._id}
                                  handleClose={() =>
                                    handleCloseRewardChange(index)
                                  }
                                />
                              </div>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                    <span
                      className={`${
                        i.status ? "bg-[#16A34A]" : "bg-[#D32F2F]"
                      } px-[40px] border rounded-md py-[3px] text-white cursor-pointer text-[12px] leading-[14px]`}
                      onClick={() => handleStatus(!i?.status, i?._id)}
                    >
                      {i.status ? "Active" : "Inactive"}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap font-inter text-2xl hover:text-3xl w-[10%] cursor-pointer text-red-600">
                    <span onClick={() => deleteReward(i._id)}>
                      <MdDelete />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex flex-row justify-between p-2 m-1 border-t-2 ">
          <div>
            <div className="flex flex-row gap-3">
              <Dropdown options={options} onSelect={handleSelect} />
              <p className="text-[#A6A8B1]">Items Per Page</p>
              <p>Categories</p>
            </div>
          </div>
          <div>
            <div className="flex flex-row gap-3">
              <Dropdown options={options} onSelect={handleSelect} />
              <p className="text-[#A6A8B1]">of 44 pages</p>
              <div className="flex flex-row items-center gap-2 text-[#666666]">
                <span>
                  <FaChevronLeft size={15} />
                </span>
                <span>
                  <FaChevronRight size={15} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
