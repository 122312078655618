import { useSelector } from 'react-redux';
import { RootState } from '../../Redux/store'; // Adjust the path based on your project structure

const useCompanyNames = (companyIds: string[]) => {

    const allCompanies = useSelector((state: RootState) => state.values.allCompanies)

    // craeet a mapping of company Id to name 
    const companyMap = allCompanies.reduce((acc: {[key:string]:string},company) => {
        acc[company.id] = company.name;
        return acc; 
    },{});

    const result = companyIds.map(id => ({
        id,
        name: companyMap[id] || ""
    }))

    return result;

}

export default useCompanyNames;