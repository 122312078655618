import React, { useEffect, useState } from 'react'
import file from '../../Images/file.png'
import { CombinedState } from '../../../../../types/api-types';
import { useSearchFiltersMutation } from '../../../../../Redux/Api/ProductAPI';

const BlueBox = () => {
  const [combinedState, setCombinedState] = useState<CombinedState>({
    search: "",
    // rating: null,
    company: [],
    category: [],
    // sort: "",
    limit: 12,
    page: 1,
  });

  const [searchFilters, { data: SearchProductsResponse }, isLoading, isError] =
  useSearchFiltersMutation();

useEffect(() => {
 
}, [SearchProductsResponse]);
useEffect(() => {
  searchFilters({ combinedState });
}, [combinedState, searchFilters]);

  return (
    <div>
         <div className='bg-[#334155] mx-4 my-2 px-4 py-3 rounded-xl'>
                        <div>
                            <img src={file} alt='file' className='bg-[#FFFFFF29] p-2 rounded-lg'/>
                        </div>
                        <div className='flex flex-row justify-around'>
                            {/* Left div */}
                            <div className='mt-9 w-full flex flex-col justify-start gap-2'>
                                <h1 className='font-inter text-sm text-white'>All Products</h1>
                                <p className='font-inter text-xl text-white'>{SearchProductsResponse?.total}</p>
                            </div>
                            {/* Right div */}
                            {/* <div className='mt-9 w-full flex flex-col justify-start gap-2'>
                                <h1 className='font-inter text-sm text-white'>Publish</h1>
                                <p className='font-inter text-xl text-white flex flex-row gap-2 items-center'>316 
                                    <span className='text-[#519C66] text-xs' >86%</span></p>
                            </div> */}
                        </div>    
            </div>
    </div>
  )
}

export default BlueBox