import { api } from "../rtkAPI";
import { Rewards } from "../types";

export const RewardsQuery: any = api.injectEndpoints({
  endpoints: (builder) => ({
    getAdminRewards: builder.query<Rewards, string>({
      query: () => {
        return {
          url: `/global/rewards/admin/all`,
          method: "GET",
        };
      },
      providesTags: ["Rewards"],
    }),

    getRewards: builder.query<Rewards, string>({
      query: () => {
        return {
          url: `/global/rewards/all`,
          method: "GET",
        };
      },
      providesTags: ["Rewards"],
    }),

    createReward: builder.mutation<any, any>({
      query: (couponData) => {
        const headers: Record<string, string> = {};
        headers["Content-Type"] = "multipart/form-data";

        return {
          url: `global/rewards/create`,
          method: "POST",
          body: couponData,
          invalidatesTags: ["Rewards"],
        };
      },
      invalidatesTags: ["Rewards"],
    }),

    editReward: builder.mutation<any, any>({
      query: ({ id, body }) => {
        // console.log(id,body,"openup")
        return {
          url: `global/rewards/edit/${id}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: ["Rewards"],
    }),

    deleteReward: builder.mutation<any, any>({
      query: (id) => {
        return {
          url: `/global/rewards/admin/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Rewards"],
    }),

    changeImage: builder.mutation<any, any>({
      query: ({ couponData, id }) => {
        console.log("change image data", couponData, id);
        // const headers: Record<string, string> = {};
        // headers["Content-Type"] = "multipart/form-data";

        return {
          url: `/global/rewards/image/upload/${id}`,
          method: "PATCH",
          body: couponData,
        };
      },
      invalidatesTags: ["Rewards"],
    }),

  }),
});

export const {
  useCreateRewardMutation,
  useGetRewardsQuery,
  useGetAdminRewardsQuery,
  useEditRewardMutation,
  useDeleteRewardMutation,
  
  useChangeImageMutation,
} = RewardsQuery;
