import { createSlice , PayloadAction} from "@reduxjs/toolkit";

interface SelectionState {
    allCategories: any[]; 
    allCompanies: any[]; 
  }
  
const initialState: SelectionState ={
  allCategories: [],
  allCompanies: []
}

const toStoreValues = createSlice({
      name:"storeValues",
      initialState,
      reducers: {
           setAllCategories(state,action: PayloadAction<any>){
            state.allCategories = action.payload
           },
           setAllCompanies(state,action: PayloadAction<any>){
            state.allCompanies = action.payload
           },
     },
})

export const { setAllCategories,setAllCompanies} = toStoreValues.actions;
export default toStoreValues.reducer;