import  { useCallback, useEffect , useState } from "react";

import { CiSearch } from "react-icons/ci";
import { GoArrowLeft } from "react-icons/go";
import { GoArrowUpLeft } from "react-icons/go";
import image from "../assets/Bangur.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { usePriceMutation, useSearchDataMutation } from "../Redux/Api/priceAPI";
import { setSearch } from "../Redux/reducer/Filter";
import { useTopSellingQuery } from "../Redux/Api/top";
import { chawlaispat } from "../Redux/apiRoutes";
import usePageMeta from "./hooks/usePageMeta";

interface Selling {
  name?: string;
  id?: string;
  type: string;
  view: number;
}

interface Price {
  id: string;
  finalPrice: number;
  unit: string;
}

interface PriceResponse {
  productId: string;
  finalPrice: number;
  unit: string;
}


export const SearchPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [search, getSearch] = useState<string>("");
  const [searchResults, setSearchResults] = useState<Selling[]>([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [searchAllProducts] = useSearchDataMutation();
  const { data: selling } = useTopSellingQuery();
  const [priceMutation] = usePriceMutation();
  const [data, setData] = useState([]);
  const [priceData, setPriceData] = useState<{ [key: string]: Price }>({});

  const handleSearch = (value: string) => {
    getSearch(value);
  };

  const memoizedSearchAllProducts = useCallback(
    async (search: string) => {
      if (search?.trim() === "") {
        setSearchResults([]);
        return;
      }
      setProductLoading(true);
      try {
        const response = await searchAllProducts(search);
        if (response?.data) {
          setSearchResults(response?.data);
          return response;
        } else {
          console.log("error in search response", response.error);
          setSearchResults([]);
        }
      } catch (error) {
        console.error("Error searching products:", error);
        throw error;
      } finally {
        setProductLoading(false);
      }
    },
    [searchAllProducts]
  );

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (search?.trim() !== "") {
        await memoizedSearchAllProducts(search);
      } else {
        setSearchResults([]);
      }
    };
    fetchSearchResults();
  }, [search, memoizedSearchAllProducts]);

  const showSearchBoxOnClick = (event: any) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      dispatch(setSearch(search));
      navigate(`/items`);
    }
  };

  const onGlassClick = () => {
    if (search.trim() !== "") {
      dispatch(setSearch(search));
      navigate(`/items`);
      setShowSearchBox(false);
    }
  };

  const handleNavigation = (name: string) => {
    navigate("/subitems", { state: { name: name } });
  };

  useEffect(() => {
    if (selling) {
      setData(selling);
    }
  }, [selling]);

  useEffect(() => {
    const fetchPrices = async (productId: string[]) => {
      try {
        const priceResponse = await priceMutation({ productId });
        const priceDataObj: { [key: string]: Price } = {};
        priceResponse?.data?.results?.slice(0, 8).forEach((result: PriceResponse) => {
          priceDataObj[result.productId] = {
            id: result.productId,
            finalPrice: result.finalPrice,
            unit: result.unit,
          };
        });
        setPriceData(priceDataObj);
      } catch (error) {
        console.log(error, "error occured");
      }
    };

    if (selling?.length > 0) {
      const productId = selling.map((selling: Selling) => selling.id);
      fetchPrices(productId);
    }
  }, [selling, priceMutation]);

  useEffect(() => {
    document.title = "Search Page - Chawla Ispat";
  }, []);

  const title = "Search | Chawla Ispat | Buy Steel Products Building Materials";
  const description = "Explore search results and find products or information quickly on the Search Page of Chawla Ispat.";
  const canonicalUrl = chawlaispat;

  usePageMeta(title, description, canonicalUrl);

  return (
    <div className="  my-1">
      <div className=" flex gap-2 items-center   w-full px-[4px]">
        <GoArrowLeft className="h-8 w-8 cursor-pointer" onClick={() => navigate("/")} />

        <div className="p-2  rounded-3xl flex items-center border border-black w-full">
          <CiSearch className="h-7 w-7 cursor-pointer" onClick={onGlassClick} />
          <input
            className="w-full border-none outline-none"
            placeholder="Search Here..."
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            onKeyDown={(e) => showSearchBoxOnClick(e)}
          />
        </div>
      </div>

      <div className="mt-3 border-t border-t-gray-600 ">
        {/* Flat List */}
        {searchResults?.slice(0, 8).map((searches: any,index: number) => (
          <div className="p-2 border  hover:bg-slate-200" key={index} >
            <div className="flex items-center px-2 justify-between ">
              <span
                className="font-inter font-normal text-sm leading-5 cursor-pointer "
                onClick={() => handleNavigation(searches?.name)}
              >
                {searches?.name}
              </span>
              <GoArrowUpLeft
                className="h-5 w-5 cursor-pointer"
                onClick={() => handleNavigation(searches?.name)}
              />
            </div>
          </div>
        ))}
      </div>

      <div className="h-2 w-full bg-gray-200"></div>

      <div className="p-2 flex flex-col gap-2">
        {data &&  <div>Popular Products</div> }
       

        <div className=" flex flex-wrap justify-evenly gap-[12px]">
          {/* cards */}

          {data && data?.map((item: Selling) => {
              if (item?.id && item.id in priceData && priceData[item?.id]?.id === item?.id) {
                const PriceData = priceData[item?.id];
                const totalPrice = PriceData?.finalPrice;
                const unit = PriceData?.unit;

                return (
                  <div
                    className="w-[120px] border border-gray-600 rounded-md flex flex-col gap-[10px] py-2"
                    key={item.id}
                  >
                    <img src={image} alt="image" className="h-[100px]" />
                    <div className="flex flex-col justify-center items-center text-xs p-1">
                      <span
                        className="line-clamp-3 w-full cursor-pointer"
                        onClick={() =>
                          navigate(`/singleproduct/${item.id}`, {
                            state: { id: item?.id },
                          })
                        }
                      >
                        {item?.name}
                      </span>
                      <span className="truncate w-full flex pl-2 text-blue-400 cursor-pointer">
                        {totalPrice ? `${totalPrice.toFixed(2)} / ${unit}` : ""}
                      </span>
                    </div>
                  </div>
                );
              }
              return null;
            })}
        </div>
      </div>
    </div>
  );
};