import Table from "./Table";
import { Product } from "../type";

interface ItemDataProps {
  singleProduct: Product;
}

const ItemData: React.FC<ItemDataProps> = ({ singleProduct }) => {

  return (
    <div className="w-full ">
      <div className="flex flex-col gap-4 justify-between">
        <div>
          <div className="sm:hidden md:block  text-2xl font-medium">
            {singleProduct?.productName}
          </div>

          <div className="sm:hidden md:block text-xl font-semibold">
            {singleProduct?.finalPrice ===  null
              ? "Price information not available"
              :  `${singleProduct?.finalPrice?.toFixed(2)} / ${
                  singleProduct?.priceStructureUnit
                }` }
          </div>
        </div>

        <div className="w-full ">
          <div>
            {singleProduct?.companyProductDescriptionLabels?.length > 0 && (
              <h1 className="font-medium text-lg mt-6 mb-4">Specifications</h1>
            )}
            <Table
              productDetails={singleProduct?.companyProductDescriptionLabels}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemData;
